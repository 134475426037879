/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * Flatfox API
 * For customers adding integrations with our API please read the API Reference linked below first.
 * OpenAPI spec version: 0.0.0 (v1)
 */
export type UnreadStateGroupbyListRole =
  | typeof UnreadStateGroupbyListRole[keyof typeof UnreadStateGroupbyListRole]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnreadStateGroupbyListRole = {
  '': '',
  advertiser: 'advertiser',
  agent: 'agent',
  'agent-notice': 'agent-notice',
  applicant: 'applicant',
} as const;

export type UnreadStateGroupbyListMessageType =
  typeof UnreadStateGroupbyListMessageType[keyof typeof UnreadStateGroupbyListMessageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnreadStateGroupbyListMessageType = {
  accept_date: 'accept_date',
  plain: 'plain',
  propose_date: 'propose_date',
  reject_date: 'reject_date',
  remove_date: 'remove_date',
  status: 'status',
} as const;

export type UnreadStateGroupbyListParams = {
  lease_termination?: number;
  listing?: number;
  /**
   * * `plain` - Plain message
   * `status` - Status message
   * `propose_date` - Propose viewing date
   * `accept_date` - Accept viewing date
   * `reject_date` - Reject viewing date
   * `remove_date` - Remove viewing date
   */
  message_type?: UnreadStateGroupbyListMessageType;
  participant?: number;
  /**
   * * `applicant` - APPLICANT
   * `advertiser` - ADVERTISER
   * `agent` - AGENT
   * `agent-notice` - AGENT-NOTICE
   */
  role?: UnreadStateGroupbyListRole;
  ticket?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type UnreadStateListRole =
  | typeof UnreadStateListRole[keyof typeof UnreadStateListRole]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnreadStateListRole = {
  '': '',
  advertiser: 'advertiser',
  agent: 'agent',
  'agent-notice': 'agent-notice',
  applicant: 'applicant',
} as const;

export type UnreadStateListMessageType =
  typeof UnreadStateListMessageType[keyof typeof UnreadStateListMessageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnreadStateListMessageType = {
  accept_date: 'accept_date',
  plain: 'plain',
  propose_date: 'propose_date',
  reject_date: 'reject_date',
  remove_date: 'remove_date',
  status: 'status',
} as const;

export type UnreadStateListParams = {
  lease_termination?: number;
  /**
   * Number of results to return per page.
   */
  limit?: number;
  listing?: number;
  /**
   * * `plain` - Plain message
   * `status` - Status message
   * `propose_date` - Propose viewing date
   * `accept_date` - Accept viewing date
   * `reject_date` - Reject viewing date
   * `remove_date` - Remove viewing date
   */
  message_type?: UnreadStateListMessageType;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  participant?: number;
  /**
   * * `applicant` - APPLICANT
   * `advertiser` - ADVERTISER
   * `agent` - AGENT
   * `agent-notice` - AGENT-NOTICE
   */
  role?: UnreadStateListRole;
  ticket?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type TicketRetrieveParams = {
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type TicketListStatus = typeof TicketListStatus[keyof typeof TicketListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketListStatus = {
  not_open: 'not_open',
  open: 'open',
} as const;

export type TicketListParams = {
  assigned?: string;
  /**
   * Multiple values may be separated by commas.
   */
  city?: string[];
  /**
   * Multiple values may be separated by commas.
   */
  house?: number[];
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * Multiple values may be separated by commas.
   */
  number?: number[];
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Which field to use when ordering the results.
   */
  ordering?: string;
  requester?: number;
  /**
   * * `open` - open
   * `not_open` - not_open
   */
  status?: TicketListStatus;
  /**
   * Multiple values may be separated by commas.
   */
  street?: string[];
  /**
   * Multiple values may be separated by commas.
   */
  text?: string[];
  /**
   * Multiple values may be separated by commas.
   */
  zipcode?: string[];
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type TenancyListParams = {
  /**
   * Multiple values may be separated by commas.
   */
  house?: number[];
  is_primary_tenant?: boolean;
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * Multiple values may be separated by commas.
   */
  object?: number[];
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Multiple values may be separated by commas.
   */
  property?: number[];
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type SubscriptionListParams = {
  /**
   * Which field to use when ordering the results.
   */
  ordering?: string;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type SecurityDepositRequestPdfCreateParams = {
  /**
   * A unique integer value identifying the security deposit request
   */
  security_deposit_request?: number;
  /**
   * Edit token for security deposit request
   */
  security_deposit_request_edit_token?: string;
};

export type RoleListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type PublicListingListStatusItem =
  typeof PublicListingListStatusItem[keyof typeof PublicListingListStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublicListingListStatusItem = {
  act: 'act',
  arc: 'arc',
  def: 'def',
  dis: 'dis',
  new: 'new',
  pre: 'pre',
  rem: 'rem',
} as const;

export type PublicListingListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Multiple values may be separated by commas.
   */
  organization?: number[];
  /**
   * Multiple values may be separated by commas.
   */
  organization__slug?: string[];
  /**
   * Multiple values may be separated by commas.
   */
  pk?: number[];
  /**
   * Multiple values may be separated by commas.
   */
  project?: number[];
  selection?: number;
  /**
 * Multiple values may be separated by commas.

* `new` - New
* `def` - Deferred
* `pre` - Notice
* `act` - Published
* `dis` - Paused
* `arc` - Archived
* `rem` - Removed
 */
  status?: PublicListingListStatusItem[];
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type PropertyListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  ref_property?: string;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type ListingRetrieveParams = {
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type ListingListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type LabelTypeListParams = {
  scope?: string;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type HouseListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type DirectSubmitListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type CrmSearchProfilePartialUpdateParams = {
  code?: string;
};

export type CrmSearchProfileCreateParams = {
  code?: string;
};

export type CrmContactDestroyParams = {
  code?: string;
};

export type CrmContactPartialUpdateParams = {
  code?: string;
};

export type CrmContactRetrieveParams = {
  code?: string;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type CrmContactListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type ContactListParams = {
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * The initial index from which to return the results.
   */
  offset?: number;
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type ConfigRetrieveParams = {
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

export type ApplicationRetrieveParams = {
  /**
   * Which fields to expand
   */
  expand?: string[];
  /**
   * Which additional fields to include
   */
  include?: string[];
};

/**
 * * `sent` - Export sent
 * `accepted` - Exported
 * `rejected` - Export rejected
 */
export type WorkOrderPushStatus =
  typeof WorkOrderPushStatus[keyof typeof WorkOrderPushStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkOrderPushStatus = {
  sent: 'sent',
  accepted: 'accepted',
  rejected: 'rejected',
} as const;

export type WorkOrderPushPushData = { [key: string]: unknown };

export interface WorkOrderPush {
  push_data: WorkOrderPushPushData;
}

export type WorkOrderSupplier = number | NestedUser;

export type WorkOrderPushStatusProperty = WorkOrderPushStatus | null;

export type WorkOrderPushResponseData = { [key: string]: unknown } | null;

export type WorkOrderPushData = { [key: string]: unknown } | null;

export type WorkOrderContractedBy = number | NestedUser;

/**
 * WorkOrder
 */
export interface WorkOrder {
  additional_notes?: string | null;
  address: ObjectAddress;
  attachments: UploadField[];
  billing_city: string;
  billing_conditions?: string | null;
  billing_email?: string;
  billing_name: string;
  billing_street: string;
  billing_zipcode: string;
  competence_sum?: number | null;
  contracted_by: WorkOrderContractedBy;
  readonly created: string;
  delivery_city: string;
  delivery_name: string;
  delivery_phone_number?: string;
  delivery_street: string;
  delivery_zipcode: string;
  discount?: string | null;
  readonly document: string | null;
  due_date?: string | null;
  message_text: string;
  message_to_requester: string | null;
  notify_requester: boolean;
  order_number: string;
  readonly pk: number;
  proprietor_city: string;
  proprietor_name: string;
  proprietor_street: string;
  proprietor_zipcode: string;
  readonly push_data: WorkOrderPushData;
  readonly push_response_data: WorkOrderPushResponseData;
  readonly push_status: WorkOrderPushStatusProperty;
  readonly push_status_changed: string | null;
  supplier?: WorkOrderSupplier;
  supplier_email: string;
  supplier_full_name?: string;
  supplier_phone_number: string | null;
  supplier_reference?: string | null;
  ticket: number;
}

/**
 * * `push_dossier` - PUSH_DOSSIER
 * `application_chosen` - APPLICATION_CHOSEN
 */
export type WebhookEventType = typeof WebhookEventType[keyof typeof WebhookEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookEventType = {
  push_dossier: 'push_dossier',
  application_chosen: 'application_chosen',
} as const;

/**
 * * `push` - PUSH_DELIVERY
 * `postbox` - POSTBOX_DELIVERY
 */
export type WebhookDelivery = typeof WebhookDelivery[keyof typeof WebhookDelivery];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookDelivery = {
  push: 'push',
  postbox: 'postbox',
} as const;

export type WebhookEventData = { [key: string]: unknown };

export interface WebhookEvent {
  readonly created: string;
  readonly data: WebhookEventData;
  readonly id: string;
  readonly type: WebhookEventType;
}

export interface Webhook {
  delivery_type: WebhookDelivery;
  event_types?: unknown[];
  name?: string;
  push_url?: string | null;
}

/**
 * * `sms` - SMS
 * `call` - CALL
 */
export type VerificationMethod =
  typeof VerificationMethod[keyof typeof VerificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationMethod = {
  sms: 'sms',
  call: 'call',
} as const;

/**
 * * `None` - NONE
 * `incl` - INCL
 * `excl` - EXCL
 */
export type VatType = typeof VatType[keyof typeof VatType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VatType = {
  incl: 'incl',
  excl: 'excl',
} as const;

/**
 * * `disabled` - disabled
 * `email_only` - email_only
 * `push_only` - push_only
 */
export type UserNotificationDeliveryMethod =
  typeof UserNotificationDeliveryMethod[keyof typeof UserNotificationDeliveryMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserNotificationDeliveryMethod = {
  disabled: 'disabled',
  email_only: 'email_only',
  push_only: 'push_only',
} as const;

export interface UserNotificationConfig {
  application_forms?: UserNotificationDeliveryMethod;
  direct_messages?: UserNotificationDeliveryMethod;
  note_reminders?: UserNotificationDeliveryMethod;
  recommendations?: UserNotificationDeliveryMethod;
  viewing_confirmations?: UserNotificationDeliveryMethod;
}

/**
 * A DRF field which transparently takes upload's upload uid's as well as
app-specific upload uids. Will capture and convert upload's uploads to
the app-specific ones during parsing.

Things to note:
- Can be used with many=True
- Doesn't check ownership! The given queryset is independent of the instance
  we're updateing/creating, so we'll search ALL uploads.
- Doesn't copy. If you need copying, do it in the API view. The uids will
  change, so make sure the client gets the updated ones.
 */
export interface UploadField {
  readonly image_height: number;
  readonly image_width: number;
  readonly is_image: boolean;
  readonly mime_type: string;
  readonly name: string;
  uid: string;
  readonly url: string;
  readonly url_thumb_m: string | null;
}

export interface UploadOffer {
  message: string;
  upload: UploadField;
}

export interface Upload {
  content: string;
  readonly image_height: number;
  readonly image_width: number;
  readonly is_image: boolean;
  readonly mime_type: string;
  readonly name: string;
  readonly uid: string;
  readonly url: string;
  readonly url_thumb_m: string | null;
}

export type UnreadStateTicket = number | NestedTicket | null;

export type UnreadStateRole = Role | null;

export type UnreadStateMessage = number | NestedMessage;

export type UnreadStateListing = number | NestedListing | null;

export type UnreadStateLeaseTermination = number | NestedLeaseTermination | null;

export interface UnreadState {
  readonly created: string;
  internal?: boolean;
  readonly lease_termination: UnreadStateLeaseTermination;
  readonly listing: UnreadStateListing;
  message: UnreadStateMessage;
  readonly pk: number;
  readonly role: UnreadStateRole;
  readonly thread: number;
  readonly ticket: UnreadStateTicket;
  topic?: string;
  readonly user: number | null;
}

export interface TrustedSitesConfig {
  name: string;
  url: string;
}

/**
 * * `volume` - VOLUME
 * `ftp` - FTP
 * `sftp` - SFTP
 * `memory` - MEMORY
 */
export type TransferAccountType =
  typeof TransferAccountType[keyof typeof TransferAccountType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferAccountType = {
  volume: 'volume',
  ftp: 'ftp',
  sftp: 'sftp',
  memory: 'memory',
} as const;

export interface TicketSummaryZip {
  readonly zip_content: string;
}

export interface TicketSummaryPDF {
  /** base64 encoded pdf content */
  readonly pdf: string;
}

/**
 * * `open` - Open
 * `solved` - Solved
 * `archived` - Archived
 */
export type TicketStatus = typeof TicketStatus[keyof typeof TicketStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketStatus = {
  open: 'open',
  solved: 'solved',
  archived: 'archived',
} as const;

export type TicketInquiryDecisionsItemName = {
  de: string;
  en: string;
  fr: string;
  it: string;
};

export type TicketInquiryDecisionsItem = {
  key: string;
  name: TicketInquiryDecisionsItemName;
  next: string;
  step: string;
};

export interface TicketInquiry {
  decisions: TicketInquiryDecisionsItem[];
  readonly doorbell_inscription: string;
}

export type TicketFollowerUser = number | NestedUser;

export interface TicketFollower {
  is_auto?: boolean;
  is_representative?: boolean;
  user: TicketFollowerUser;
}

/**
 * Serializer for the nested ticket instance. Renders fields read-only.
 */
export interface TicketContext {
  address: ObjectAddress;
  attachments?: UploadField[];
  readonly broadcast: number;
  readonly created: string;
  readonly description: string;
  inquiry: TicketInquiry;
  readonly number: number;
  readonly pk: number;
  requester: ContactInformation;
  readonly status: TicketStatus;
  readonly submitter: ContactInformation;
  readonly title: string;
}

export interface TicketAgency {
  city: string;
  country: string;
  logo: Logo;
  name: string;
  name_2: string;
  street: string;
  zipcode: string;
}

export interface Ticket {
  address: ObjectAddress;
  attachments?: UploadField[];
  broadcast: number;
  readonly created: string;
  description: string;
  follower_set?: TicketFollower[];
  inquiry: TicketInquiry;
  readonly modified: string;
  readonly number: number;
  owner: number;
  readonly pk: number;
  requester: ContactInformation;
  status?: TicketStatus;
  readonly submitter: ContactInformation;
  readonly summary_pdf_url: string;
  readonly summary_zip_url: string;
  title: string;
}

export interface ThreadView {
  thread: number;
}

export interface ThreadUnread {
  readonly pk: number;
  touched: string;
}

/**
 * * `listing.advisor` - LISTING_ADVISOR
 * `listing.group` - LISTING_GROUP
 * `listing.application` - LISTING_APPLICATION
 * `ticket.group` - TICKET_GROUP
 * `ticket.requester` - TICKET_REQUESTER
 * `ticket.supplier` - TICKET_SUPPLIER
 * `ticket.broadcast` - TICKET_BROADCAST
 * `lease-termination.group` - LEASE_TERMINATION_GROUP
 * `lease-termination.requester` - LEASE_TERMINATION_REQUESTER
 * `lease-termination.viewing-handler` - LEASE_TERMINATION_VIEWING_HANDLER
 */
export type ThreadTopic = typeof ThreadTopic[keyof typeof ThreadTopic];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThreadTopic = {
  listingadvisor: 'listing.advisor',
  listinggroup: 'listing.group',
  listingapplication: 'listing.application',
  ticketgroup: 'ticket.group',
  ticketrequester: 'ticket.requester',
  ticketsupplier: 'ticket.supplier',
  ticketbroadcast: 'ticket.broadcast',
  'lease-terminationgroup': 'lease-termination.group',
  'lease-terminationrequester': 'lease-termination.requester',
  'lease-terminationviewing-handler': 'lease-termination.viewing-handler',
} as const;

export type ThreadStatusEnum = typeof ThreadStatusEnum[keyof typeof ThreadStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThreadStatusEnum = {
  open: 'open',
  hidden: 'hidden',
  closed: 'closed',
} as const;

/**
 * * `open` - Open
 * `hidden` - Hidden
 * `closed` - Closed
 */
export type ThreadStatus = typeof ThreadStatus[keyof typeof ThreadStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThreadStatus = {
  open: 'open',
  hidden: 'hidden',
  closed: 'closed',
} as const;

export type ThreadInventoryContextItem = number | InventoryContext;

export type ThreadGuestsItem = number | Guest;

export interface Thread {
  agency: AgencyThread;
  application_context: ApplicationContext;
  flat_context: FlatContext;
  readonly guests: ThreadGuestsItem[];
  readonly inventory_context: ThreadInventoryContextItem[];
  /**
   * DEPRECATED. Use `topic` instead.
   * @deprecated
   */
  readonly is_assistant: boolean;
  readonly is_participant: boolean;
  labels: LabelProxy[];
  last_message_time: string;
  readonly lease_termination_context: LeaseTermination;
  messages: Message[];
  num_messages: number;
  num_unread: number;
  readonly pk: number;
  /** requires the query to through
:meth:`~models.Thread.objects.for_user`
which
adds the prefetched self_hidden_states attr. */
  readonly status: ThreadStatusEnum;
  readonly target_platform: TargetPlatformEnum;
  readonly ticket_context: TicketContext;
  readonly topic: ThreadTopic;
  touched: string;
}

/**
 * * `with_tpv` - With Option
 * `without_tpv` - Without Option
 */
export type ThirdPartyDriverOption =
  typeof ThirdPartyDriverOption[keyof typeof ThirdPartyDriverOption];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThirdPartyDriverOption = {
  with_tpv: 'with_tpv',
  without_tpv: 'without_tpv',
} as const;

/**
 * * `property-too-small` - Rented property too small
 * `property-too-big` - Rented property too big
 * `property-standard` - Construction standard insufficient
 * `noise` - Noisy neighborhood
 * `other-needs` - Different needs
 * `residential-area` - Unsuitable residential area
 * `value-for-money` - Insufficient value for money
 * `other` - Other reason
 */
export type TerminationReasonEnum =
  typeof TerminationReasonEnum[keyof typeof TerminationReasonEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TerminationReasonEnum = {
  'property-too-small': 'property-too-small',
  'property-too-big': 'property-too-big',
  'property-standard': 'property-standard',
  noise: 'noise',
  'other-needs': 'other-needs',
  'residential-area': 'residential-area',
  'value-for-money': 'value-for-money',
  other: 'other',
} as const;

/**
 * * `apartment` - Apartment / House
 * `parking` - Parking space
 * `secondary` - Storage / Hobby room
 * `industry` - Industry
 */
export type TerminatedObjectType =
  typeof TerminatedObjectType[keyof typeof TerminatedObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TerminatedObjectType = {
  apartment: 'apartment',
  parking: 'parking',
  secondary: 'secondary',
  industry: 'industry',
} as const;

/**
 * * `insured_person` - Insured person
 * `insurance_company` - Insurance company
 */
export type TerminatedByOption =
  typeof TerminatedByOption[keyof typeof TerminatedByOption];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TerminatedByOption = {
  insured_person: 'insured_person',
  insurance_company: 'insurance_company',
} as const;

/**
 * The contact information of a legal or natural entity. Might or might not
be linked to an actual user account. Depending on use case information
might be partial and not usable for contacting on arbitrary channels.

For now we treat this as a snapshot that is immutable, but eventually we
might propagate updates from Agent and Guest side to all active processes.
 */
export interface Tenant {
  readonly all_emails: string[];
  readonly avatar_url: string;
  city?: string;
  company?: string;
  email?: string;
  first_name?: string;
  language?: string;
  last_name?: string;
  phone_number?: string;
  pk: number;
  street?: string;
  type?: string;
  readonly user_id: number;
  zipcode?: string;
}

export type TenancyTenant = number | Tenant;

export type TenancyObject = number | NestedObject;

export interface Tenancy {
  readonly end_date: string | null;
  readonly is_primary_tenant: boolean;
  readonly object: TenancyObject;
  readonly pk: number;
  readonly start_date: string;
  readonly tenant: TenancyTenant;
}

/**
 * We split up the available merge fields on this template into the common
groups object, dossier, person and other. This is just for easier
grouping on the client screen.
 */
export type TemplateGroupedMergeFields = { [key: string]: string[] };

export interface Template {
  readonly caption: string | null;
  /** We split up the available merge fields on this template into the common
groups object, dossier, person and other. This is just for easier
grouping on the client screen. */
  readonly grouped_merge_fields: TemplateGroupedMergeFields;
  readonly merge_fields: string[] | null;
  readonly name: string;
  readonly pk: number;
}

/**
 * * `flatfox` - Flatfox
 * `homegate` - Homegate
 * `immoscout24` - ImmoScout24
 */
export type TargetPlatformEnum =
  typeof TargetPlatformEnum[keyof typeof TargetPlatformEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TargetPlatformEnum = {
  flatfox: 'flatfox',
  homegate: 'homegate',
  immoscout24: 'immoscout24',
} as const;

/**
 * * `EMAIL` - EMAIL
 * `LISTING` - LISTING
 * `LISTING_ATTRIBUTE` - LISTING_ATTRIBUTE
 * `MESSENGER` - MESSENGER
 * `LANDING_PAGE` - LANDING_PAGE
 * `MANUAL_DISTRIBUTION` - MANUAL_DISTRIBUTION
 */
export type Target = typeof Target[keyof typeof Target];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Target = {
  EMAIL: 'EMAIL',
  LISTING: 'LISTING',
  LISTING_ATTRIBUTE: 'LISTING_ATTRIBUTE',
  MESSENGER: 'MESSENGER',
  LANDING_PAGE: 'LANDING_PAGE',
  MANUAL_DISTRIBUTION: 'MANUAL_DISTRIBUTION',
} as const;

/**
 * * `swisscaution` - SWISSCAUTION
 */
export type SwissCautionInsuranceDepositTypeEnum =
  typeof SwissCautionInsuranceDepositTypeEnum[keyof typeof SwissCautionInsuranceDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SwissCautionInsuranceDepositTypeEnum = {
  swisscaution: 'swisscaution',
} as const;

export interface SwissCautionInsurance {
  readonly deposit_type: SwissCautionInsuranceDepositTypeEnum;
  readonly offer_guid: string | null;
  readonly people: SecurityDepositRequestPerson[];
  readonly pk: number;
  readonly price_description: string | null;
}

export interface Subscription {
  active?: boolean;
  readonly created: string;
  east?: number | null;
  is_furnished?: boolean | null;
  is_temporary?: boolean | null;
  lat?: number | null;
  lng?: number | null;
  max_floor?: number | null;
  max_price?: number | null;
  max_rooms?: number | null;
  max_space?: number | null;
  max_year_built?: number | null;
  min_floor?: number | null;
  min_price?: number | null;
  min_rooms?: number | null;
  min_space?: number | null;
  min_year_built?: number | null;
  moving_date_from?: string | null;
  moving_date_to?: string | null;
  name?: string | null;
  north?: number | null;
  object_categories?: string[] | null;
  offer_types?: string[] | null;
  readonly pk: number;
  radius?: number | null;
  south?: number | null;
  west?: number | null;
}

/**
 * * `normal` - normal
 * `anon` - anon
 * `proxy` - proxy
 */
export type SubmitModeEnum = typeof SubmitModeEnum[keyof typeof SubmitModeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubmitModeEnum = {
  normal: 'normal',
  anon: 'anon',
  proxy: 'proxy',
} as const;

export interface Snippet {
  content: string;
  name: string;
  readonly pk: number;
}

export interface SmgPpaPrices {
  pk: number;
}

/**
 * * `TENANT` - Tenant
 * `LANDLORD` - Landlord
 * `ADDITIONAL_SIGNER` - Additional signer
 */
export type SignatureRole = typeof SignatureRole[keyof typeof SignatureRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignatureRole = {
  TENANT: 'TENANT',
  LANDLORD: 'LANDLORD',
  ADDITIONAL_SIGNER: 'ADDITIONAL_SIGNER',
} as const;

/**
 * Model of a signature request
 */
export interface SignatureRequest {
  document_type?: SignatureDocumentType;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  signatures: Signature[];
  upload: UploadField;
}

/**
 * * `RENTAL_CONTRACT` - Signing rental contract
 */
export type SignatureProcessType =
  typeof SignatureProcessType[keyof typeof SignatureProcessType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignatureProcessType = {
  RENTAL_CONTRACT: 'RENTAL_CONTRACT',
} as const;

/**
 * * `CREATED` - CREATED
 * `OPEN` - OPEN
 * `SUBMITTED_BY_TENANT` - SUBMITTED_BY_TENANT
 * `COMPLETED` - COMPLETED
 * `DECLINED` - DECLINED
 * `CANCELLED` - CANCELLED
 * `ERROR` - ERROR
 */
export type SignatureProcessStatusEnum =
  typeof SignatureProcessStatusEnum[keyof typeof SignatureProcessStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignatureProcessStatusEnum = {
  CREATED: 'CREATED',
  OPEN: 'OPEN',
  SUBMITTED_BY_TENANT: 'SUBMITTED_BY_TENANT',
  COMPLETED: 'COMPLETED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
} as const;

/**
 * Model for a signature process
 */
export interface SignatureProcess {
  readonly account_holder: string | null;
  application_id?: number | null;
  attachments?: UploadField[];
  collect_doorbell_inscription?: boolean;
  collect_payment_details?: boolean;
  collect_rent_payment_method?: boolean;
  readonly doorbell_inscription: string | null;
  readonly iban: string | null;
  listing_id?: number | null;
  message: string;
  readonly pk: number;
  readonly selected_optional_confirms: string[];
  readonly selected_required_confirms: string[];
  signature_requests: SignatureRequest[];
  readonly status: SignatureProcessStatusEnum;
  type: SignatureProcessType;
  use_default_message?: boolean;
}

/**
 * * `RENTAL_CONTRACT` - Rental contract
 * `SECONDARY_RENTAL_CONTRACT` - Secondary rental contract
 * `INITIAL_RENT_FORM` - Initial rent form
 * `RENTAL_CONTRACT_SUPPLEMENT` - Rental contract supplement
 */
export type SignatureDocumentType =
  typeof SignatureDocumentType[keyof typeof SignatureDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignatureDocumentType = {
  RENTAL_CONTRACT: 'RENTAL_CONTRACT',
  SECONDARY_RENTAL_CONTRACT: 'SECONDARY_RENTAL_CONTRACT',
  INITIAL_RENT_FORM: 'INITIAL_RENT_FORM',
  RENTAL_CONTRACT_SUPPLEMENT: 'RENTAL_CONTRACT_SUPPLEMENT',
} as const;

export type SignatureConfigurationRequiredConfirmsItem = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
};

export type SignatureConfigurationOptionalConfirmsItem = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
};

/**
 * * `EMAIL` - EMAIL
 * `MOBILE_PHONE` - MOBILE_PHONE
 */
export type SignWith = typeof SignWith[keyof typeof SignWith];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SignWith = {
  EMAIL: 'EMAIL',
  MOBILE_PHONE: 'MOBILE_PHONE',
} as const;

export interface SignatureConfiguration {
  optional_confirms?: SignatureConfigurationOptionalConfirmsItem[];
  required_confirms?: SignatureConfigurationRequiredConfirmsItem[];
  sign_with?: SignWith;
}

/**
 * Model for a signature
 */
export interface Signature {
  email?: string | null;
  first_name?: string;
  last_name?: string;
  mobile_number?: string | null;
  role: SignatureRole;
  sign_with: SignWith;
  user?: number | null;
}

/**
 * Serializer that mimics dj-rest-auth's social login serializer's fields,
minus any custom logic/validation.
 */
export interface SignInWithApple {
  access_token?: string;
  code?: string;
  first_name?: string | null;
  id_token?: string;
  last_name?: string | null;
}

export type ShareableOwner = number | NestedUser;

export type ShareableFollowersItem = number | NestedUser;

export interface Shareable {
  readonly created: string;
  followers: ShareableFollowersItem[];
  is_shared?: boolean;
  readonly modified: string;
  readonly owner: ShareableOwner;
  readonly pk: number;
  readonly sharing_url: string;
}

/**
 * * `listing` - Listing
 * `volume_discount` - Volume discount
 * `listing_day_service` - Service costs
 * `listing_day_published` - Listing costs
 * `listing_transaction` - Completion costs
 * `listing_slot` - Listing slots
 * `listing_created` - Listing
 * `flat_rate` - Flat rate
 * `monthly_minimum_fee` - Service costs - monthly minimum fee
 * `credit_check` - Credit check
 * `moving_insurance` - Moving Insurance
 * `social_boost` - Social Media Promotion
 * `immoscout_promotion` - ImmoScout24 Promotion
 * `tenant_portal_pingen` - Tenant Portal
 * `newhome_top_promotion_15` - Newhome Top Promotion 15
 * `newhome_top_promotion_30` - Newhome Top Promotion 30
 * `newhome_top_promotion_60` - Newhome Top Promotion 60
 * `newhome_special_promotion_15` - Newhome Special Promotion 15
 * `newhome_special_promotion_30` - Newhome Special Promotion 30
 * `newhome_special_promotion_60` - Newhome Special Promotion 60
 * `homegate_top_listing` - SMG TopListing
 * `homegate_premium_listing` - SMG PremiumListing
 * `immoscout_top_listing` - SMG TopListing
 * `marketing_service_backbone` - Backbone Services
 * `backbone_flat_pack` - Backbone Photo: Flat Pack
 * `backbone_villa_pack` - Backbone Photo: Villa Pack
 * `credit` - Credit
 * `facebook_ad` - Facebook Ad
 * `instagram_ad` - Instagram Ad
 * `premium_listing` - Premium Listing
 */
export type ServiceType = typeof ServiceType[keyof typeof ServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceType = {
  listing: 'listing',
  volume_discount: 'volume_discount',
  listing_day_service: 'listing_day_service',
  listing_day_published: 'listing_day_published',
  listing_transaction: 'listing_transaction',
  listing_slot: 'listing_slot',
  listing_created: 'listing_created',
  flat_rate: 'flat_rate',
  monthly_minimum_fee: 'monthly_minimum_fee',
  credit_check: 'credit_check',
  moving_insurance: 'moving_insurance',
  social_boost: 'social_boost',
  immoscout_promotion: 'immoscout_promotion',
  tenant_portal_pingen: 'tenant_portal_pingen',
  newhome_top_promotion_15: 'newhome_top_promotion_15',
  newhome_top_promotion_30: 'newhome_top_promotion_30',
  newhome_top_promotion_60: 'newhome_top_promotion_60',
  newhome_special_promotion_15: 'newhome_special_promotion_15',
  newhome_special_promotion_30: 'newhome_special_promotion_30',
  newhome_special_promotion_60: 'newhome_special_promotion_60',
  homegate_top_listing: 'homegate_top_listing',
  homegate_premium_listing: 'homegate_premium_listing',
  immoscout_top_listing: 'immoscout_top_listing',
  marketing_service_backbone: 'marketing_service_backbone',
  backbone_flat_pack: 'backbone_flat_pack',
  backbone_villa_pack: 'backbone_villa_pack',
  credit: 'credit',
  facebook_ad: 'facebook_ad',
  instagram_ad: 'instagram_ad',
  premium_listing: 'premium_listing',
} as const;

/**
 * * `IN` - IN
 * `NOT_IN` - NOT_IN
 */
export type SelectionRuleOperator =
  typeof SelectionRuleOperator[keyof typeof SelectionRuleOperator];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionRuleOperator = {
  IN: 'IN',
  NOT_IN: 'NOT_IN',
} as const;

/**
 * * `BRANCH` - BRANCH
 * `PROJECT` - PROJECT
 * `OFFER_TYPE` - OFFER_TYPE
 * `OBJECT_CATEGORY` - OBJECT_CATEGORY
 * `REFERENCE` - REFERENCE
 */
export type SelectionRuleAttribute =
  typeof SelectionRuleAttribute[keyof typeof SelectionRuleAttribute];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionRuleAttribute = {
  BRANCH: 'BRANCH',
  PROJECT: 'PROJECT',
  OFFER_TYPE: 'OFFER_TYPE',
  OBJECT_CATEGORY: 'OBJECT_CATEGORY',
  REFERENCE: 'REFERENCE',
} as const;

/**
 * * `ALL` - ALL
 * `ANY` - ANY
 * `NONE` - NONE
 */
export type SelectionRuleAggregation =
  typeof SelectionRuleAggregation[keyof typeof SelectionRuleAggregation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionRuleAggregation = {
  ALL: 'ALL',
  ANY: 'ANY',
  NONE: 'NONE',
} as const;

export interface SelectionRule {
  attribute: SelectionRuleAttribute;
  operator: SelectionRuleOperator;
  readonly pk: number;
  values: string[];
}

export interface Selection {
  readonly created: string;
  readonly modified: string;
  readonly pk: number;
  rule_aggregation: SelectionRuleAggregation;
  rules: SelectionRule[];
}

/**
 * * `hypolenzburg` - HYPOLENZBURG
 * `baloise-pdf` - BALOISE_PDF
 * `swisscaution` - SWISSCAUTION
 * `helvetia` - HELVETIA
 * `firstcaution` - FIRSTCAUTION
 * `firstcaution-baloise` - FIRSTCAUTION_BALOISE
 * `gocaution` - GOCAUTION
 * `zuger-kantonalbank-pdf` - ZUGER_KANTONALBANK_PDF
 * `zuercher-kantonalbank-pdf` - ZUERCHER_KANTONALBANK_PDF
 * `luzerner-kantonalbank-pdf` - LUZERNER_KANTONALBANK_PDF
 * `valiant-bank-pdf` - VALIANT_BANK_PDF
 * `other` - OTHER
 */
export type SecurityDepositType =
  typeof SecurityDepositType[keyof typeof SecurityDepositType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SecurityDepositType = {
  hypolenzburg: 'hypolenzburg',
  'baloise-pdf': 'baloise-pdf',
  swisscaution: 'swisscaution',
  helvetia: 'helvetia',
  firstcaution: 'firstcaution',
  'firstcaution-baloise': 'firstcaution-baloise',
  gocaution: 'gocaution',
  'zuger-kantonalbank-pdf': 'zuger-kantonalbank-pdf',
  'zuercher-kantonalbank-pdf': 'zuercher-kantonalbank-pdf',
  'luzerner-kantonalbank-pdf': 'luzerner-kantonalbank-pdf',
  'valiant-bank-pdf': 'valiant-bank-pdf',
  other: 'other',
} as const;

/**
 * * `open` - OPEN
 * `selected` - SELECTED
 * `done` - DONE
 */
export type SecurityDepositStatus =
  typeof SecurityDepositStatus[keyof typeof SecurityDepositStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SecurityDepositStatus = {
  open: 'open',
  selected: 'selected',
  done: 'done',
} as const;

/**
 * People data is stored next to a security deposit request in a separate
person model. But most of the deposit providers (companion objects) need
this data on the client too. E.g. for selecting a policy holder out of all
the people. Therefore this serializer is shared.
 */
export interface SecurityDepositRequestPerson {
  readonly age: number | null;
  readonly date_of_birth: string | null;
  readonly first_name: string | null;
  readonly hometown: string | null;
  readonly last_name: string | null;
  readonly pk: number;
}

export interface SecurityDepositRequestPdf {
  first_person_id: number;
  readonly pk: number;
  second_person_id: number | null;
}

export type SecurityDepositRequestStatus = SecurityDepositStatus | null;

/**
 * Model for a security deposit request
 */
export interface SecurityDepositRequest {
  readonly active_security_deposit_types: SecurityDepositType[];
  amount?: number | null;
  application_id: number;
  branch_id?: number | null;
  readonly created: string;
  readonly edit_token: string;
  message: string;
  monthly_gross_rent?: number | null;
  readonly people: SecurityDepositRequestPerson[];
  readonly pk: number;
  proprietor_city?: string | null;
  proprietor_name?: string | null;
  proprietor_street?: string | null;
  proprietor_zipcode: string;
  rental_contract_start_date?: string | null;
  readonly status: SecurityDepositRequestStatus;
  readonly status_changed: string | null;
}

/**
 * Model for a search profile
 */
export interface SearchProfile {
  contact: number;
  east?: number | null;
  is_furnished?: boolean | null;
  is_temporary?: boolean | null;
  lat?: number | null;
  lng?: number | null;
  max_price?: number | null;
  max_rooms?: number | null;
  max_space?: number | null;
  min_price?: number | null;
  min_rooms?: number | null;
  min_space?: number | null;
  moving_date_from?: string | null;
  moving_date_to?: string | null;
  name?: string | null;
  north?: number | null;
  object_categories?: string[] | null;
  offer_types?: string[] | null;
  readonly pk: number;
  radius?: number | null;
  south?: number | null;
  west?: number | null;
}

export interface SearchHouse {
  readonly city: string | null;
  readonly label: string;
  readonly pk: number;
  readonly property_id: number | null;
  readonly ref_house: string;
  readonly ref_property: string;
  readonly street: string | null;
  readonly zipcode: string | null;
}

/**
 * * `CONFIRMED` - ACTIVE
 * `CANCELLED` - CANCELLED
 */
export type ScheduleEventStatus =
  typeof ScheduleEventStatus[keyof typeof ScheduleEventStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduleEventStatus = {
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
} as const;

export interface ScheduleEvent {
  readonly attendees_count: number;
  end?: string | null;
  readonly is_fully_booked: boolean;
  readonly num_slots: number | null;
  pk?: number | null;
  start: string;
  readonly status: ScheduleEventStatus;
}

export type ScheduleViewingHandler = number | NestedUser;

/**
 * Model for a viewing schedule
 */
export interface Schedule {
  additional_info?: string | null;
  readonly attend_url: string | null;
  auto_invite?: boolean;
  readonly created: string;
  events?: ScheduleEvent[];
  listing_id: number;
  num_slots?: number | null;
  readonly num_survey_responses: number;
  readonly owner: number;
  readonly pk: number;
  readonly survey_url: string | null;
  viewing_handler?: ScheduleViewingHandler;
}

/**
 * * `mr` - Mr.
 * `ms` - Ms.
 */
export type Salutation = typeof Salutation[keyof typeof Salutation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Salutation = {
  mr: 'mr',
  ms: 'ms',
} as const;

export interface Rotate {
  degrees: DegreesEnum;
}

/**
 * * `applicant` - APPLICANT
 * `advertiser` - ADVERTISER
 * `agent` - AGENT
 * `agent-notice` - AGENT-NOTICE
 */
export type Role = typeof Role[keyof typeof Role];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  applicant: 'applicant',
  advertiser: 'advertiser',
  agent: 'agent',
  'agent-notice': 'agent-notice',
} as const;

/**
 * * `B` - B (Resident foreign national)
 * `C` - C (Settled foreign national)
 * `D` - D (Type D visa)
 * `L` - L (Short-term resident)
 * `G` - G (Cross-border commuter)
 * `F` - F (Provisionally admitted)
 * `N` - N (Asylum-seeker)
 * `S` - S (In need of protection)
 * `NONE` - None
 */
export type ResidencePermit = typeof ResidencePermit[keyof typeof ResidencePermit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResidencePermit = {
  B: 'B',
  C: 'C',
  D: 'D',
  L: 'L',
  G: 'G',
  F: 'F',
  N: 'N',
  S: 'S',
  NONE: 'NONE',
} as const;

/**
 * This one could be extended with more fields if we need them, for now
it's just first_name and last_name which we use for dropdown labels
 */
export interface RentalContractPerson {
  first_name?: string;
  last_name: string;
}

/**
 * Model for a rental contract
 */
export interface RentalContract {
  application_id?: number | null;
  readonly created: string;
  documents: DocumentUpload[];
  dossier_id: string | null;
  persons: RentalContractPerson[];
  readonly pk: number;
  reference: string;
  rental_contract_number?: string;
}

/**
 * * `no-applications` - NO_APPLICATONS
 * `with-applications` - WITH_APPLICATIONS
 * `reserved` - RESERVED
 * `rented` - RENTED
 */
export type RentStatusEnum = typeof RentStatusEnum[keyof typeof RentStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RentStatusEnum = {
  'no-applications': 'no-applications',
  'with-applications': 'with-applications',
  reserved: 'reserved',
  rented: 'rented',
} as const;

export interface RenderedMessage {
  sender_avatar_url: string;
  sender_name: string;
  text: string;
}

/**
 * * `SPOUSE` - Spouse
 * `REGISTERED_PARTNER` - Registered partner
 * `CO_APPLICANT` - Co-Applicant
 * `PERSON_IN_SOLIDARITY` - Person in solidarity
 * `OTHER` - Other
 */
export type RelationshipToMain =
  typeof RelationshipToMain[keyof typeof RelationshipToMain];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelationshipToMain = {
  SPOUSE: 'SPOUSE',
  REGISTERED_PARTNER: 'REGISTERED_PARTNER',
  CO_APPLICANT: 'CO_APPLICANT',
  PERSON_IN_SOLIDARITY: 'PERSON_IN_SOLIDARITY',
  OTHER: 'OTHER',
} as const;

/**
 * * `THUMBS_UP` - THUMBS_UP
 * `THUMBS_DOWN` - THUMBS_DOWN
 */
export type ReactionTypeEnum = typeof ReactionTypeEnum[keyof typeof ReactionTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReactionTypeEnum = {
  THUMBS_UP: 'THUMBS_UP',
  THUMBS_DOWN: 'THUMBS_DOWN',
} as const;

/**
 * * `1` - 1
 * `2` - 2
 * `3` - 3
 * `4` - 4
 * `5` - 5
 */
export type RatingEnum = typeof RatingEnum[keyof typeof RatingEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RatingEnum = {
  NUMBER_1: '1',
  NUMBER_2: '2',
  NUMBER_3: '3',
  NUMBER_4: '4',
  NUMBER_5: '5',
} as const;

/**
 * * `requested` - Requested
 * `open` - Open
 * `accepted` - Accepted
 * `rejected` - Rejected
 */
export type QuoteStatusEnum = typeof QuoteStatusEnum[keyof typeof QuoteStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuoteStatusEnum = {
  requested: 'requested',
  open: 'open',
  accepted: 'accepted',
  rejected: 'rejected',
} as const;

export type QuoteTicket = number | NestedTicket;

export type QuoteSupplier = number | NestedUser;

export type QuoteCreatedBy = number | NestedUser;

/**
 * Model for a ticket quote
 */
export interface Quote {
  readonly agency: TicketAgency;
  attachments: UploadField[];
  contact_email: string;
  contact_full_name: string;
  contact_phone_number: string | null;
  contact_reference: string | null;
  readonly created: string;
  created_by?: QuoteCreatedBy;
  readonly house_id: number;
  readonly message_from_supplier: string | null;
  message_text: string;
  readonly modified: string;
  readonly number: number;
  readonly offer_pdf_name: string;
  readonly offer_pdf_url: string;
  readonly pk: number;
  status: QuoteStatusEnum;
  supplier?: QuoteSupplier;
  readonly supplier_full_name: string;
  readonly supplier_reference: string | null;
  ticket?: QuoteTicket;
}

/**
 * * `LISTING_CREATE` - LISTING_CREATE
 * `LISTING_ACTIVE` - LISTING_ACTIVE
 */
export type QuotaType = typeof QuotaType[keyof typeof QuotaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuotaType = {
  LISTING_CREATE: 'LISTING_CREATE',
  LISTING_ACTIVE: 'LISTING_ACTIVE',
} as const;

export interface Quota {
  available: number;
  quota: QuotaType;
  reason: string;
  used: number;
}

export interface QuicklineInquiry {
  ad_pk: number;
  city?: string;
  readonly created: string;
  email?: string;
  first_name?: string;
  gender?: Gender;
  last_name?: string;
  phone_number?: string;
  readonly pk: number;
  products?: ProductType[] | null;
  street?: string;
  street_number?: string;
  readonly user: number | null;
  zipcode?: number | null;
}

export type PurchaseVatType = VatType | null;

export type PurchaseInvoice = number | Invoice;

/**
 * Model for a purchase
 */
export interface Purchase {
  billing_address?: string;
  billing_email?: string;
  billing_remarks?: string;
  readonly created: string;
  readonly currency: string;
  readonly date: string;
  readonly date_through: string | null;
  flat?: number | null;
  readonly invoice: PurchaseInvoice;
  invoice_type: InvoiceType;
  readonly is_authorized: boolean;
  readonly is_cancelled: boolean;
  readonly is_charged: boolean;
  readonly is_refunded: boolean;
  readonly pk: number;
  quantity: string;
  service_type: ServiceType;
  stripe_payment_intent_id?: string | null;
  readonly token: string;
  unit_price: number;
  readonly user: number;
  vat_type?: PurchaseVatType;
}

export interface PublicViewing {
  end?: string | null;
  readonly pk: number;
  start: string;
}

export interface PublicTerminatedObject {
  address: ObjectAddress;
  available_from: string;
  readonly pk: number;
  termination_date: string;
  type: TerminatedObjectType;
}

export interface PublicSigner {
  first_name: string;
  last_name: string;
}

export interface PublicSearchHouse {
  readonly city: string | null;
  readonly label: string;
  readonly pk: number;
  readonly property_id: number | null;
  readonly ref_house: string;
  readonly ref_property: string;
  readonly street: string | null;
  readonly zipcode: string | null;
}

export interface PublicScheduleEvent {
  readonly end: string | null;
  readonly is_fully_booked: boolean;
  readonly pk: number;
  readonly start: string;
}

/**
 * Model for a public viewing schedule
 */
export interface PublicSchedule {
  readonly additional_info: string | null;
  events: PublicScheduleEvent[];
  readonly pk: number;
}

export type PublicListingDetailPriceUnit = PriceUnit | null;

export type PublicListingDetailPriceDisplayType = PriceDisplayType | null;

export type PublicListingDetailObjectType = ObjectType | null;

export type PublicListingDetailObjectCategory = ObjectCategory | null;

export type PublicListingDetailMovingDateType = MovingDateType | null;

export type PublicListingDetailImagesItem = number | FlatImage;

export type PublicListingDetailDocumentsItem = number | FlatDocuments;

export type PublicListingDetailCoverImage = number | FlatImage;

/**
 * This serializer is used for the retrieve action, as it includes some heavy
performance operations which we don't need for the list action anyway.
 */
export interface PublicListingDetail {
  agency: ListingAgency;
  readonly alternative_reference: string;
  readonly attributes: FlatAttr[];
  readonly can_direct_apply: boolean;
  readonly city: string;
  readonly country: string;
  readonly cover_image: PublicListingDetailCoverImage;
  readonly created: string;
  readonly description: string;
  readonly description_title: string | null;
  readonly display_data: string[];
  readonly documents: PublicListingDetailDocumentsItem[];
  readonly floor: number | null;
  readonly formatted: ListingFormatter;
  readonly full_address: string;
  readonly images: PublicListingDetailImagesItem[];
  readonly is_disliked: boolean | null;
  readonly is_furnished: boolean;
  readonly is_liked: boolean | null;
  readonly is_selling_furniture: boolean;
  readonly is_subscribed: boolean | null;
  readonly is_temporary: boolean;
  readonly latitude: number | null;
  readonly live_viewing_url: string | null;
  /** @deprecated */
  readonly livingspace: number | null;
  readonly longitude: number | null;
  readonly moving_date: string | null;
  readonly moving_date_type: PublicListingDetailMovingDateType;
  readonly number_of_rooms: string | null;
  readonly object_category: PublicListingDetailObjectCategory;
  readonly object_type: PublicListingDetailObjectType;
  readonly offer_type: OfferType;
  readonly pitch_title: string;
  readonly pk: number;
  readonly price_display: number | null;
  readonly price_display_type: PublicListingDetailPriceDisplayType;
  readonly price_unit: PublicListingDetailPriceUnit;
  readonly public_address: string;
  readonly public_title: string;
  readonly published: string | null;
  readonly ref_house: string;
  readonly ref_object: string;
  readonly ref_property: string;
  readonly reference: string;
  readonly rent_charges: number | null;
  readonly rent_gross: number | null;
  readonly rent_net: number | null;
  /**
   * DEPRECATED, use `pitch_title`.
   * @deprecated
   */
  readonly rent_title: string;
  readonly reserved: boolean;
  readonly short_title: string;
  readonly short_url: string;
  readonly show_exact_address: boolean;
  readonly slug: string | null;
  readonly space_display: number | null;
  readonly state: string | null;
  readonly status: ListingStatus;
  readonly street: string | null;
  readonly submit_url: string;
  readonly surface_living: number | null;
  readonly surface_property: number | null;
  readonly surface_usable: number | null;
  readonly surface_usable_minimum: number | null;
  /** Get thread url for current user if current user is authenticated.
Beware, this will be used in the app to determine if the user has already
applied. */
  readonly thread_url: string | null;
  readonly tour_url: string | null;
  readonly url: string;
  readonly video_url: string | null;
  readonly volume: number | null;
  readonly website_url: string | null;
  readonly year_built: number | null;
  readonly year_renovated: number | null;
  readonly zipcode: number;
}

export type PublicListingPriceUnit = PriceUnit | null;

export type PublicListingPriceDisplayType = PriceDisplayType | null;

export type PublicListingObjectType = ObjectType | null;

export type PublicListingObjectCategory = ObjectCategory | null;

export type PublicListingMovingDateType = MovingDateType | null;

export type PublicListingImagesItem = number | FlatImage;

export type PublicListingDocumentsItem = number | FlatDocuments;

export type PublicListingCoverImage = number | FlatImage;

/**
 * Used for the list action, as it excludes some heavy performance operations.
See PublicListingDetailSerializer for the retrieve action.
 */
export interface PublicListing {
  agency: ListingAgency;
  readonly alternative_reference: string;
  readonly attributes: FlatAttr[];
  readonly city: string;
  readonly country: string;
  readonly cover_image: PublicListingCoverImage;
  readonly created: string;
  readonly description: string;
  readonly description_title: string | null;
  readonly display_data: string[];
  readonly documents: PublicListingDocumentsItem[];
  readonly floor: number | null;
  readonly formatted: ListingFormatter;
  readonly full_address: string;
  readonly images: PublicListingImagesItem[];
  readonly is_disliked: boolean | null;
  readonly is_furnished: boolean;
  readonly is_liked: boolean | null;
  readonly is_selling_furniture: boolean;
  readonly is_subscribed: boolean | null;
  readonly is_temporary: boolean;
  readonly latitude: number | null;
  readonly live_viewing_url: string | null;
  /** @deprecated */
  readonly livingspace: number | null;
  readonly longitude: number | null;
  readonly moving_date: string | null;
  readonly moving_date_type: PublicListingMovingDateType;
  readonly number_of_rooms: string | null;
  readonly object_category: PublicListingObjectCategory;
  readonly object_type: PublicListingObjectType;
  readonly offer_type: OfferType;
  readonly pitch_title: string;
  readonly pk: number;
  readonly price_display: number | null;
  readonly price_display_type: PublicListingPriceDisplayType;
  readonly price_unit: PublicListingPriceUnit;
  readonly public_address: string;
  readonly public_title: string;
  readonly published: string | null;
  readonly ref_house: string;
  readonly ref_object: string;
  readonly ref_property: string;
  readonly reference: string;
  readonly rent_charges: number | null;
  readonly rent_gross: number | null;
  readonly rent_net: number | null;
  /**
   * DEPRECATED, use `pitch_title`.
   * @deprecated
   */
  readonly rent_title: string;
  readonly reserved: boolean;
  readonly short_title: string;
  readonly short_url: string;
  readonly show_exact_address: boolean;
  readonly slug: string | null;
  readonly space_display: number | null;
  readonly state: string | null;
  readonly status: ListingStatus;
  readonly street: string | null;
  readonly submit_url: string;
  readonly surface_living: number | null;
  readonly surface_property: number | null;
  readonly surface_usable: number | null;
  readonly surface_usable_minimum: number | null;
  /** Get thread url for current user if current user is authenticated.
Beware, this will be used in the app to determine if the user has already
applied. */
  readonly thread_url: string | null;
  readonly tour_url: string | null;
  readonly url: string;
  readonly video_url: string | null;
  readonly volume: number | null;
  readonly website_url: string | null;
  readonly year_built: number | null;
  readonly year_renovated: number | null;
  readonly zipcode: number;
}

export type PublicLeaseTerminationAgency = { [key: string]: unknown };

export interface PublicLeaseTermination {
  readonly agency: PublicLeaseTerminationAgency;
  readonly created: string;
  message_to_landlord?: string | null;
  readonly modified: string;
  next_tenants?: PublicContactInformation[];
  owner: number;
  readonly pk: number;
  requester: PublicContactInformation;
  signers: PublicSigner[];
  terminated_objects: PublicTerminatedObject[];
  readonly termination_letter_pdf_name: string;
  readonly termination_letter_pdf_size: number;
  readonly termination_letter_pdf_url: string;
  termination_reason: TerminationReasonEnum;
  viewing_contact_public?: boolean;
  viewing_handler?: PublicContactInformation;
  viewings?: PublicViewing[];
}

export type PublicInquiryDecisionsItemName = {
  de: string;
  en: string;
  fr: string;
  it: string;
};

export type PublicInquiryDecisionsItem = {
  key: string;
  name: PublicInquiryDecisionsItemName;
  next: string;
  step: string;
};

export interface PublicInquiry {
  decisions: PublicInquiryDecisionsItem[];
  doorbell_inscription: string;
}

/**
 * Like ContactInformationSerializer, but meant to start new processes from
public, potentially anonymous endpoints. Creating Tickets, terminating
leases. Notably, email always needs to be set such that we can send
replies.
 */
export interface PublicContactInformation {
  readonly avatar_url: string;
  company?: string;
  email: string;
  first_name: string;
  language?: string;
  last_name: string;
  phone_number: string;
  readonly user_id: number;
}

export interface PublicInquiryTicket {
  address: ObjectAddress;
  attachments?: UploadField[];
  readonly created: string;
  description: string;
  inquiry: PublicInquiry;
  readonly modified: string;
  readonly number: number;
  owner: number;
  readonly pk: number;
  requester: PublicContactInformation;
  title: string;
}

export interface PublicB2CTerminatedObject {
  address: ObjectAddress;
  available_from: string;
  termination_date: string;
  type: TerminatedObjectType;
}

export interface PublicAgencyContact {
  city: string;
  name: string;
  slug: string;
  street: string;
  zipcode: string;
}

export interface PublicB2CLeaseTermination {
  agency: PublicAgencyContact;
  message_to_landlord: string;
  next_tenants?: PublicContactInformation[];
  requester: PublicContactInformation;
  signers: PublicSigner[];
  terminated_objects: PublicB2CTerminatedObject[];
  readonly termination_letter_pdf_name: string;
  readonly termination_letter_pdf_size: number;
  readonly termination_letter_pdf_url: string;
  termination_reason: string;
  viewing_handler?: PublicContactInformation;
  viewings?: PublicViewing[];
}

/**
 * An `Agency` can be either an `Organization` or a `Branch`.
 */
export interface PublicAgency {
  /** Null when this `Agency` is an `Organization`. */
  branch_id: number | null;
  city: string;
  country: string;
  email: string | null;
  /** Null when this `Agency` is an `Organization`. */
  externally_administered: boolean | null;
  name: string;
  name_2: string;
  organization_id: number;
  /** The slug uniquely identifies the agency. You can use it to navigate to the landing pages (ticketing, termination, ...) of that agency. */
  slug: string;
  street: string;
  readonly url_org_logo_m: string | null;
  zipcode: string;
}

/**
 * * `supervisor` - SUPERVISOR
 * `deputy` - DEPUTY
 * `manager` - MANAGER
 * `assistant` - ASSISTANT
 * `lessor` - LESSOR
 * `field_representative` - FIELD_REPRESENTATIVE
 * `vacancy_manager` - VACANCY_MANAGER
 * `facility_manager` - FACILITY_MANAGER
 * `accountant` - ACCOUNTANT
 */
export type PropertyRole = typeof PropertyRole[keyof typeof PropertyRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyRole = {
  supervisor: 'supervisor',
  deputy: 'deputy',
  manager: 'manager',
  assistant: 'assistant',
  lessor: 'lessor',
  field_representative: 'field_representative',
  vacancy_manager: 'vacancy_manager',
  facility_manager: 'facility_manager',
  accountant: 'accountant',
} as const;

export type PropertyRolesItem = number | NestedRole;

export type PropertyBranch = number | Branch;

export interface Property {
  readonly branch: PropertyBranch;
  readonly owner: number;
  readonly pk: number;
  ref_property: string;
  readonly roles: PropertyRolesItem[];
}

/**
 * * `debt_extract` - DEBT_EXTRACT
 * `credit_trust_or_debt_extract` - CREDIT_TRUST_OR_DEBT_EXTRACT
 */
export type ProofOfCreditworthinessType =
  typeof ProofOfCreditworthinessType[keyof typeof ProofOfCreditworthinessType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfCreditworthinessType = {
  debt_extract: 'debt_extract',
  credit_trust_or_debt_extract: 'credit_trust_or_debt_extract',
} as const;

/**
 * * `social_boost` - Social Media Promotion
 * `immoscout_promotion` - ImmoScout24 Promotion
 */
export type PromotionType = typeof PromotionType[keyof typeof PromotionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PromotionType = {
  social_boost: 'social_boost',
  immoscout_promotion: 'immoscout_promotion',
} as const;

/**
 * * `PENDING_REVIEW` - PENDING_REVIEW
 * `ACTIVE` - ACTIVE
 * `ARCHIVED` - ARCHIVED
 * `PAUSED` - PAUSED
 */
export type PromotionStatus = typeof PromotionStatus[keyof typeof PromotionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PromotionStatus = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  PAUSED: 'PAUSED',
} as const;

/**
 * Model for a promotion order
 */
export interface Promotion {
  ad_claim?: string | null;
  ad_image?: number | null;
  ad_text?: string | null;
  billing_address?: string;
  billing_email?: string;
  billing_remarks?: string;
  readonly clicks: number | null;
  readonly created: string;
  days?: number | null;
  readonly end_time: string;
  readonly facebook_ad_preview_url: string | null;
  readonly impressions: number | null;
  readonly instagram_ad_preview_url: string | null;
  lat?: number | null;
  lifetime_budget: number;
  listing: number;
  lng?: number | null;
  readonly pk: number;
  readonly progress: number;
  purchase_token: string;
  radius?: number | null;
  readonly start_time: string;
  readonly status: PromotionStatus;
  type: PromotionType;
  vat_type: VatType;
}

export type ProjectAutoReservationCondition = AutoReservationCondition | null;

export interface Project {
  auto_reservation_condition?: ProjectAutoReservationCondition;
  readonly created: string;
  readonly modified: string;
  name?: string;
  readonly num_listings: number;
  ordering?: number;
  readonly owner: number;
  readonly pk: number;
  /** Rules support Unix style pattern matching, may use ? and * */
  reference_rules?: string[] | null;
  separate_invoice?: boolean;
}

/**
 * * `INTERNET` - Internet
 * `TV` - TV
 * `MOBILE` - Mobile
 * `LANDLINE` - Landline
 */
export type ProductType = typeof ProductType[keyof typeof ProductType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductType = {
  INTERNET: 'INTERNET',
  TV: 'TV',
  MOBILE: 'MOBILE',
  LANDLINE: 'LANDLINE',
} as const;

export type PrivateLiabilityOrderFormerInsuranceTerminatedBy = TerminatedByOption | null;

export type PrivateLiabilityOrderContractDurationInDays =
  ContractDurationInDaysEnum | null;

export interface PrivateLiabilityOrder {
  contract_duration_in_days?: PrivateLiabilityOrderContractDurationInDays;
  contract_start_date?: string | null;
  readonly created: string;
  former_insurance_exists?: boolean | null;
  former_insurance_name?: string | null;
  former_insurance_terminated?: boolean | null;
  former_insurance_terminated_by?: PrivateLiabilityOrderFormerInsuranceTerminatedBy;
  insured_persons: InsuredPerson[];
  living_arrangement: LivingArrangementOption;
  option_tpv: ThirdPartyDriverOption;
  other_already_rejected?: boolean | null;
  readonly pk: number;
  start_asap?: boolean | null;
  readonly status: OrderStatus;
  three_damages_filed?: boolean | null;
}

/**
 * * `sell` - total
 * `sellm2` - total / m²
 * `daily` - per day
 * `weekly` - per week
 * `monthly` - per month
 * `yearly` - per year
 * `yearlym2` - per m² / year
 */
export type PriceUnit = typeof PriceUnit[keyof typeof PriceUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PriceUnit = {
  sell: 'sell',
  sellm2: 'sellm2',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
  yearlym2: 'yearlym2',
} as const;

/**
 * * `TOTAL` - TOTAL
 * `M2` - M2
 */
export type PriceDisplayType = typeof PriceDisplayType[keyof typeof PriceDisplayType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PriceDisplayType = {
  TOTAL: 'TOTAL',
  M2: 'M2',
} as const;

/**
 * Model for a letter preview
 */
export interface PreviewLetter {
  attachments?: UploadField[];
  branch_id?: number | null;
  content?: string;
  language: Languages;
  readonly letter_preview_pdf_url: string;
  recipient_contacts: ContactInformation[];
  sender_contact?: ContactInformation;
  subject?: string;
}

export type PreListingObjectType = ObjectType | null;

export type PreListingObjectCategory = ObjectCategory | null;

/**
 * Model for a pre-listing
 */
export interface PreListing {
  city: string;
  country?: string;
  lease_termination: number;
  object_category?: PreListingObjectCategory;
  object_type?: PreListingObjectType;
  offer_type?: OfferType;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  show_exact_address?: boolean;
  street?: string | null;
  zipcode: number;
}

/**
 * * `FLATFOX` - Flatfox
 * `ACHETERLOUER` - acheter-louer.ch
 * `ACLADO` - aclado
 * `HOMEGATE` - homegate
 * `IMMOBILIER` - immobilier.ch
 * `IMMOMIG` - immomigsa.ch
 * `IMMOSCOUT` - ImmoScout24
 * `IMMOSTREET` - immostreet
 * `IMMOWELT` - immowelt
 * `NEWHOME` - newhome
 * `URBANHOME` - urbanhome
 * `SMG` - smg
 * `OTHER` - Other
 * `DIRECT` - Direct
 */
export type PortalType = typeof PortalType[keyof typeof PortalType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortalType = {
  FLATFOX: 'FLATFOX',
  ACHETERLOUER: 'ACHETERLOUER',
  ACLADO: 'ACLADO',
  HOMEGATE: 'HOMEGATE',
  IMMOBILIER: 'IMMOBILIER',
  IMMOMIG: 'IMMOMIG',
  IMMOSCOUT: 'IMMOSCOUT',
  IMMOSTREET: 'IMMOSTREET',
  IMMOWELT: 'IMMOWELT',
  NEWHOME: 'NEWHOME',
  URBANHOME: 'URBANHOME',
  SMG: 'SMG',
  OTHER: 'OTHER',
  DIRECT: 'DIRECT',
} as const;

export interface PortalStats {
  advertiser: number;
  branch: number;
  city: string;
  readonly date: string;
  readonly days_disabled: number;
  readonly days_prepublished: number;
  readonly days_published: number;
  listing: number;
  readonly num_applications: number;
  readonly num_chosen: number;
  readonly num_contact_requests: number;
  readonly num_views: number;
  object_category: string;
  readonly portal_type: PortalType;
  project: number;
  ref_house: string;
  ref_object: string;
  ref_property: string;
  reference: string;
  street: string;
  zipcode: string;
}

export type PinPriceUnit = PriceUnit | null;

export type PinPriceDisplayType = PriceDisplayType | null;

export interface Pin {
  readonly is_disliked: boolean;
  readonly is_in_region: boolean;
  readonly is_liked: boolean;
  latitude: number;
  readonly like_status: string;
  longitude: number;
  readonly pk: number;
  readonly price_display: number | null;
  readonly price_display_type: PinPriceDisplayType;
  readonly price_unit: PinPriceUnit;
  readonly selling_price: number | null;
}

export interface PhoneNumber {
  phone_number: string;
  readonly pk: number;
  token?: string;
  readonly verified: boolean;
  via?: VerificationMethod;
}

export interface PersonStats {
  advertiser: number;
  readonly age: number | null;
  readonly age_b: number | null;
  application: number;
  branch: number;
  readonly canton_of_origin: string | null;
  readonly citizenship: string | null;
  closed: string;
  created: string;
  form_submitted: string;
  readonly gender: string | null;
  listing: number;
  marital_status: string;
  object_category: string;
  portal_type: string;
  project: number;
  pushed: string;
  ref_house: string;
  ref_object: string;
  ref_property: string;
  reference: string;
  status: string;
  submitted: boolean;
}

export type PersonResidencePermit = ResidencePermit | null;

export type PersonRelationshipToMain = RelationshipToMain | null;

export type PersonPhoto = DossierImage | null;

export type PersonMaritalStatus = MaritalStatus | null;

export type PersonJobStatus = JobStatus | null;

export type PersonGender = Gender | null;

export type PersonAge = number | boolean;

export interface Person {
  readonly age: PersonAge;
  citizenship?: string | null;
  city?: string | null;
  collection_procedures?: boolean | null;
  confirmation_of_residence?: DossierDocument[];
  current_financing_confirmation?: DossierDocument[];
  date_of_birth?: string | null;
  disability_pension_statement?: DossierDocument[];
  earlier_address?: string | null;
  earlier_landlord?: string | null;
  email?: string | null;
  employer?: string | null;
  employer_contact_email?: string | null;
  employer_contact_name?: string | null;
  employer_contact_phone?: string | null;
  first_name?: string | null;
  gender?: PersonGender;
  guardianship_type?: string | null;
  has_guardianship?: boolean | null;
  has_moved_in_past_year?: boolean | null;
  hometown?: string | null;
  id_card?: DossierDocument[];
  in_rent_since?: string | null;
  readonly is_main: boolean;
  job_employed_since?: string | null;
  job_employed_until?: string | null;
  job_gross_salary_end?: number | null;
  job_gross_salary_start?: number | null;
  job_is_temporary?: boolean | null;
  job_place_of_work?: string | null;
  job_status?: PersonJobStatus;
  job_title?: string | null;
  landlord?: string | null;
  landlord_contact_email?: string | null;
  landlord_contact_name?: string | null;
  landlord_contact_phone?: string | null;
  last_name?: string | null;
  lives_with_main?: boolean;
  marital_status?: PersonMaritalStatus;
  office_phone_number?: string | null;
  phone_number?: string | null;
  photo?: PersonPhoto;
  readonly pk: number;
  previous_rent?: string | null;
  proof_of_creditworthiness?: DossierDocument[];
  relationship_to_main?: PersonRelationshipToMain;
  residence_permit?: PersonResidencePermit;
  salary_statement?: DossierDocument[];
  street?: string | null;
  tax_assessment?: DossierDocument[];
  unemployed_statement?: DossierDocument[];
  unia_membership_number?: string | null;
  zipcode?: string | null;
}

export interface PaymentIntent {
  amount: number;
}

export interface PatchedWebhook {
  delivery_type?: WebhookDelivery;
  event_types?: unknown[];
  name?: string;
  push_url?: string | null;
}

export interface PatchedUserNotificationConfig {
  application_forms?: UserNotificationDeliveryMethod;
  direct_messages?: UserNotificationDeliveryMethod;
  note_reminders?: UserNotificationDeliveryMethod;
  recommendations?: UserNotificationDeliveryMethod;
  viewing_confirmations?: UserNotificationDeliveryMethod;
}

export interface PatchedTicket {
  address?: ObjectAddress;
  attachments?: UploadField[];
  broadcast?: number;
  readonly created?: string;
  description?: string;
  follower_set?: TicketFollower[];
  inquiry?: TicketInquiry;
  readonly modified?: string;
  readonly number?: number;
  owner?: number;
  readonly pk?: number;
  requester?: ContactInformation;
  status?: TicketStatus;
  readonly submitter?: ContactInformation;
  readonly summary_pdf_url?: string;
  readonly summary_zip_url?: string;
  title?: string;
}

export interface PatchedSubscription {
  active?: boolean;
  readonly created?: string;
  east?: number | null;
  is_furnished?: boolean | null;
  is_temporary?: boolean | null;
  lat?: number | null;
  lng?: number | null;
  max_floor?: number | null;
  max_price?: number | null;
  max_rooms?: number | null;
  max_space?: number | null;
  max_year_built?: number | null;
  min_floor?: number | null;
  min_price?: number | null;
  min_rooms?: number | null;
  min_space?: number | null;
  min_year_built?: number | null;
  moving_date_from?: string | null;
  moving_date_to?: string | null;
  name?: string | null;
  north?: number | null;
  object_categories?: string[] | null;
  offer_types?: string[] | null;
  readonly pk?: number;
  radius?: number | null;
  south?: number | null;
  west?: number | null;
}

export interface PatchedSnippet {
  content?: string;
  name?: string;
  readonly pk?: number;
}

export type PatchedSignatureConfigurationRequiredConfirmsItem = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
};

export type PatchedSignatureConfigurationOptionalConfirmsItem = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
};

export interface PatchedSignatureConfiguration {
  optional_confirms?: PatchedSignatureConfigurationOptionalConfirmsItem[];
  required_confirms?: PatchedSignatureConfigurationRequiredConfirmsItem[];
  sign_with?: SignWith;
}

export type PatchedShareableOwner = number | NestedUser;

export type PatchedShareableFollowersItem = number | NestedUser;

export interface PatchedShareable {
  readonly created?: string;
  followers?: PatchedShareableFollowersItem[];
  is_shared?: boolean;
  readonly modified?: string;
  readonly owner?: PatchedShareableOwner;
  readonly pk?: number;
  readonly sharing_url?: string;
}

export interface PatchedSelection {
  readonly created?: string;
  readonly modified?: string;
  readonly pk?: number;
  rule_aggregation?: SelectionRuleAggregation;
  rules?: SelectionRule[];
}

/**
 * Model for a search profile
 */
export interface PatchedSearchProfile {
  contact?: number;
  east?: number | null;
  is_furnished?: boolean | null;
  is_temporary?: boolean | null;
  lat?: number | null;
  lng?: number | null;
  max_price?: number | null;
  max_rooms?: number | null;
  max_space?: number | null;
  min_price?: number | null;
  min_rooms?: number | null;
  min_space?: number | null;
  moving_date_from?: string | null;
  moving_date_to?: string | null;
  name?: string | null;
  north?: number | null;
  object_categories?: string[] | null;
  offer_types?: string[] | null;
  readonly pk?: number;
  radius?: number | null;
  south?: number | null;
  west?: number | null;
}

export type PatchedScheduleViewingHandler = number | NestedUser;

/**
 * Model for a viewing schedule
 */
export interface PatchedSchedule {
  additional_info?: string | null;
  readonly attend_url?: string | null;
  auto_invite?: boolean;
  readonly created?: string;
  events?: ScheduleEvent[];
  listing_id?: number;
  num_slots?: number | null;
  readonly num_survey_responses?: number;
  readonly owner?: number;
  readonly pk?: number;
  readonly survey_url?: string | null;
  viewing_handler?: PatchedScheduleViewingHandler;
}

export type PatchedPurchaseVatType = VatType | null;

export type PatchedPurchaseInvoice = number | Invoice;

/**
 * Model for a purchase
 */
export interface PatchedPurchase {
  billing_address?: string;
  billing_email?: string;
  billing_remarks?: string;
  readonly created?: string;
  readonly currency?: string;
  readonly date?: string;
  readonly date_through?: string | null;
  flat?: number | null;
  readonly invoice?: PatchedPurchaseInvoice;
  invoice_type?: InvoiceType;
  readonly is_authorized?: boolean;
  readonly is_cancelled?: boolean;
  readonly is_charged?: boolean;
  readonly is_refunded?: boolean;
  readonly pk?: number;
  quantity?: string;
  service_type?: ServiceType;
  stripe_payment_intent_id?: string | null;
  readonly token?: string;
  unit_price?: number;
  readonly user?: number;
  vat_type?: PatchedPurchaseVatType;
}

/**
 * Model for a promotion order
 */
export interface PatchedPromotion {
  ad_claim?: string | null;
  ad_image?: number | null;
  ad_text?: string | null;
  billing_address?: string;
  billing_email?: string;
  billing_remarks?: string;
  readonly clicks?: number | null;
  readonly created?: string;
  days?: number | null;
  readonly end_time?: string;
  readonly facebook_ad_preview_url?: string | null;
  readonly impressions?: number | null;
  readonly instagram_ad_preview_url?: string | null;
  lat?: number | null;
  lifetime_budget?: number;
  listing?: number;
  lng?: number | null;
  readonly pk?: number;
  readonly progress?: number;
  purchase_token?: string;
  radius?: number | null;
  readonly start_time?: string;
  readonly status?: PromotionStatus;
  type?: PromotionType;
  vat_type?: VatType;
}

export type PatchedProjectAutoReservationCondition = AutoReservationCondition | null;

export interface PatchedProject {
  auto_reservation_condition?: PatchedProjectAutoReservationCondition;
  readonly created?: string;
  readonly modified?: string;
  name?: string;
  readonly num_listings?: number;
  ordering?: number;
  readonly owner?: number;
  readonly pk?: number;
  /** Rules support Unix style pattern matching, may use ? and * */
  reference_rules?: string[] | null;
  separate_invoice?: boolean;
}

export interface PatchedPhoneNumber {
  phone_number?: string;
  readonly pk?: number;
  token?: string;
  readonly verified?: boolean;
  via?: VerificationMethod;
}

export type PatchedNoteUser = number | NestedUser | null;

export type PatchedNoteTicket = number | NestedTicket | null;

export type PatchedNoteListing = number | NestedListing | null;

export type PatchedNoteLeaseTermination = number | NestedLeaseTermination | null;

export type PatchedNoteAuthor = number | NestedUser;

export interface PatchedNote {
  readonly author?: PatchedNoteAuthor;
  content?: string;
  readonly created?: string;
  done?: boolean | null;
  lease_termination?: PatchedNoteLeaseTermination;
  listing?: PatchedNoteListing;
  readonly modified?: string;
  readonly pk?: number;
  reminder?: string | null;
  ticket?: PatchedNoteTicket;
  user?: PatchedNoteUser;
}

export interface PatchedNestedProfilePhoto {
  readonly height?: number;
  photo?: string;
  readonly url?: string;
  readonly url_thumb_m?: string;
  readonly width?: number;
}

export type PatchedMyFlatWelcomeMessages = { [key: string]: unknown } | null;

export type PatchedMyFlatPriceUnit = PriceUnit | null;

export type PatchedMyFlatPriceDisplayType = PriceDisplayType | null;

export type PatchedMyFlatObjectType = ObjectType | null;

export type PatchedMyFlatObjectCategory = ObjectCategory | null;

export type PatchedMyFlatMovingDateType = MovingDateType | null;

export type PatchedMyFlatLanguage = Languages | null;

export type PatchedMyFlatFormRemarks = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMyFlatCoverImage = ListingImage | null;

export type PatchedMyFlatBranch = number | Branch | null;

export type PatchedMyFlatAdvertiserType = AdvertiserType | null;

/**
 * The base serializer to the my flat endpoints. Defines a few methods for
special (read-only) fields that can be used by the concrete serializers
later on.
 */
export interface PatchedMyFlat {
  advertiser_company?: string | null;
  advertiser_phone?: string | null;
  advertiser_set?: Advertiser[];
  advertiser_type?: PatchedMyFlatAdvertiserType;
  agency_city?: string | null;
  agency_contact_mode?: AgencyContactMode;
  agency_country?: string | null;
  agency_email?: string | null;
  agency_id?: string | null;
  agency_name?: string | null;
  agency_name_2?: string | null;
  agency_phone?: string | null;
  agency_reference?: string | null;
  agency_street?: string | null;
  agency_zipcode?: number | null;
  alternative_reference?: string | null;
  attributes?: ListingAttribute[];
  auto_advertiser_email?: string | null;
  auto_advertiser_name?: string | null;
  branch?: PatchedMyFlatBranch;
  /** kg */
  carrying_capacity_elevator?: string | null;
  /** m */
  ceiling_height?: string | null;
  city?: string;
  concealed?: boolean;
  country?: string;
  cover_image?: PatchedMyFlatCoverImage;
  readonly created?: string;
  description?: string;
  description_title?: string | null;
  documents?: FlatDocuments[];
  exporters?: number[];
  floor?: number | null;
  form_remarks?: PatchedMyFlatFormRemarks;
  images?: ListingImage[];
  is_furnished?: boolean;
  readonly is_imported?: boolean;
  readonly is_premium?: boolean;
  readonly is_publishable?: boolean;
  is_selling_furniture?: boolean;
  is_temporary?: boolean;
  readonly labels?: LabelProxy[];
  language?: PatchedMyFlatLanguage;
  readonly latitude?: number;
  liable_until?: string | null;
  live_viewing_url?: string | null;
  readonly longitude?: number;
  /** kg/m2 */
  maximal_floor_loading?: string | null;
  moving_date?: string | null;
  moving_date_type?: PatchedMyFlatMovingDateType;
  notice_per?: string | null;
  notify_on_contact_request?: boolean;
  readonly num_applications?: number;
  number_of_rooms?: string | null;
  readonly object_category?: PatchedMyFlatObjectCategory;
  object_type?: PatchedMyFlatObjectType;
  offer_type?: OfferType;
  readonly pk?: number;
  readonly preview_url?: string;
  readonly price_display?: number | null;
  readonly price_display_type?: PatchedMyFlatPriceDisplayType;
  price_unit?: PatchedMyFlatPriceUnit;
  readonly proprietor_city?: string;
  readonly proprietor_first_name?: string;
  readonly proprietor_last_name?: string;
  readonly proprietor_street?: string;
  readonly proprietor_zipcode?: string;
  readonly public_address?: string;
  readonly public_url?: string;
  purchase_token?: string;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  rent_charges?: number | null;
  rent_gross?: number | null;
  rent_net?: number | null;
  reserved?: boolean;
  selling_price?: number | null;
  readonly short_title?: string;
  show_exact_address?: boolean;
  readonly slug?: string | null;
  readonly source?: string;
  readonly space_display?: number | null;
  state?: string | null;
  status?: ListingStatus;
  street?: string | null;
  surface_living?: number | null;
  surface_property?: number | null;
  surface_usable?: number | null;
  surface_usable_minimum?: number | null;
  tour_url?: string | null;
  video_url?: string | null;
  viewing_contact_name?: string | null;
  volume?: number | null;
  website_url?: string | null;
  welcome_message_attachments?: FlatDocuments[];
  welcome_messages?: PatchedMyFlatWelcomeMessages;
  year_built?: number | null;
  year_renovated?: number | null;
  zipcode?: number;
}

export type PatchedMessageConfigWorkOrderAdditionalNotes = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigWelcomeMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigTicketSubmittedMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigTicketForwardedMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigSignContractMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigSelection = NestedSelection | null;

export type PatchedMessageConfigSecurityDepositRequestMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigRejectQuoteMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigRejectApplicantMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigChooseApplicantMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedMessageConfigApplicationFormSubmittedMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export interface PatchedMessageConfig {
  application_form_submitted_message?: PatchedMessageConfigApplicationFormSubmittedMessage;
  choose_applicant_message?: PatchedMessageConfigChooseApplicantMessage;
  readonly is_flatfox_default?: boolean;
  name?: string;
  ordering?: number;
  readonly pk?: number;
  reject_applicant_message?: PatchedMessageConfigRejectApplicantMessage;
  reject_quote_message?: PatchedMessageConfigRejectQuoteMessage;
  security_deposit_request_message?: PatchedMessageConfigSecurityDepositRequestMessage;
  selection?: PatchedMessageConfigSelection;
  sign_contract_message?: PatchedMessageConfigSignContractMessage;
  ticket_forwarded_message?: PatchedMessageConfigTicketForwardedMessage;
  ticket_submitted_message?: PatchedMessageConfigTicketSubmittedMessage;
  welcome_message?: PatchedMessageConfigWelcomeMessage;
  work_order_additional_notes?: PatchedMessageConfigWorkOrderAdditionalNotes;
}

export type PatchedListingLikeReactionSummariesItem = { [key: string]: unknown };

export type PatchedListingLikeOwnReaction = FlatLikeReaction | null;

export type PatchedListingLikeListing = number | ListingContext;

/**
 * Serializer for ListingLike(s).
 */
export interface PatchedListingLike {
  listing?: PatchedListingLikeListing;
  readonly own_reaction?: PatchedListingLikeOwnReaction;
  readonly pk?: number;
  readonly reaction_summaries?: PatchedListingLikeReactionSummariesItem[];
  readonly shareable?: number;
  status?: LikeStatus;
}

export interface PatchedLegacyGCMDevice {
  /** Inactive devices will not be sent notifications */
  active?: boolean;
  /** Opaque application identity, should be filled in for multiple key/certificate access */
  application_id?: string | null;
  /** You should choose FCM, GCM is deprecated

* `FCM` - Firebase Cloud Message
* `GCM` - Google Cloud Message */
  cloud_message_type?: CloudMessageTypeEnum;
  readonly date_created?: string | null;
  /** ANDROID_ID / TelephonyManager.getDeviceId() (e.g: 0x01) */
  device_id?: number | null;
  /** @deprecated */
  enabled?: boolean;
  readonly id?: number;
  name?: string | null;
  registration_id?: string;
}

export interface PatchedLegacyAPNSDevice {
  /** Inactive devices will not be sent notifications */
  active?: boolean;
  /** Opaque application identity, should be filled in for multiple key/certificate access */
  application_id?: string | null;
  readonly date_created?: string | null;
  /** UUID / UIDevice.identifierForVendor() */
  device_id?: string | null;
  /** @deprecated */
  enabled?: boolean;
  readonly id?: number;
  name?: string | null;
  registration_id?: string;
}

export type PatchedLeaseTerminationDetailViewingsItem = number | PublicViewing;

export type PatchedLeaseTerminationDetailTerminatedObjectsItem =
  | number
  | PublicTerminatedObject;

export type PatchedLeaseTerminationDetailSignersItem = number | PublicSigner;

export type PatchedLeaseTerminationDetailNextTenantsItem = number | ContactInformation;

/**
 * Detail serializer for Lease Termination model
 */
export interface PatchedLeaseTerminationDetail {
  readonly created?: string;
  follower_set?: LeaseTerminationFollower[];
  readonly message_to_landlord?: string | null;
  readonly modified?: string;
  readonly next_tenants?: PatchedLeaseTerminationDetailNextTenantsItem[];
  readonly number?: number;
  readonly pk?: number;
  readonly requester?: ContactInformation;
  readonly signers?: PatchedLeaseTerminationDetailSignersItem[];
  status?: LeaseTerminationStatus;
  readonly terminated_objects?: PatchedLeaseTerminationDetailTerminatedObjectsItem[];
  readonly termination_reason?: TerminationReasonEnum;
  readonly viewing_contact_public?: boolean;
  readonly viewing_handler?: ContactInformation;
  readonly viewings?: PatchedLeaseTerminationDetailViewingsItem[];
}

/**
 * The delivery status of the last reference email, if available.
 */
export type PatchedLandlordReferenceExternalReferenceDeliveryStatus =
  ExternalReferenceDeliveryStatusEnum | null;

export type PatchedLandlordReferenceExternalReference = { [key: string]: unknown };

export interface PatchedLandlordReference {
  readonly checklist?: number;
  comment?: string | null;
  complaints_from_other_tenants?: string | null;
  readonly contact_email?: string | null;
  readonly contact_name?: string | null;
  readonly contact_phone_number?: string | null;
  readonly created?: string;
  readonly external_reference?: PatchedLandlordReferenceExternalReference;
  /** The delivery status of the last reference email, if available. */
  readonly external_reference_delivery_status?: PatchedLandlordReferenceExternalReferenceDeliveryStatus;
  readonly external_reference_email?: string | null;
  readonly external_reference_message?: string | null;
  readonly external_reference_received?: string | null;
  readonly external_reference_referer?: string | null;
  readonly external_reference_sent?: string | null;
  readonly landlord?: string | null;
  paid_in_time?: boolean | null;
  readonly person_city?: string | null;
  readonly person_first_name?: string;
  readonly person_id?: number | null;
  readonly person_last_name?: string;
  readonly person_street?: string | null;
  readonly person_zipcode?: string | null;
  readonly pk?: number;
  rent?: string | null;
  readonly reviewer?: NestedUser;
  since?: string | null;
  readonly updated?: string | null;
  verdict?: boolean | null;
  would_let_rent_again?: boolean | null;
}

export interface PatchedIncome {
  readonly checklist?: number;
  comment?: string | null;
  readonly created?: string;
  readonly flat_rent?: number | null;
  job_gross_salary_end?: number | null;
  job_gross_salary_start?: number | null;
  job_gross_salary_verdict?: number | null;
  readonly pk?: number;
  readonly reviewer?: NestedUser;
  readonly updated?: string | null;
  verdict?: boolean | null;
}

export type PatchedFormConfigSelection = NestedSelection | null;

export type PatchedFormConfigPersonFields = { [key: string]: unknown };

export type PatchedFormConfigFlyerRemarks = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type PatchedFormConfigDossierFields = { [key: string]: unknown };

export type PatchedFormConfigConfirmGenericItem = { [key: string]: unknown };

export interface PatchedFormConfig {
  allow_direct_apply?: boolean;
  confirm_generic?: PatchedFormConfigConfirmGenericItem[];
  confirm_reference?: boolean;
  confirm_visited?: boolean;
  dossier_fields?: PatchedFormConfigDossierFields;
  flyer_remarks?: PatchedFormConfigFlyerRemarks;
  readonly is_flatfox_default?: boolean;
  name?: string;
  ordering?: number;
  person_fields?: PatchedFormConfigPersonFields;
  readonly pk?: number;
  proof_of_creditworthiness_type?: ProofOfCreditworthinessType;
  selection?: PatchedFormConfigSelection;
}

/**
 * Model for a service that exports a listing to a third party service
 */
export interface PatchedExporterService {
  billing_address?: string | null;
  billing_email?: string | null;
  billing_remarks?: string | null;
  readonly can_stop?: boolean;
  readonly created?: string;
  end_date?: string | null;
  exporter?: number;
  readonly formatted?: ExporterServiceFormatted;
  listing?: number;
  readonly owner?: number;
  readonly pk?: number;
  readonly purchaser?: number;
  service_type?: ExporterServiceType;
  start_date?: string;
  readonly status?: ExporterServiceStatusEnum;
}

export type PatchedExporterServiceCatalogItemServiceType =
  typeof PatchedExporterServiceCatalogItemServiceType[keyof typeof PatchedExporterServiceCatalogItemServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchedExporterServiceCatalogItemServiceType = {
  newhome_top_promotion_15: 'newhome_top_promotion_15',
  newhome_top_promotion_30: 'newhome_top_promotion_30',
  newhome_top_promotion_60: 'newhome_top_promotion_60',
  newhome_special_promotion_15: 'newhome_special_promotion_15',
  newhome_special_promotion_30: 'newhome_special_promotion_30',
  newhome_special_promotion_60: 'newhome_special_promotion_60',
  homegate_top_listing: 'homegate_top_listing',
  homegate_premium_listing: 'homegate_premium_listing',
  immoscout_top_listing: 'immoscout_top_listing',
} as const;

export type PatchedExporterServiceCatalogItem = {
  description: string;
  invoice_type: string;
  label: string;
  portal_type: string;
  service_type: PatchedExporterServiceCatalogItemServiceType;
};

export type PatchedExporterSelection = NestedSelection | null;

export interface PatchedExporter {
  agency_id?: string;
  auto?: boolean;
  ftp_host?: string | null;
  ftp_password?: string | null;
  ftp_port?: number | null;
  ftp_username?: string | null;
  is_active?: boolean;
  is_private?: boolean;
  last_export?: string | null;
  readonly last_log?: number | null;
  readonly pk?: number;
  readonly portal_type?: string;
  selection?: PatchedExporterSelection;
  readonly service_catalog?: PatchedExporterServiceCatalogItem[];
  title?: string;
  type?: TransferAccountType;
}

export interface PatchedEventSubscription {
  auth_password?: string | null;
  auth_username?: string | null;
  description?: string;
  enabled_events?: EventType[];
  readonly owner?: number | null;
  readonly pk?: number;
  url?: string;
}

/**
 * The delivery status of the last reference email, if available.
 */
export type PatchedEmployerReferenceExternalReferenceDeliveryStatus =
  ExternalReferenceDeliveryStatusEnum | null;

export type PatchedEmployerReferenceExternalReference = { [key: string]: unknown } | null;

export interface PatchedEmployerReference {
  readonly checklist?: number;
  comment?: string | null;
  readonly contact_email?: string | null;
  readonly contact_name?: string | null;
  readonly contact_phone_number?: string | null;
  readonly created?: string;
  readonly employer?: string | null;
  readonly external_reference?: PatchedEmployerReferenceExternalReference;
  /** The delivery status of the last reference email, if available. */
  readonly external_reference_delivery_status?: PatchedEmployerReferenceExternalReferenceDeliveryStatus;
  readonly external_reference_email?: string | null;
  readonly external_reference_message?: string | null;
  readonly external_reference_received?: string | null;
  readonly external_reference_referer?: string | null;
  readonly external_reference_sent?: string | null;
  is_permanent?: boolean | null;
  job_gross_salary_end?: number | null;
  job_gross_salary_start?: number | null;
  job_status?: string | null;
  job_title?: string | null;
  no_planned_change?: boolean | null;
  readonly person_city?: string | null;
  readonly person_first_name?: string;
  readonly person_id?: number | null;
  readonly person_last_name?: string;
  readonly person_street?: string | null;
  readonly person_zipcode?: string | null;
  readonly pk?: number;
  readonly reviewer?: NestedUser;
  salary_correct?: boolean | null;
  since?: string | null;
  readonly updated?: string | null;
  verdict?: boolean | null;
}

export type PatchedDossierDepositType = DepositType | null;

export type PatchedDossierCompanyLegalForm = BusinessLegalForm | null;

/**
 * This serializer is used to persist a dossier and store it as json on
an application. Never use this serializer for an enpoint.
 */
export interface PatchedDossier {
  bell_plate_label?: string | null;
  carpooling_interest?: boolean | null;
  children_ages?: string | null;
  co_working_space_interest?: boolean | null;
  company_annual_turnover?: string | null;
  company_correspondence_address?: string | null;
  company_debt_extract?: DossierDocument[];
  company_founding_year?: string | null;
  company_has_private_liability_insurance?: boolean | null;
  company_has_vat?: boolean | null;
  company_headquarters?: string | null;
  company_industry?: string | null;
  company_is_registered?: boolean | null;
  company_legal_form?: PatchedDossierCompanyLegalForm;
  company_name?: string | null;
  company_private_liability_insurance_amount?: string | null;
  company_purpose?: string | null;
  company_register_extract?: DossierDocument[];
  company_uid_number?: string | null;
  company_website?: string | null;
  cooperative_shares?: string | null;
  deposit_type?: PatchedDossierDepositType;
  documents?: DossierDocument[];
  ev_charging_station_interest?: boolean | null;
  has_musical_instruments?: boolean | null;
  has_parking_lot_interest?: boolean | null;
  has_pets?: boolean | null;
  has_secondary_interest?: boolean | null;
  has_subtenants?: boolean | null;
  household_insurance?: DossierDocument[];
  iban_account?: string | null;
  iban_account_holder?: string | null;
  liability_insurance?: DossierDocument[];
  musical_instruments?: string | null;
  number_adults?: number | null;
  number_children?: number | null;
  persons?: Person[];
  pets?: string | null;
  readonly pk?: number;
  reason_remarks?: string | null;
  remarks?: string | null;
  requested_moving_date?: string | null;
  seal_tv_connection?: boolean | null;
  secondary_details?: string | null;
  smoker?: boolean | null;
  terminated_by_landlord_reason?: string | null;
  use_as_family?: boolean | null;
  vehicle_plates?: string | null;
  vehicles_and_plates?: string | null;
  was_terminated_by_landlord?: boolean | null;
}

export type PatchedDateProposalSenderRole = Role | null;

export type PatchedDateProposalInviteeRole = Role | null;

/**
 * Model for a date proposal of a separate appointment
 */
export interface PatchedDateProposal {
  description?: string | null;
  readonly invitee_role?: PatchedDateProposalInviteeRole;
  location?: string | null;
  readonly pk?: number;
  readonly sender_role?: PatchedDateProposalSenderRole;
  status?: DateProposalStatus;
  thread?: number;
  title?: string | null;
  when?: string;
}

export interface PatchedCrmContact {
  city?: string | null;
  date_of_birth?: string | null;
  readonly emails?: string[];
  first_name?: string | null;
  last_name?: string | null;
  readonly matching?: boolean;
  phone_number?: string | null;
  readonly pk?: number;
  readonly search_profiles?: SearchProfile[];
  street?: string | null;
  zipcode?: string | null;
}

export interface PatchedCreditRisk {
  readonly checklist?: number;
  comment?: string | null;
  readonly created?: string;
  readonly person_first_name?: string;
  readonly person_last_name?: string;
  readonly pk?: number;
  readonly rating?: string | null;
  readonly reviewer?: NestedUser;
  readonly score?: number | null;
  readonly updated?: string | null;
  verdict?: boolean | null;
}

export type PatchedConfigUserProfilePhoto = number | NestedProfilePhoto;

/**
 * This serializer will be used for the auth/user/ endpoint and we'll
embedd it into window.flatfoxConfig.user for all page views.

NB: We need the session in context for resolving current_org.
 */
export interface PatchedConfigUserProfile {
  readonly account_profile_url?: string;
  readonly capabilities?: string[];
  readonly email?: string;
  readonly features?: string[];
  first_name?: string;
  full_name?: string;
  gtc_version?: string | null;
  lang?: string | null;
  last_name?: string;
  readonly organization_pk?: number;
  readonly organization_role?: string | null;
  readonly owner_pk?: number;
  phone_number?: string | null;
  readonly photo?: PatchedConfigUserProfilePhoto;
  pk?: number;
  readonly pricing?: string;
  readonly sha256_email?: string;
  readonly sha256_phone_number?: string | null;
  signature_mobile_phone_number?: string | null;
}

export interface PatchedChecklist {
  readonly application_context?: number | null;
  application_form_submitted?: string | null;
  comment?: string | null;
  readonly created?: string;
  readonly finished?: boolean | null;
  readonly owner?: number;
  readonly pk?: number;
  readonly reviewer?: NestedUser;
  readonly updated?: string | null;
}

export type PatchedApplicationUser = number | NestedUser;

export type PatchedApplicationMetadata = { [key: string]: unknown };

export type PatchedApplicationFormData = { [key: string]: unknown } | null;

export type PatchedApplicationFlat = number | NestedListing;

export type PatchedApplicationContractData = { [key: string]: unknown } | null;

export type PatchedApplicationChecklistStatus = ChecklistStatus | null;

/**
 * Application serializer. Adds the user's role (advertiser or applicant).
 */
export interface PatchedApplication {
  readonly checklist_status?: PatchedApplicationChecklistStatus;
  readonly contract_data?: PatchedApplicationContractData;
  create_subscription?: boolean;
  readonly created?: string;
  email?: string;
  first_name?: string;
  flat?: PatchedApplicationFlat;
  readonly form_data?: PatchedApplicationFormData;
  readonly form_pdf_url?: string;
  readonly form_submitted?: string | null;
  readonly has_form_data?: boolean;
  is_favorite?: boolean;
  last_name?: string;
  metadata?: PatchedApplicationMetadata;
  phone_number?: string;
  readonly pk?: number;
  readonly purged?: string | null;
  status?: string;
  readonly summary_pdf_url?: string;
  readonly summary_zip_url?: string;
  text?: string;
  readonly user?: PatchedApplicationUser;
}

export interface PaginatedUnreadStateList {
  count: number;
  next: string | null;
  previous: string | null;
  results: UnreadState[];
}

export interface PaginatedTicketList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Ticket[];
}

export interface PaginatedThreadList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Thread[];
}

export interface PaginatedTenancyList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Tenancy[];
}

export interface PaginatedShareableList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Shareable[];
}

export interface PaginatedSelectionList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Selection[];
}

export interface PaginatedSecurityDepositRequestList {
  count: number;
  next: string | null;
  previous: string | null;
  results: SecurityDepositRequest[];
}

export interface PaginatedScheduleList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Schedule[];
}

export interface PaginatedScheduleEventList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ScheduleEvent[];
}

export interface PaginatedPurchaseList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Purchase[];
}

export interface PaginatedPublicListingList {
  count: number;
  next: string | null;
  previous: string | null;
  results: PublicListing[];
}

export interface PaginatedPropertyList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Property[];
}

export interface PaginatedPortalStatsList {
  count: number;
  next: string | null;
  previous: string | null;
  results: PortalStats[];
}

export interface PaginatedPersonStatsList {
  count: number;
  next: string | null;
  previous: string | null;
  results: PersonStats[];
}

export interface PaginatedNoteList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Note[];
}

export interface PaginatedNestedUserList {
  count: number;
  next: string | null;
  previous: string | null;
  results: NestedUser[];
}

export interface PaginatedMyFlatList {
  count: number;
  next: string | null;
  previous: string | null;
  results: MyFlat[];
}

export interface PaginatedMessageList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Message[];
}

export interface PaginatedMembershipList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Membership[];
}

export interface PaginatedListingStatsList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ListingStats[];
}

export interface PaginatedListingLikeList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ListingLike[];
}

export interface PaginatedLeaseTerminationListList {
  count: number;
  next: string | null;
  previous: string | null;
  results: LeaseTerminationList[];
}

export interface PaginatedLabelProxyList {
  count: number;
  next: string | null;
  previous: string | null;
  results: LabelProxy[];
}

export interface PaginatedInventoryObjectList {
  count: number;
  next: string | null;
  previous: string | null;
  results: InventoryObject[];
}

export interface PaginatedIdxListingList {
  count: number;
  next: string | null;
  previous: string | null;
  results: IdxListing[];
}

export interface PaginatedHouseList {
  count: number;
  next: string | null;
  previous: string | null;
  results: House[];
}

export interface PaginatedFlatLikeReactionList {
  count: number;
  next: string | null;
  previous: string | null;
  results: FlatLikeReaction[];
}

export interface PaginatedFeedItemList {
  count: number;
  next: string | null;
  previous: string | null;
  results: FeedItem[];
}

export interface PaginatedExtendedRoleList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ExtendedRole[];
}

export interface PaginatedEventSubscriptionList {
  count: number;
  next: string | null;
  previous: string | null;
  results: EventSubscription[];
}

export interface PaginatedEventList {
  next: string | null;
  previous: string | null;
  results: Event[];
}

export interface PaginatedDirectSubmitListingList {
  count: number;
  next: string | null;
  previous: string | null;
  results: DirectSubmitListing[];
}

export interface PaginatedCrmMatchList {
  count: number;
  next: string | null;
  previous: string | null;
  results: CrmMatch[];
}

export interface PaginatedCrmContactList {
  count: number;
  next: string | null;
  previous: string | null;
  results: CrmContact[];
}

export interface PaginatedCostingStatsList {
  count: number;
  next: string | null;
  previous: string | null;
  results: CostingStats[];
}

export interface PaginatedContactRequestStatsList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ContactRequestStats[];
}

export interface PaginatedContactList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Contact[];
}

export interface PaginatedAttendanceList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Attendance[];
}

export interface PaginatedApplicationStatsList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ApplicationStats[];
}

/**
 * * `other` - OTHER
 */
export type OtherDepositDepositTypeEnum =
  typeof OtherDepositDepositTypeEnum[keyof typeof OtherDepositDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OtherDepositDepositTypeEnum = {
  other: 'other',
} as const;

export interface OtherDeposit {
  attachment?: UploadField;
  readonly attachment_url: string | null;
  readonly deposit_type: OtherDepositDepositTypeEnum;
  message?: string | null;
  readonly pk: number;
}

/**
 * * `admin` - Administrator
 * `staff` - Staff
 * `external_staff` - External staff
 */
export type OrganizationRole = typeof OrganizationRole[keyof typeof OrganizationRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationRole = {
  admin: 'admin',
  staff: 'staff',
  external_staff: 'external_staff',
} as const;

export interface Organization {
  readonly city: string;
  readonly country: string;
  readonly name: string;
  readonly name_2: string;
  readonly street: string;
  readonly url_org_logo_m: string | null;
  readonly zipcode: string;
}

/**
 * * `created` - created
 * `sent` - sent
 * `rejected` - rejected
 * `delivered` - delivered
 */
export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderStatus = {
  created: 'created',
  sent: 'sent',
  rejected: 'rejected',
  delivered: 'delivered',
} as const;

/**
 * * `RENT` - For rent
 * `SALE` - For sale
 */
export type OfferType = typeof OfferType[keyof typeof OfferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferType = {
  RENT: 'RENT',
  SALE: 'SALE',
} as const;

/**
 * * `AGRICULTURAL_INSTALLATION` - agricultural installation
 * `MOUNTAIN_FARM` - mountain farm
 * `FARM` - farm
 * `APARTMENT` - apartment
 * `DUPLEX` - duplex
 * `ATTIC_FLAT` - attic flat
 * `ROOF_FLAT` - roof flat
 * `STUDIO` - studio
 * `SINGLE_ROOM` - single room
 * `FURNISHED_FLAT` - furnished flat
 * `TERRACE_FLAT` - terrace flat
 * `BACHELOR_FLAT` - bachelor flat
 * `LOFT` - loft
 * `ATTIC` - mansard
 * `ALOTTMENT_GARDEN` - Alottment garden
 * `HOTEL` - hotel
 * `RESTAURANT` - restaurant
 * `COFFEEHOUSE` - coffeehouse
 * `BAR` - bar
 * `CLUB` - club / disco
 * `CASINO` - casino
 * `MOVIE_THEATER` - movie / theater
 * `SQUASH_BADMINTON` - squash / badminton
 * `INDOOR_TENNIS_COURTS` - indoor tennis courts
 * `TENNIS_COURT` - tennis court
 * `SPORTS_HALL` - sports hall
 * `CAMPGROUND` - campground / tent camping
 * `OUTDOOR_SWIMMING_POOL` - outdoor swimming pool
 * `INDOOR_SWIMMINGPOOL` - indoor swimmingpool
 * `GOLF_COURSE` - golf course
 * `MOTEL` - motel
 * `PUB` - pub
 * `SINGLE_HOUSE` - single house
 * `ROW_HOUSE` - row house
 * `DUPLEX_HOUSE` - duplex house
 * `TERRACE_HOUSE` - terrace house
 * `VILLA` - villa
 * `FARM_HOUSE` - farm house
 * `MULTI_FAMILY_HOUSE` - multi family house
 * `CAVE_EARTHEN_HOUSE` - cave house / earthen house
 * `CASTLE` - castle
 * `GRANNY_FLAT` - granny flat
 * `CHALET` - chalet
 * `RUSTIC_HOUSE` - rustic house
 * `OFFICE` - office
 * `SHOP` - shop
 * `ADVERTISING_AREA` - advertising area
 * `COMMERCIAL` - commercial
 * `STORAGE_ROOM` - storage room
 * `PRACTICE` - practice
 * `KIOSK` - kiosk
 * `GARDENING` - gardening
 * `FUEL_STATION` - fuel station
 * `GARAGE` - garage
 * `CHEESE_FACTORY` - cheese factory
 * `BUTCHER` - butcher
 * `BAKERY` - bakery
 * `HAIRDRESSER` - hairdresser
 * `SHOPPING_CENTRE` - shopping centre
 * `FACTORY` - factory
 * `INDUSTRIAL_OBJECT` - industrial object
 * `ARCADE` - arcade
 * `ATELIER` - atelier
 * `LIVING_COMMERCIAL_BUILDING` - living / commercial building
 * `LIBRARY` - library
 * `HOSPITAL` - hospital
 * `LABORATORY` - laboratory
 * `MINI_GOLF_COURSE` - mini-golf course
 * `NURSING_HOME` - nursing home
 * `RIDING_HALL` - riding hall
 * `SANATORIUM` - sanatorium
 * `WORKSHOP` - workshop
 * `PARTY_ROOM` - party room
 * `SAUNA` - sauna
 * `SOLARIUM` - solarium
 * `CARPENTRY_SHOP` - carpentry shop
 * `OLD_AGE_HOME` - retirement home
 * `DEPARTMENT_STORE` - department store
 * `ASYLUM` - asylum
 * `DISPLAY_WINDOW` - display window
 * `PARKING_GARAGE` - parking garage
 * `PARKING_SURFACE` - parking surface
 * `OPEN_SLOT` - open slot
 * `COVERED_SLOT` - covered slot
 * `SINGLE_GARAGE` - single garage
 * `DOUBLE_GARAGE` - double garage
 * `GARAGE_SLOT` - underground slot
 * `BOAT_DRY_DOCK` - boat dry dock
 * `BOAT_LANDING_STAGE` - boat landing stage
 * `COVERED_PARKING_PLACE_BIKE` - covered bike parking
 * `OUTDOOR_PARKING_PLACE_BIKE` - outdoor bike parking
 * `HORSE_BOX` - horse box
 * `BOAT_MOORING` - boat mooring
 * `BUILDING_LAND` - building land
 * `AGRICULTURAL_LAND` - agricultural land
 * `COMMERCIAL_LAND` - commercial land
 * `INDUSTRIAL_LAND` - industrial land
 * `HOBBY_ROOM` - hobby room
 * `CELLAR_COMPARTMENT` - cellar compartment
 * `ATTIC_COMPARTMENT` - attic compartment
 * `SHARED_FLAT` - room in a shared flat
 */
export type ObjectType = typeof ObjectType[keyof typeof ObjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObjectType = {
  AGRICULTURAL_INSTALLATION: 'AGRICULTURAL_INSTALLATION',
  MOUNTAIN_FARM: 'MOUNTAIN_FARM',
  FARM: 'FARM',
  APARTMENT: 'APARTMENT',
  DUPLEX: 'DUPLEX',
  ATTIC_FLAT: 'ATTIC_FLAT',
  ROOF_FLAT: 'ROOF_FLAT',
  STUDIO: 'STUDIO',
  SINGLE_ROOM: 'SINGLE_ROOM',
  FURNISHED_FLAT: 'FURNISHED_FLAT',
  TERRACE_FLAT: 'TERRACE_FLAT',
  BACHELOR_FLAT: 'BACHELOR_FLAT',
  LOFT: 'LOFT',
  ATTIC: 'ATTIC',
  ALOTTMENT_GARDEN: 'ALOTTMENT_GARDEN',
  HOTEL: 'HOTEL',
  RESTAURANT: 'RESTAURANT',
  COFFEEHOUSE: 'COFFEEHOUSE',
  BAR: 'BAR',
  CLUB: 'CLUB',
  CASINO: 'CASINO',
  MOVIE_THEATER: 'MOVIE_THEATER',
  SQUASH_BADMINTON: 'SQUASH_BADMINTON',
  INDOOR_TENNIS_COURTS: 'INDOOR_TENNIS_COURTS',
  TENNIS_COURT: 'TENNIS_COURT',
  SPORTS_HALL: 'SPORTS_HALL',
  CAMPGROUND: 'CAMPGROUND',
  OUTDOOR_SWIMMING_POOL: 'OUTDOOR_SWIMMING_POOL',
  INDOOR_SWIMMINGPOOL: 'INDOOR_SWIMMINGPOOL',
  GOLF_COURSE: 'GOLF_COURSE',
  MOTEL: 'MOTEL',
  PUB: 'PUB',
  SINGLE_HOUSE: 'SINGLE_HOUSE',
  ROW_HOUSE: 'ROW_HOUSE',
  DUPLEX_HOUSE: 'DUPLEX_HOUSE',
  TERRACE_HOUSE: 'TERRACE_HOUSE',
  VILLA: 'VILLA',
  FARM_HOUSE: 'FARM_HOUSE',
  MULTI_FAMILY_HOUSE: 'MULTI_FAMILY_HOUSE',
  CAVE_EARTHEN_HOUSE: 'CAVE_EARTHEN_HOUSE',
  CASTLE: 'CASTLE',
  GRANNY_FLAT: 'GRANNY_FLAT',
  CHALET: 'CHALET',
  RUSTIC_HOUSE: 'RUSTIC_HOUSE',
  OFFICE: 'OFFICE',
  SHOP: 'SHOP',
  ADVERTISING_AREA: 'ADVERTISING_AREA',
  COMMERCIAL: 'COMMERCIAL',
  STORAGE_ROOM: 'STORAGE_ROOM',
  PRACTICE: 'PRACTICE',
  KIOSK: 'KIOSK',
  GARDENING: 'GARDENING',
  FUEL_STATION: 'FUEL_STATION',
  GARAGE: 'GARAGE',
  CHEESE_FACTORY: 'CHEESE_FACTORY',
  BUTCHER: 'BUTCHER',
  BAKERY: 'BAKERY',
  HAIRDRESSER: 'HAIRDRESSER',
  SHOPPING_CENTRE: 'SHOPPING_CENTRE',
  FACTORY: 'FACTORY',
  INDUSTRIAL_OBJECT: 'INDUSTRIAL_OBJECT',
  ARCADE: 'ARCADE',
  ATELIER: 'ATELIER',
  LIVING_COMMERCIAL_BUILDING: 'LIVING_COMMERCIAL_BUILDING',
  LIBRARY: 'LIBRARY',
  HOSPITAL: 'HOSPITAL',
  LABORATORY: 'LABORATORY',
  MINI_GOLF_COURSE: 'MINI_GOLF_COURSE',
  NURSING_HOME: 'NURSING_HOME',
  RIDING_HALL: 'RIDING_HALL',
  SANATORIUM: 'SANATORIUM',
  WORKSHOP: 'WORKSHOP',
  PARTY_ROOM: 'PARTY_ROOM',
  SAUNA: 'SAUNA',
  SOLARIUM: 'SOLARIUM',
  CARPENTRY_SHOP: 'CARPENTRY_SHOP',
  OLD_AGE_HOME: 'OLD_AGE_HOME',
  DEPARTMENT_STORE: 'DEPARTMENT_STORE',
  ASYLUM: 'ASYLUM',
  DISPLAY_WINDOW: 'DISPLAY_WINDOW',
  PARKING_GARAGE: 'PARKING_GARAGE',
  PARKING_SURFACE: 'PARKING_SURFACE',
  OPEN_SLOT: 'OPEN_SLOT',
  COVERED_SLOT: 'COVERED_SLOT',
  SINGLE_GARAGE: 'SINGLE_GARAGE',
  DOUBLE_GARAGE: 'DOUBLE_GARAGE',
  GARAGE_SLOT: 'GARAGE_SLOT',
  BOAT_DRY_DOCK: 'BOAT_DRY_DOCK',
  BOAT_LANDING_STAGE: 'BOAT_LANDING_STAGE',
  COVERED_PARKING_PLACE_BIKE: 'COVERED_PARKING_PLACE_BIKE',
  OUTDOOR_PARKING_PLACE_BIKE: 'OUTDOOR_PARKING_PLACE_BIKE',
  HORSE_BOX: 'HORSE_BOX',
  BOAT_MOORING: 'BOAT_MOORING',
  BUILDING_LAND: 'BUILDING_LAND',
  AGRICULTURAL_LAND: 'AGRICULTURAL_LAND',
  COMMERCIAL_LAND: 'COMMERCIAL_LAND',
  INDUSTRIAL_LAND: 'INDUSTRIAL_LAND',
  HOBBY_ROOM: 'HOBBY_ROOM',
  CELLAR_COMPARTMENT: 'CELLAR_COMPARTMENT',
  ATTIC_COMPARTMENT: 'ATTIC_COMPARTMENT',
  SHARED_FLAT: 'SHARED_FLAT',
} as const;

/**
 * * `AGRICULTURE` - Agriculture
 * `APARTMENT` - Apartment
 * `GARDEN` - Garden
 * `GASTRO` - Gastronomy
 * `HOUSE` - House
 * `INDUSTRY` - Industry
 * `PARK` - Parking space
 * `PROPERTY` - Plot
 * `SECONDARY` - Storage / Hobby
 * `SHARED` - Room in a shared flat
 */
export type ObjectCategory = typeof ObjectCategory[keyof typeof ObjectCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObjectCategory = {
  AGRICULTURE: 'AGRICULTURE',
  APARTMENT: 'APARTMENT',
  GARDEN: 'GARDEN',
  GASTRO: 'GASTRO',
  HOUSE: 'HOUSE',
  INDUSTRY: 'INDUSTRY',
  PARK: 'PARK',
  PROPERTY: 'PROPERTY',
  SECONDARY: 'SECONDARY',
  SHARED: 'SHARED',
} as const;

/**
 * Address with additional (sometimes mandatory) link to an inventory link.

### Street address:
`street`, `city`, `zipcode`

### Inventory direct links:
`house_id`, `property_id`, `object_id`

### Reference:
`ref_house`, `ref_property`, `ref_object`

### Create or Update validations:

* Organizations that have an inventory:
   * `house_id` is required
   * Street address and reference data are sourced from the specified
     house_id/object_id.
* Organizations with no inventory:
   * Street address is required
   * `house_id` and `object_id` cannot be set, they are invalid.
   * `ref_property`, `ref_house`, `ref_object` are optional.
 */
export interface ObjectAddress {
  city?: string;
  /** On create or update its required for organizations with inventory, otherwise its invalid. */
  house_id?: number | null;
  /** For organizations with inventory capability, it is required on create or update, if house_id is not set. Invalid otherwise. */
  object_id?: number | null;
  /** Read-only. */
  property_id?: number | null;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  street?: string;
  zipcode?: string;
}

export interface OTPLogin {
  email: string;
  otp: string;
}

export interface OTPCreate {
  email: string;
}

export type NoteUser = number | NestedUser | null;

export type NoteTicket = number | NestedTicket | null;

export type NoteListing = number | NestedListing | null;

export type NoteLeaseTermination = number | NestedLeaseTermination | null;

export interface Note {
  readonly author: NoteAuthor;
  content: string;
  readonly created: string;
  done?: boolean | null;
  lease_termination?: NoteLeaseTermination;
  listing?: NoteListing;
  readonly modified: string;
  readonly pk: number;
  reminder?: string | null;
  ticket?: NoteTicket;
  user?: NoteUser;
}

export interface NextWorkOrderNumber {
  readonly order_number: string;
}

export type NestedUserPhoto = number | NestedProfilePhoto;

/**
 * django.contrib.auth.models.User

=> This is kind of a public profile and should be safe to be viewed by
anyone. Everything private will be in the Person object.
 */
export interface NestedUser {
  readonly avatar_url: string;
  email: string;
  first_name: string;
  full_name: string;
  last_name: string;
  phone_number: string;
  readonly photo: NestedUserPhoto;
  pk: number;
}

export type NoteAuthor = number | NestedUser;

export type NestedTicketSubmitter = number | NestedUser | null;

/**
 * flatfox_ticket.models.Ticket
 */
export interface NestedTicket {
  readonly address: ObjectAddress;
  created: string;
  description: string;
  number: number;
  pk: number;
  readonly submitter: NestedTicketSubmitter;
  title: string;
}

export interface NestedTerminatedObject {
  address: ObjectAddress;
  pk: number;
}

export interface NestedSelectionRule {
  attribute: SelectionRuleAttribute;
  operator: SelectionRuleOperator;
  values: string[];
}

export interface NestedSelection {
  rule_aggregation: SelectionRuleAggregation;
  rules: NestedSelectionRule[];
}

export type NestedRoleUser = number | NestedUser;

export interface NestedRole {
  readonly pk: number;
  readonly role: PropertyRole;
  readonly user: NestedRoleUser;
}

export interface NestedProfilePhoto {
  readonly height: number;
  photo: string;
  readonly url: string;
  readonly url_thumb_m: string;
  readonly width: number;
}

export type NestedObjectHouse = number | NestedHouse;

/**
 * flatfox_inventory.models.Object
 */
export interface NestedObject {
  branch?: number;
  readonly house: NestedObjectHouse;
  object_type?: string;
  pk: number;
  property?: number;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
}

export type NestedMessageSender = number | NestedUser | null;

/**
 * flatfox_messenger.models.Message
 */
export interface NestedMessage {
  created: string;
  message_type: MessageType;
  pk: number;
  readonly sender: NestedMessageSender;
  text?: string;
}

export type NestedMembershipUserPhoto = number | NestedProfilePhoto;

/**
 * django.contrib.auth.models.User

=> This is kind of a public profile and should be safe to be viewed by
anyone. Everything private will be in the Person object.
 */
export interface NestedMembershipUser {
  readonly avatar_url: string;
  email: string;
  ephemeral: boolean;
  first_name: string;
  full_name: string;
  last_name: string;
  phone_number: string;
  readonly photo: NestedMembershipUserPhoto;
  pk: number;
  signature_mobile_phone_number: string;
}

export type NestedListingUser = number | NestedUser | null;

/**
 * flatfox.models.Flat
 */
export interface NestedListing {
  advertiser_set?: NestedAdvertiser[];
  city?: string;
  pk: number;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  readonly reference: string;
  status?: ListingStatus;
  street?: string;
  readonly user: NestedListingUser;
  zipcode?: string;
}

export type NestedLeaseTerminationTerminatedObjectsItem = number | NestedTerminatedObject;

export type NestedLeaseTerminationRequester = number | NestedUser | null;

export interface NestedLeaseTermination {
  created: string;
  number: number;
  pk: number;
  readonly requester: NestedLeaseTerminationRequester;
  terminated_objects?: NestedLeaseTerminationTerminatedObjectsItem[];
}

/**
 * flatfox_inventory.models.House
 */
export interface NestedHouse {
  city?: string;
  pk: number;
  property?: number;
  ref_house?: string;
  ref_property?: string;
  street?: string;
  zipcode?: string;
}

export type NestedAdvertiserUser = number | NestedUser;

export interface NestedAdvertiser {
  is_auto?: boolean;
  is_representative?: boolean;
  user: NestedAdvertiserUser;
}

export interface MyFlatApplyCardsPDF {
  /** base64 encoded pdf content */
  readonly pdf: string;
}

export type MyFlatWelcomeMessages = { [key: string]: unknown } | null;

export type MyFlatPriceUnit = PriceUnit | null;

export type MyFlatPriceDisplayType = PriceDisplayType | null;

export type MyFlatObjectType = ObjectType | null;

export type MyFlatObjectCategory = ObjectCategory | null;

export type MyFlatMovingDateType = MovingDateType | null;

export type MyFlatLanguage = Languages | null;

export type MyFlatFormRemarks = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MyFlatCoverImage = ListingImage | null;

export type MyFlatBranch = number | Branch | null;

export type MyFlatAdvertiserType = AdvertiserType | null;

/**
 * The base serializer to the my flat endpoints. Defines a few methods for
special (read-only) fields that can be used by the concrete serializers
later on.
 */
export interface MyFlat {
  advertiser_company?: string | null;
  advertiser_phone?: string | null;
  advertiser_set?: Advertiser[];
  advertiser_type?: MyFlatAdvertiserType;
  agency_city?: string | null;
  agency_contact_mode?: AgencyContactMode;
  agency_country?: string | null;
  agency_email?: string | null;
  agency_id?: string | null;
  agency_name?: string | null;
  agency_name_2?: string | null;
  agency_phone?: string | null;
  agency_reference?: string | null;
  agency_street?: string | null;
  agency_zipcode?: number | null;
  alternative_reference?: string | null;
  attributes?: ListingAttribute[];
  auto_advertiser_email?: string | null;
  auto_advertiser_name?: string | null;
  branch?: MyFlatBranch;
  /** kg */
  carrying_capacity_elevator?: string | null;
  /** m */
  ceiling_height?: string | null;
  city: string;
  concealed?: boolean;
  country?: string;
  cover_image?: MyFlatCoverImage;
  readonly created: string;
  description?: string;
  description_title?: string | null;
  documents?: FlatDocuments[];
  exporters?: number[];
  floor?: number | null;
  form_remarks?: MyFlatFormRemarks;
  images?: ListingImage[];
  is_furnished?: boolean;
  readonly is_imported: boolean;
  readonly is_premium: boolean;
  readonly is_publishable: boolean;
  is_selling_furniture?: boolean;
  is_temporary?: boolean;
  readonly labels: LabelProxy[];
  language?: MyFlatLanguage;
  readonly latitude: number;
  liable_until?: string | null;
  live_viewing_url?: string | null;
  readonly longitude: number;
  /** kg/m2 */
  maximal_floor_loading?: string | null;
  moving_date?: string | null;
  moving_date_type?: MyFlatMovingDateType;
  notice_per?: string | null;
  notify_on_contact_request?: boolean;
  readonly num_applications: number;
  number_of_rooms?: string | null;
  readonly object_category: MyFlatObjectCategory;
  object_type?: MyFlatObjectType;
  offer_type?: OfferType;
  readonly pk: number;
  readonly preview_url: string;
  readonly price_display: number | null;
  readonly price_display_type: MyFlatPriceDisplayType;
  price_unit?: MyFlatPriceUnit;
  readonly proprietor_city: string;
  readonly proprietor_first_name: string;
  readonly proprietor_last_name: string;
  readonly proprietor_street: string;
  readonly proprietor_zipcode: string;
  readonly public_address: string;
  readonly public_url: string;
  purchase_token?: string;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  rent_charges?: number | null;
  rent_gross?: number | null;
  rent_net?: number | null;
  reserved?: boolean;
  selling_price?: number | null;
  readonly short_title: string;
  show_exact_address?: boolean;
  readonly slug: string | null;
  readonly source: string;
  readonly space_display: number | null;
  state?: string | null;
  status?: ListingStatus;
  street?: string | null;
  surface_living?: number | null;
  surface_property?: number | null;
  surface_usable?: number | null;
  surface_usable_minimum?: number | null;
  tour_url?: string | null;
  video_url?: string | null;
  viewing_contact_name?: string | null;
  volume?: number | null;
  website_url?: string | null;
  welcome_message_attachments?: FlatDocuments[];
  welcome_messages?: MyFlatWelcomeMessages;
  year_built?: number | null;
  year_renovated?: number | null;
  zipcode: number;
}

/**
 * * `moving` - moving
 * `cleaning` - cleaning
 * `combined` - combined
 */
export type MovingServiceType = typeof MovingServiceType[keyof typeof MovingServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MovingServiceType = {
  moving: 'moving',
  cleaning: 'cleaning',
  combined: 'combined',
} as const;

/**
 * * `imm` - Immediately
 * `agr` - By agreement
 * `dat` - Specific date
 */
export type MovingDateType = typeof MovingDateType[keyof typeof MovingDateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MovingDateType = {
  imm: 'imm',
  agr: 'agr',
  dat: 'dat',
} as const;

export interface MobilePhoneNumber {
  mobile_phone_numbers: string[];
}

export interface MetricsData {
  branch: number;
  date: string;
  name: string;
}

/**
 * * `plain` - PLAIN
 * `status` - STATUS
 * `propose_date` - PROPOSE_DATE
 * `accept_date` - ACCEPT_DATE
 * `reject_date` - REJECT_DATE
 * `remove_date` - REMOVE_DATE
 */
export type MessageType = typeof MessageType[keyof typeof MessageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageType = {
  plain: 'plain',
  status: 'status',
  propose_date: 'propose_date',
  accept_date: 'accept_date',
  reject_date: 'reject_date',
  remove_date: 'remove_date',
} as const;

/**
 * * `plain` - Plain message
 * `status` - Status message
 * `propose_date` - Propose viewing date
 * `accept_date` - Accept viewing date
 * `reject_date` - Reject viewing date
 * `remove_date` - Remove viewing date
 */
export type MessageMessageTypeEnum =
  typeof MessageMessageTypeEnum[keyof typeof MessageMessageTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageMessageTypeEnum = {
  plain: 'plain',
  status: 'status',
  propose_date: 'propose_date',
  accept_date: 'accept_date',
  reject_date: 'reject_date',
  remove_date: 'remove_date',
} as const;

export type MessageConfigWorkOrderAdditionalNotes = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigWelcomeMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigTicketSubmittedMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigTicketForwardedMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigSignContractMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigSelection = NestedSelection | null;

export type MessageConfigSecurityDepositRequestMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigRejectQuoteMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigRejectApplicantMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigChooseApplicantMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type MessageConfigApplicationFormSubmittedMessage = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export interface MessageConfig {
  application_form_submitted_message?: MessageConfigApplicationFormSubmittedMessage;
  choose_applicant_message?: MessageConfigChooseApplicantMessage;
  readonly is_flatfox_default: boolean;
  name: string;
  ordering?: number;
  readonly pk: number;
  reject_applicant_message?: MessageConfigRejectApplicantMessage;
  reject_quote_message?: MessageConfigRejectQuoteMessage;
  security_deposit_request_message?: MessageConfigSecurityDepositRequestMessage;
  selection?: MessageConfigSelection;
  sign_contract_message?: MessageConfigSignContractMessage;
  ticket_forwarded_message?: MessageConfigTicketForwardedMessage;
  ticket_submitted_message?: MessageConfigTicketSubmittedMessage;
  welcome_message?: MessageConfigWelcomeMessage;
  work_order_additional_notes?: MessageConfigWorkOrderAdditionalNotes;
}

export type MessageStatusArgs = { [key: string]: any } | null;

export type MessageSenderRole = Role | null;

/**
 * Serializes the messages depending on who is the current user. You'd need
to use a partial for setting the constructor's user argument
 */
export interface Message {
  readonly ad: number;
  attachment_pks?: number[];
  readonly attachments: Attachment[];
  readonly buttons: string[] | null;
  readonly created: string;
  date_proposal?: DateProposal;
  message_type: MessageMessageTypeEnum;
  readonly pk: number;
  readonly rendered: RenderedMessage;
  readonly sender: number | null;
  readonly sender_name: string | null;
  readonly sender_role: MessageSenderRole;
  readonly status_args: MessageStatusArgs;
  text?: string | null;
  thread: number;
}

export type MembershipUser = number | NestedMembershipUser;

/**
 * Model for a membership of an organization
 */
export interface Membership {
  access_lease_termination_pks: number[];
  access_listing_pks: number[];
  access_ref_properties: string[];
  access_ticket_pks: number[];
  readonly pk: number;
  role: OrganizationRole;
  user: MembershipUser;
}

/**
 * * `marketing_service_backbone` - Backbone Photo Service
 */
export type MarketingServiceType =
  typeof MarketingServiceType[keyof typeof MarketingServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketingServiceType = {
  marketing_service_backbone: 'marketing_service_backbone',
} as const;

export interface MarketingServiceFormatted {
  readonly products: string;
  readonly service_type: string;
}

export interface MarketingService {
  readonly created: string;
  readonly formatted: MarketingServiceFormatted;
  readonly listing: number;
  readonly order_placed_time: string | null;
  readonly owner: number;
  readonly pk: number;
  readonly products: string[];
  readonly purchaser: number;
  readonly receipt_pdf: string | null;
  readonly service_type: MarketingServiceType;
}

/**
 * * `UNMARRIED` - Unmarried
 * `MARRIED` - Married
 * `CIVIL_UNION` - Civil union
 * `DIVORCED` - Divorced
 * `WIDOWED` - Widowed
 * `SEPARATED` - Separated
 */
export type MaritalStatus = typeof MaritalStatus[keyof typeof MaritalStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaritalStatus = {
  UNMARRIED: 'UNMARRIED',
  MARRIED: 'MARRIED',
  CIVIL_UNION: 'CIVIL_UNION',
  DIVORCED: 'DIVORCED',
  WIDOWED: 'WIDOWED',
  SEPARATED: 'SEPARATED',
} as const;

export interface Logo {
  readonly url: string;
  readonly url_org_logo_m: string;
}

/**
 * We'll need to customize the serializer for a couple of things:
- Provide the correct exception for when a device verification is needed.
- Make the password field optional, so we can use this for PASSWORDLESS logins
- Add the `otp` field for PASSWORDLESS and DEVICE_VERIFICATION workflows.

Note that this is the same code, in spirit, as in
dj_rest_auth.serializers.LoginSerializer, without all the configurability.
 */
export interface Login {
  email: string;
  otp?: string;
  password?: string;
}

/**
 * * `single_person` - Single
 * `multi_person` - Family
 */
export type LivingArrangementOption =
  typeof LivingArrangementOption[keyof typeof LivingArrangementOption];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LivingArrangementOption = {
  single_person: 'single_person',
  multi_person: 'multi_person',
} as const;

/**
 * * `new` - New
 * `def` - Deferred
 * `pre` - Notice
 * `act` - Published
 * `dis` - Paused
 * `arc` - Archived
 * `rem` - Removed
 */
export type ListingStatus = typeof ListingStatus[keyof typeof ListingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListingStatus = {
  new: 'new',
  def: 'def',
  pre: 'pre',
  act: 'act',
  dis: 'dis',
  arc: 'arc',
  rem: 'rem',
} as const;

export interface ListingStats {
  advertiser: number;
  applicant_chosen: string;
  application_pushed: string;
  branch: number;
  checklist_created: string;
  checklist_finished: string;
  city: string;
  closed: string;
  created: string;
  readonly days_disabled: number;
  readonly days_prepublished: number;
  readonly days_published: number;
  direct_submit_view: string;
  readonly floor: number | null;
  flyer_downloaded: string;
  form_submitted: string;
  readonly is_latest: boolean;
  listing: number;
  marketing_flyer_downloaded: string;
  num_applications: number;
  num_clicks: number;
  num_confirmed_viewing_appointments: number;
  num_contact_requests: number;
  num_rooms: string;
  readonly object_category: string | null;
  readonly object_type: string | null;
  prepublished: string;
  project: number;
  published: string;
  readonly ref_house: string;
  readonly ref_object: string;
  readonly ref_property: string;
  reference: string;
  readonly rent_gross: number | null;
  rent_gross_pa: number;
  rent_gross_pa_actual: number;
  readonly rent_net: number | null;
  rent_net_pa: number;
  rent_net_pa_actual: number;
  rent_status: string;
  rented: boolean;
  rented_in_period: boolean;
  security_deposit_request_finished: string;
  security_deposit_request_started: string;
  signature_process_finished: string;
  signature_process_started: string;
  status: string;
  street: string;
  readonly surface_living: number | null;
  zipcode: number;
}

export type ListingLikeReactionSummariesItem = { [key: string]: unknown };

export type ListingLikeOwnReaction = FlatLikeReaction | null;

export type ListingLikeListing = number | ListingContext;

/**
 * Serializer for ListingLike(s).
 */
export interface ListingLike {
  listing: ListingLikeListing;
  readonly own_reaction: ListingLikeOwnReaction;
  readonly pk: number;
  readonly reaction_summaries: ListingLikeReactionSummariesItem[];
  readonly shareable: number;
  status: LikeStatus;
}

export interface ListingImage {
  caption?: string | null;
  readonly height: number | null;
  readonly ordering: number | null;
  pk?: number;
  readonly url: string;
  readonly url_listing_search: string;
  readonly url_thumb_m: string;
  readonly width: number | null;
}

/**
 * Model for a formatted listing
 */
export interface ListingFormatter {
  readonly available: string;
  readonly floor: string;
  readonly full: string[];
  /** @deprecated */
  readonly livingspace: string;
  readonly number_of_rooms: string;
  readonly price_unit: string;
  readonly rent_charges: string;
  readonly rent_gross: string;
  readonly rent_net: string;
  readonly selling_price: string;
  readonly short_title: string;
  readonly space: string;
}

export type ListingContextPriceDisplayType = PriceDisplayType | null;

export type ListingContextCoverImage = number | FlatImage;

/**
 * * `view` - View
 * `parkingspace` - Parking space
 * `garage` - Garage
 * `lift` - Lift
 * `balconygarden` - Balcony/Garden
 * `fireplace` - Fire place
 * `cable` - Cable
 * `broadbandinternet` - Broadband internet
 * `parquetflooring` - Parquet flooring
 * `accessiblewithwheelchair` - Accessible with wheelchair
 * `petsallowed` - Pets allowed
 * `partofcooperative` - Part of cooperative
 * `stonefloor` - Stone floor
 * `dishwasher` - Dishwasher
 * `tumbler` - Tumbler
 * `washingmachine` - Washing machine
 * `minergie` - Minergie
 * `ramp` - Ramp
 * `liftingplatform` - Lifting platform
 * `raisedgroundfloor` - Raised groundfloor
 * `underbuildinglaws` - Under building laws
 */
export type ListingAttribute = typeof ListingAttribute[keyof typeof ListingAttribute];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListingAttribute = {
  view: 'view',
  parkingspace: 'parkingspace',
  garage: 'garage',
  lift: 'lift',
  balconygarden: 'balconygarden',
  fireplace: 'fireplace',
  cable: 'cable',
  broadbandinternet: 'broadbandinternet',
  parquetflooring: 'parquetflooring',
  accessiblewithwheelchair: 'accessiblewithwheelchair',
  petsallowed: 'petsallowed',
  partofcooperative: 'partofcooperative',
  stonefloor: 'stonefloor',
  dishwasher: 'dishwasher',
  tumbler: 'tumbler',
  washingmachine: 'washingmachine',
  minergie: 'minergie',
  ramp: 'ramp',
  liftingplatform: 'liftingplatform',
  raisedgroundfloor: 'raisedgroundfloor',
  underbuildinglaws: 'underbuildinglaws',
} as const;

export interface ListingAgency {
  city: string;
  country: string;
  logo: Logo;
  name: string;
  name_2: string;
  street: string;
  zipcode: string;
}

/**
 * Used for the list action, as it excludes some heavy performance operations.
See PublicListingDetailSerializer for the retrieve action.
 */
export interface ListingContext {
  agency: ListingAgency;
  readonly city: string;
  readonly cover_image: ListingContextCoverImage;
  readonly is_furnished: boolean;
  readonly is_subscribed: boolean | null;
  readonly is_temporary: boolean;
  readonly offer_type: OfferType;
  readonly pk: number;
  readonly price_display: number | null;
  readonly price_display_type: ListingContextPriceDisplayType;
  readonly reference: string;
  readonly short_title: string;
  readonly status: ListingStatus;
  readonly url: string;
  readonly zipcode: number;
}

/**
 * SMG Listing serializer that adds corresponding Flat pk to every instance
 */
export interface Listing {
  customer_id?: string | null;
  readonly flat_id: number;
  listing_id: string;
}

/**
 * * `LIKE` - LIKED
 * `DISLIKE` - DISLIKED
 */
export type LikeStatus = typeof LikeStatus[keyof typeof LikeStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LikeStatus = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
} as const;

export type LegacyPublicTicketDecisions = { [key: string]: unknown };

export interface LegacyPublicTicket {
  address: ObjectAddress;
  attachments?: UploadField[];
  readonly created: string;
  decisions: LegacyPublicTicketDecisions;
  description: string;
  doorbell_inscription: string;
  readonly modified: string;
  readonly number: number;
  owner: number;
  readonly pk: number;
  requester: PublicContactInformation;
  title: string;
}

export interface LegacyGCMDevice {
  /** Inactive devices will not be sent notifications */
  active?: boolean;
  /** Opaque application identity, should be filled in for multiple key/certificate access */
  application_id?: string | null;
  /** You should choose FCM, GCM is deprecated

* `FCM` - Firebase Cloud Message
* `GCM` - Google Cloud Message */
  cloud_message_type?: CloudMessageTypeEnum;
  readonly date_created: string | null;
  /** ANDROID_ID / TelephonyManager.getDeviceId() (e.g: 0x01) */
  device_id?: number | null;
  /** @deprecated */
  enabled: boolean;
  readonly id: number;
  name?: string | null;
  registration_id: string;
}

export interface LegacyAPNSDevice {
  /** Inactive devices will not be sent notifications */
  active?: boolean;
  /** Opaque application identity, should be filled in for multiple key/certificate access */
  application_id?: string | null;
  readonly date_created: string | null;
  /** UUID / UIDevice.identifierForVendor() */
  device_id?: string | null;
  /** @deprecated */
  enabled: boolean;
  readonly id: number;
  name?: string | null;
  registration_id: string;
}

/**
 * * `open` - Open
 * `archived` - Archived
 */
export type LeaseTerminationStatus =
  typeof LeaseTerminationStatus[keyof typeof LeaseTerminationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeaseTerminationStatus = {
  open: 'open',
  archived: 'archived',
} as const;

export type LeaseTerminationFollowerUser = number | NestedUser;

export interface LeaseTerminationFollower {
  is_auto?: boolean;
  is_representative?: boolean;
  user: LeaseTerminationFollowerUser;
}

/**
 * List serializer for Lease Termination model
 */
export interface LeaseTerminationList {
  readonly created: string;
  readonly follower_set: LeaseTerminationFollower[];
  readonly message_to_landlord: string | null;
  readonly modified: string;
  readonly number: number;
  readonly pk: number;
  readonly requester: ContactInformation;
  readonly status: LeaseTerminationStatus;
  readonly terminated_objects: PublicTerminatedObject[];
  readonly termination_reason: TerminationReasonEnum;
  readonly viewing_contact_public: boolean;
  readonly viewing_handler: ContactInformation;
}

export type LeaseTerminationDetailViewingsItem = number | PublicViewing;

export type LeaseTerminationDetailTerminatedObjectsItem = number | PublicTerminatedObject;

export type LeaseTerminationDetailSignersItem = number | PublicSigner;

export type LeaseTerminationDetailNextTenantsItem = number | ContactInformation;

/**
 * Detail serializer for Lease Termination model
 */
export interface LeaseTerminationDetail {
  readonly created: string;
  follower_set: LeaseTerminationFollower[];
  readonly message_to_landlord: string | null;
  readonly modified: string;
  readonly next_tenants: LeaseTerminationDetailNextTenantsItem[];
  readonly number: number;
  readonly pk: number;
  readonly requester: ContactInformation;
  readonly signers: LeaseTerminationDetailSignersItem[];
  status?: LeaseTerminationStatus;
  readonly terminated_objects: LeaseTerminationDetailTerminatedObjectsItem[];
  readonly termination_reason: TerminationReasonEnum;
  readonly viewing_contact_public: boolean;
  readonly viewing_handler: ContactInformation;
  readonly viewings: LeaseTerminationDetailViewingsItem[];
}

export type LeaseTerminationViewingsItem = number | PublicViewing;

export type LeaseTerminationTerminatedObjectsItem = number | PublicTerminatedObject;

export type LeaseTerminationSignersItem = number | PublicSigner;

export type LeaseTerminationNextTenantsItem = number | ContactInformation;

/**
 * Detail serializer for Lease Termination model
 */
export interface LeaseTermination {
  readonly created: string;
  readonly message_to_landlord: string | null;
  readonly modified: string;
  readonly next_tenants: LeaseTerminationNextTenantsItem[];
  readonly number: number;
  readonly pk: number;
  readonly requester: ContactInformation;
  readonly signers: LeaseTerminationSignersItem[];
  readonly status: LeaseTerminationStatus;
  readonly terminated_objects: LeaseTerminationTerminatedObjectsItem[];
  readonly termination_reason: TerminationReasonEnum;
  readonly viewing_contact_public: boolean;
  readonly viewing_handler: ContactInformation;
  readonly viewings: LeaseTerminationViewingsItem[];
}

export type LeadExtra = { [key: string]: unknown };

export interface Lead {
  ad_pk: number;
  campaign_pk?: number;
  readonly created: string;
  extra?: LeadExtra;
  readonly pk: number;
  readonly user: number | null;
}

/**
 * * `en` - English
 * `de` - Deutsch
 * `it` - Italiano
 * `fr` - Français
 */
export type Languages = typeof Languages[keyof typeof Languages];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Languages = {
  en: 'en',
  de: 'de',
  it: 'it',
  fr: 'fr',
} as const;

/**
 * The delivery status of the last reference email, if available.
 */
export type LandlordReferenceExternalReferenceDeliveryStatus =
  ExternalReferenceDeliveryStatusEnum | null;

export type LandlordReferenceExternalReference = { [key: string]: unknown };

export interface LandlordReference {
  readonly checklist: number;
  comment?: string | null;
  complaints_from_other_tenants?: string | null;
  readonly contact_email: string | null;
  readonly contact_name: string | null;
  readonly contact_phone_number: string | null;
  readonly created: string;
  readonly external_reference: LandlordReferenceExternalReference;
  /** The delivery status of the last reference email, if available. */
  readonly external_reference_delivery_status: LandlordReferenceExternalReferenceDeliveryStatus;
  readonly external_reference_email: string | null;
  readonly external_reference_message: string | null;
  readonly external_reference_received: string | null;
  readonly external_reference_referer: string | null;
  readonly external_reference_sent: string | null;
  readonly landlord: string | null;
  paid_in_time?: boolean | null;
  readonly person_city: string | null;
  readonly person_first_name: string;
  readonly person_id: number | null;
  readonly person_last_name: string;
  readonly person_street: string | null;
  readonly person_zipcode: string | null;
  readonly pk: number;
  rent?: string | null;
  readonly reviewer: NestedUser;
  since?: string | null;
  readonly updated: string | null;
  verdict?: boolean | null;
  would_let_rent_again?: boolean | null;
}

export interface Labels {
  advertiser: IntegerKeyLabel[];
  age_b: IntegerKeyLabel[];
  branch: IntegerKeyLabel[];
  gender: CharKeyLabel[];
  marital_status: CharKeyLabel[];
  metric_name: CharKeyLabel[];
  object_category: CharKeyLabel[];
  portal_type: CharKeyLabel[];
  project: IntegerKeyLabel[];
  rent_status: CharKeyLabel[];
  salary_b: IntegerKeyLabel[];
  service_type: CharKeyLabel[];
  status: CharKeyLabel[];
  surface_living_b: IntegerKeyLabel[];
}

export interface LabelTypeProxy {
  color: string;
  text: string;
  text_color: string;
  type: string;
}

export type LabelProxyListing = number | NestedListing;

export interface LabelProxy {
  application: number;
  color: string;
  count: number;
  readonly listing: LabelProxyListing;
  text: string;
  text_color: string;
  type: string;
}

/**
 * * `EM` - Employed
 * `UN` - Unemployed
 * `IN` - Independant
 * `NO` - No occupation
 * `ST` - Student
 * `RE` - Retired
 * `DI` - Disability pension
 */
export type JobStatus = typeof JobStatus[keyof typeof JobStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobStatus = {
  EM: 'EM',
  UN: 'UN',
  IN: 'IN',
  NO: 'NO',
  ST: 'ST',
  RE: 'RE',
  DI: 'DI',
} as const;

/**
 * * `SUBSCRIPTION` - Subscription
 * `RECOMMENDATION` - Recommendation
 */
export type ItemType = typeof ItemType[keyof typeof ItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemType = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  RECOMMENDATION: 'RECOMMENDATION',
} as const;

/**
 * * `CREDIT_CARD` - CREDIT_CARD
 * `COLLECTIVE_INVOICE` - COLLECTIVE_INVOICE
 * `SEPARATE_INVOICE` - SEPARATE_INVOICE
 * `THIRD_PARTY_NO_INVOICE` - THIRD_PARTY_NO_INVOICE
 * `THIRD_PARTY_SEPARATE_INVOICE` - THIRD_PARTY_SEPARATE_INVOICE
 */
export type InvoiceType = typeof InvoiceType[keyof typeof InvoiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceType = {
  CREDIT_CARD: 'CREDIT_CARD',
  COLLECTIVE_INVOICE: 'COLLECTIVE_INVOICE',
  SEPARATE_INVOICE: 'SEPARATE_INVOICE',
  THIRD_PARTY_NO_INVOICE: 'THIRD_PARTY_NO_INVOICE',
  THIRD_PARTY_SEPARATE_INVOICE: 'THIRD_PARTY_SEPARATE_INVOICE',
} as const;

export interface Invoice {
  readonly date: string;
  readonly invoice_type: InvoiceType;
  readonly pk: number;
  readonly recipient_address: string | null;
  readonly recipient_email: string | null;
  status: string;
  readonly total_amount: string | null;
  readonly total_amount_incl_vat: string | null;
}

export type InventoryObjectProperty = number | Property;

export type InventoryObjectHouse = number | House;

export interface InventoryObject {
  house: InventoryObjectHouse;
  readonly pk: number;
  property: InventoryObjectProperty;
  ref_house: string;
  ref_object?: string;
  ref_property: string;
  reference: string;
}

/**
 * Address with additional (sometimes mandatory) link to an inventory link.

### Street address:
`street`, `city`, `zipcode`

### Inventory direct links:
`house_id`, `property_id`, `object_id`

### Reference:
`ref_house`, `ref_property`, `ref_object`

### Create or Update validations:

* Organizations that have an inventory:
   * `house_id` is required
   * Street address and reference data are sourced from the specified
     house_id/object_id.
* Organizations with no inventory:
   * Street address is required
   * `house_id` and `object_id` cannot be set, they are invalid.
   * `ref_property`, `ref_house`, `ref_object` are optional.
 */
export interface InventoryContext {
  city?: string;
  /** On create or update its required for organizations with inventory, otherwise its invalid. */
  house_id?: number | null;
  /** For organizations with inventory capability, it is required on create or update, if house_id is not set. Invalid otherwise. */
  object_id?: number | null;
  /** Read-only. */
  property_id?: number | null;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  street?: string;
  zipcode?: string;
}

export interface InventoryAutocompleteObject {
  house?: number | null;
  readonly pk: number;
  property?: number | null;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
}

export interface IntegerKeyLabel {
  key: number;
  label: string;
}

export type InsuredPersonResidencePermit = ResidencePermit | null;

export type InsuredPersonCitizenship = CitizenshipEnum | null;

export interface InsuredPerson {
  citizenship?: InsuredPersonCitizenship;
  city?: string | null;
  date_of_birth?: string | null;
  email?: string | null;
  first_name: string;
  is_policy_holder?: boolean;
  last_name: string;
  phone_number?: string | null;
  phone_number_country_code?: string | null;
  residence_permit?: InsuredPersonResidencePermit;
  street?: string | null;
  title?: string | null;
  zipcode?: string | null;
}

export interface Inquiry {
  ad_pk: number;
  /** Returns url where the user will be redirect to after a successful
created inquiry. */
  readonly continue_url: string | null;
  readonly created: string;
  from_city?: string | null;
  from_street?: string | null;
  from_zipcode?: number | null;
  readonly pk: number;
  rooms: number;
  salutation: Salutation;
  service_type: MovingServiceType;
  to_city?: string | null;
  to_street?: string | null;
  to_zipcode?: number | null;
  readonly user: number | null;
}

export type InfoToAddress = { [key: string]: string };

export type InfoFromAddress = { [key: string]: string };

/**
 * Serializes a user object and returns info to be pre-filled with the
moving services widget.
 */
export interface Info {
  readonly first_name: string;
  readonly from_address: InfoFromAddress;
  readonly from_rooms: number | null;
  readonly last_name: string;
  readonly phone_number: string | null;
  readonly to_address: InfoToAddress;
}

export interface Income {
  readonly checklist: number;
  comment?: string | null;
  readonly created: string;
  readonly flat_rent: number | null;
  job_gross_salary_end?: number | null;
  job_gross_salary_start?: number | null;
  job_gross_salary_verdict?: number | null;
  readonly pk: number;
  readonly reviewer: NestedUser;
  readonly updated: string | null;
  verdict?: boolean | null;
}

export type InAppUserFeedbackRating = RatingEnum | null;

export interface InAppUserFeedback {
  message?: string | null;
  rating?: InAppUserFeedbackRating;
  topic: string;
}

export type IdxListingFlyer = { [key: string]: string };

export interface IdxFile {
  base64: string;
  name: string;
}

/**
 * Model for IDX files with listings
 */
export interface IdxListing {
  files?: IdxFile[];
  readonly flyer: IdxListingFlyer;
  idx: string;
  readonly pk: number;
  readonly ref_house: string;
  readonly ref_object: string;
  readonly ref_property: string;
  readonly status: ListingStatus;
}

/**
 * * `hypolenzburg` - HYPOLENZBURG
 */
export type HypolenzburgAccountDepositTypeEnum =
  typeof HypolenzburgAccountDepositTypeEnum[keyof typeof HypolenzburgAccountDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HypolenzburgAccountDepositTypeEnum = {
  hypolenzburg: 'hypolenzburg',
} as const;

export interface HypolenzburgAccount {
  readonly account_iban: string | null;
  readonly account_id: string | null;
  readonly contract_pdf: string | null;
  readonly deposit_type: HypolenzburgAccountDepositTypeEnum;
  readonly person: SecurityDepositRequestPerson;
  person_id: number;
  readonly pk: number;
  readonly session_id: string;
}

export interface House {
  city?: string | null;
  readonly owner: number;
  readonly pk: number;
  ref_house: string;
  ref_property: string;
  reference?: string;
  street?: string | null;
  zipcode?: string | null;
}

export interface HiddenState {
  thread: number;
}

/**
 * * `helvetia` - HELVETIA
 */
export type HelvetiaInsuranceDepositTypeEnum =
  typeof HelvetiaInsuranceDepositTypeEnum[keyof typeof HelvetiaInsuranceDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HelvetiaInsuranceDepositTypeEnum = {
  helvetia: 'helvetia',
} as const;

export interface HelvetiaInsurance {
  readonly deposit_type: HelvetiaInsuranceDepositTypeEnum;
  readonly pk: number;
  readonly policy_holder: SecurityDepositRequestPerson;
  readonly price_description: string | null;
}

export interface HblAccount {
  account_type: string;
  currency: string;
  iban: string;
  id: string;
}

/**
 * This serializer is for the Hypolenzburg callback. So when they come back
to us the report a successfull creation of an account.
 */
export interface HblAccountCreated {
  accounts: HblAccount[];
  ebanking_id?: string;
  message: string;
  persons: string[];
  session: string;
  status: string;
}

/**
 * The contact information of a legal or natural entity. Might or might not
be linked to an actual user account. Depending on use case information
might be partial and not usable for contacting on arbitrary channels.

For now we treat this as a snapshot that is immutable, but eventually we
might propagate updates from Agent and Guest side to all active processes.
 */
export interface Guest {
  readonly avatar_url: string;
  city?: string;
  company?: string;
  email?: string;
  first_name?: string;
  language?: string;
  last_name?: string;
  phone_number?: string;
  street?: string;
  readonly user_id: number;
  zipcode?: string;
}

/**
 * A group in aggregated unread state.
 */
export interface GroupedUnreadState {
  /** The number of items in this group. */
  count: number;
  /** The creation date of the latest unread item in this group. */
  created: string;
  internal?: boolean;
  lease_termination?: number | null;
  listing?: number | null;
  rent_status?: RentStatusEnum;
  role?: Role;
  thread_status?: ThreadStatus;
  ticket?: number | null;
}

/**
 * * `CUSTOM` - Custom goal
 * `STAY` - Stay on page
 * `GO_URL` - Navigate to URL but dont register lead
 * `GO_URL_AND_REGISTER_LEAD` - Navigate to URL and register as lead
 */
export type GoalType = typeof GoalType[keyof typeof GoalType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GoalType = {
  CUSTOM: 'CUSTOM',
  STAY: 'STAY',
  GO_URL: 'GO_URL',
  GO_URL_AND_REGISTER_LEAD: 'GO_URL_AND_REGISTER_LEAD',
} as const;

/**
 * * `gocaution` - GOCAUTION
 */
export type GoCautionInsuranceDepositTypeEnum =
  typeof GoCautionInsuranceDepositTypeEnum[keyof typeof GoCautionInsuranceDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GoCautionInsuranceDepositTypeEnum = {
  gocaution: 'gocaution',
} as const;

export interface GoCautionInsurance {
  readonly deposit_type: GoCautionInsuranceDepositTypeEnum;
  readonly pk: number;
  readonly policy_holder: SecurityDepositRequestPerson;
  readonly price_description: string | null;
}

/**
 * * `M` - Male
 * `F` - Female
 */
export type Gender = typeof Gender[keyof typeof Gender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Gender = {
  M: 'M',
  F: 'F',
} as const;

export interface ForwardTicket {
  attachments: UploadField[];
  contact_email: string;
  contact_full_name: string;
  contact_phone_number: string | null;
  message_text: string;
  message_to_requester: string | null;
  notify_requester: boolean;
}

export type FormConfigSelection = NestedSelection | null;

export type FormConfigPersonFields = { [key: string]: unknown };

export type FormConfigFlyerRemarks = {
  '*': string;
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
} | null;

export type FormConfigDossierFields = { [key: string]: unknown };

export type FormConfigConfirmGenericItem = { [key: string]: unknown };

export interface FormConfig {
  allow_direct_apply?: boolean;
  confirm_generic?: FormConfigConfirmGenericItem[];
  confirm_reference?: boolean;
  confirm_visited?: boolean;
  dossier_fields?: FormConfigDossierFields;
  flyer_remarks?: FormConfigFlyerRemarks;
  readonly is_flatfox_default: boolean;
  name: string;
  ordering?: number;
  person_fields?: FormConfigPersonFields;
  readonly pk: number;
  proof_of_creditworthiness_type?: ProofOfCreditworthinessType;
  selection?: FormConfigSelection;
}

export type FlatLikeReactionUser = number | NestedUser;

export interface FlatLikeReaction {
  readonly created: string;
  readonly modified: string;
  readonly pk: number;
  reaction_type: ReactionTypeEnum;
  readonly user: FlatLikeReactionUser;
}

export interface FlatImageUpload {
  caption?: string | null;
  flat?: number | null;
  ordering?: number | null;
  payload: string;
  pk?: number;
  readonly uid: string;
  readonly url: string;
  readonly url_thumb_m: string;
}

/**
 * Serializer for flat image objects. Adds a bunch of thumbnail urls as well.
 */
export interface FlatImage {
  caption?: string | null;
  readonly height: number | null;
  readonly ordering: number | null;
  pk?: number;
  readonly url: string;
  readonly url_listing_search: string;
  readonly url_thumb_m: string;
  readonly width: number | null;
}

/**
 * Model for documents of a flat
 */
export interface FlatDocuments {
  caption?: string | null;
  readonly ordering: number | null;
  pk?: number;
  readonly url: string;
}

export interface FlatDocumentUpload {
  flat?: number | null;
  readonly name: string | null;
  payload?: string | null;
  readonly pk: number;
  readonly uid: string;
}

export type FlatContextPriceDisplayType = PriceDisplayType | null;

export type FlatContextObjectCategory = ObjectCategory | null;

export type FlatContextMovingDateType = MovingDateType | null;

export type FlatContextCoverImage = number | FlatImage;

/**
 * Used for the list action, as it excludes some heavy performance operations.
See PublicListingDetailSerializer for the retrieve action.
 */
export interface FlatContext {
  readonly alternative_reference: string;
  readonly country: string;
  readonly cover_image: FlatContextCoverImage;
  readonly floor: number | null;
  readonly formatted: ListingFormatter;
  readonly full_address: string;
  readonly moving_date: string | null;
  readonly moving_date_type: FlatContextMovingDateType;
  readonly number_of_rooms: string | null;
  readonly object_category: FlatContextObjectCategory;
  readonly offer_type: OfferType;
  readonly pk: number;
  readonly price_display: number | null;
  readonly price_display_type: FlatContextPriceDisplayType;
  readonly public_address: string;
  readonly ref_house: string;
  readonly ref_object: string;
  readonly ref_property: string;
  readonly short_title: string;
  readonly status: ListingStatus;
  readonly url: string;
}

export interface FlatAttr {
  name: string;
}

/**
 * * `firstcaution` - FIRSTCAUTION
 */
export type FirstcautionGuaranteeDepositTypeEnum =
  typeof FirstcautionGuaranteeDepositTypeEnum[keyof typeof FirstcautionGuaranteeDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirstcautionGuaranteeDepositTypeEnum = {
  firstcaution: 'firstcaution',
} as const;

export interface FirstcautionGuarantee {
  readonly deposit_type: FirstcautionGuaranteeDepositTypeEnum;
  readonly pk: number;
  readonly policy_holder: SecurityDepositRequestPerson;
  readonly price_description: string | null;
}

/**
 * * `firstcaution-baloise` - FIRSTCAUTION_BALOISE
 */
export type FirstcautionBaloiseGuaranteeDepositTypeEnum =
  typeof FirstcautionBaloiseGuaranteeDepositTypeEnum[keyof typeof FirstcautionBaloiseGuaranteeDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirstcautionBaloiseGuaranteeDepositTypeEnum = {
  'firstcaution-baloise': 'firstcaution-baloise',
} as const;

export interface FirstcautionBaloiseGuarantee {
  readonly deposit_type: FirstcautionBaloiseGuaranteeDepositTypeEnum;
  readonly pk: number;
  readonly policy_holder: SecurityDepositRequestPerson;
  readonly price_description: string | null;
}

/**
 * * `debt_extract` - debt_extract
 * `letter_of_termination` - letter_of_termination
 */
export type FilesTypeEnum = typeof FilesTypeEnum[keyof typeof FilesTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilesTypeEnum = {
  debt_extract: 'debt_extract',
  letter_of_termination: 'letter_of_termination',
} as const;

export interface Files {
  created: string;
  name: string;
  type: FilesTypeEnum;
  url: string;
}

export interface FeedItem {
  readonly created: string;
  readonly listing: number;
  readonly notified: string | null;
  readonly pk: number;
  readonly type: ItemType;
  readonly user: number;
}

/**
 * Serializer that mimics dj-rest-auth's social login serializer's fields,
minus any custom logic/validation.
 */
export interface FacebookLoginOIDC {
  access_token?: string;
  code?: string;
  id_token?: string;
}

export type ExternalReferenceDeliveryStatusEnum =
  typeof ExternalReferenceDeliveryStatusEnum[keyof typeof ExternalReferenceDeliveryStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalReferenceDeliveryStatusEnum = {
  sending: 'sending',
  delivered: 'delivered',
  temporary_failure: 'temporary_failure',
  permanent_failure: 'permanent_failure',
  forbidden_recipient: 'forbidden_recipient',
} as const;

export type ExtendedRoleUser = number | NestedUser;

export type ExtendedRoleProperty = number | Property;

export interface ExtendedRole {
  email: string;
  first_name: string;
  last_name: string;
  readonly pk: number;
  property?: ExtendedRoleProperty;
  ref_property: string;
  role: PropertyRole;
  readonly user: ExtendedRoleUser;
}

/**
 * * `newhome_top_promotion_15` - Newhome Top Promotion 15 days
 * `newhome_top_promotion_30` - Newhome Top Promotion 30 days
 * `newhome_top_promotion_60` - Newhome Top Promotion 60 days
 * `newhome_special_promotion_15` - Newhome Special Promotion 15 days
 * `newhome_special_promotion_30` - Newhome Special Promotion 30 days
 * `newhome_special_promotion_60` - Newhome Special Promotion 60 days
 * `homegate_top_listing` - SMG Top Listing
 * `homegate_premium_listing` - SMG Premium Listing
 * `immoscout_top_listing` - SMG Top Listing
 */
export type ExporterServiceType =
  typeof ExporterServiceType[keyof typeof ExporterServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExporterServiceType = {
  newhome_top_promotion_15: 'newhome_top_promotion_15',
  newhome_top_promotion_30: 'newhome_top_promotion_30',
  newhome_top_promotion_60: 'newhome_top_promotion_60',
  newhome_special_promotion_15: 'newhome_special_promotion_15',
  newhome_special_promotion_30: 'newhome_special_promotion_30',
  newhome_special_promotion_60: 'newhome_special_promotion_60',
  homegate_top_listing: 'homegate_top_listing',
  homegate_premium_listing: 'homegate_premium_listing',
  immoscout_top_listing: 'immoscout_top_listing',
} as const;

/**
 * * `planned` - PLANNED
 * `cancelled` - CANCELLED
 * `running` - RUNNING
 * `finished` - FINISHED
 */
export type ExporterServiceStatusEnum =
  typeof ExporterServiceStatusEnum[keyof typeof ExporterServiceStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExporterServiceStatusEnum = {
  planned: 'planned',
  cancelled: 'cancelled',
  running: 'running',
  finished: 'finished',
} as const;

export interface ExporterServiceFormatted {
  readonly service_type: string;
}

/**
 * Model for a service that exports a listing to a third party service
 */
export interface ExporterService {
  billing_address?: string | null;
  billing_email?: string | null;
  billing_remarks?: string | null;
  readonly can_stop: boolean;
  readonly created: string;
  end_date?: string | null;
  exporter: number;
  readonly formatted: ExporterServiceFormatted;
  listing: number;
  readonly owner: number;
  readonly pk: number;
  readonly purchaser: number;
  service_type: ExporterServiceType;
  start_date: string;
  readonly status: ExporterServiceStatusEnum;
}

export type ExporterServiceCatalogItemServiceType =
  typeof ExporterServiceCatalogItemServiceType[keyof typeof ExporterServiceCatalogItemServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExporterServiceCatalogItemServiceType = {
  newhome_top_promotion_15: 'newhome_top_promotion_15',
  newhome_top_promotion_30: 'newhome_top_promotion_30',
  newhome_top_promotion_60: 'newhome_top_promotion_60',
  newhome_special_promotion_15: 'newhome_special_promotion_15',
  newhome_special_promotion_30: 'newhome_special_promotion_30',
  newhome_special_promotion_60: 'newhome_special_promotion_60',
  homegate_top_listing: 'homegate_top_listing',
  homegate_premium_listing: 'homegate_premium_listing',
  immoscout_top_listing: 'immoscout_top_listing',
} as const;

export type ExporterServiceCatalogItem = {
  description: string;
  invoice_type: string;
  label: string;
  portal_type: string;
  service_type: ExporterServiceCatalogItemServiceType;
};

export type ExporterSelection = NestedSelection | null;

export interface Exporter {
  agency_id: string;
  auto?: boolean;
  ftp_host?: string | null;
  ftp_password?: string | null;
  ftp_port?: number | null;
  ftp_username?: string | null;
  is_active?: boolean;
  is_private?: boolean;
  last_export?: string | null;
  readonly last_log: number | null;
  readonly pk: number;
  readonly portal_type: string;
  selection?: ExporterSelection;
  readonly service_catalog: ExporterServiceCatalogItem[];
  title: string;
  type: TransferAccountType;
}

export interface Export {
  exporter: number;
  listing: number;
  readonly pk: string;
}

/**
 * * `user.registered` - User registered
 * `user.recovered` - User recovered
 * `user.loggedin` - User logged in
 * `user.loggedout` - User logged out
 * `user.suspended` - User account suspended
 * `user.removed` - User account removed
 * `user.main_email_changed` - User main email changed
 * `user.email_added` - User email added
 * `user.email_removed` - User email removed
 * `user.info_updated` - User info updated
 * `user.profile_updated` - User profile updated
 * `organization.created` - Organization created
 * `organization.updated` - Organization updated
 * `listing.created` - Listing created
 * `listing.prepublished` - Listing prepublished
 * `listing.published` - Listing published
 * `listing.disabled` - Listing disabled
 * `listing.removed` - Listing removed
 * `listing.archived` - Listing archived
 * `listing.reserved` - Listing reserved
 * `listing.unreserved` - Listing unreserved
 * `listing.apply_flyer_downloaded` - Viewing flyer downloaded
 * `listing.marketing_flyer_downloaded` - Marketing flyer downloaded
 * `listing.expiry_extended` - Listing expiry extended
 * `listing.contract_confirmed` - Listing contract confirmed
 * `listing.direct_submit_viewed` - Listing direct submit viewed
 * `listing_like.liked` - Listing liked
 * `listing_like.disliked` - Listing disliked
 * `listing_like.reset` - Listing like reset
 * `listing_task.created` - Listing task created
 * `listing_task.updated` - Listing task updated
 * `listing_task.deleted` - Listing task deleted
 * `application.created` - Application created
 * `application.reactivated` - Application reactivated
 * `application.form_submitted` - Application form submitted
 * `application.removed` - Application removed
 * `application.rejected` - Application rejected
 * `application.transferred` - Application transferred
 * `application.pushed` - Application pushed
 * `application.chosen` - Application chosen
 * `dossier.created` - Dossier created
 * `dossier.reset` - Dossier reset
 * `checklist.created` - Checklist created
 * `checklist.finished` - Checklist finished
 * `checklist.reopened` - Checklist reopened
 * `checklist.removed` - Checklist removed
 * `promotion.created` - Promotion created
 * `message.created` - Message created
 * `thread.read` - Thread read
 * `unreads.changed` - Unreads changed
 * `subscription.created` - Subscription created
 * `subscription.updated` - Subscription updated
 * `subscription.unsubscribed` - Unsubscribed from recommendations
 * `purchase.charged` - Purchase made
 * `pricing.changed` - Pricing changed
 * `insurance_order.created` - Insurance order created
 * `security_deposit_request.started` - Security deposit request started
 * `security_deposit_request.completed` - Security deposit request completed
 * `signature_process.started` - Signature process started
 * `signature_process.completed` - Signature process completed
 * `lease_termination.created` - Lease termination letter created
 * `ticket.created` - Ticket created
 * `ticket.updated` - Ticket updated
 * `ticket.resolved` - Ticket resolved
 * `work_order.created` - Work order created
 * `work_order.pushed` - Work order pushed
 * `export.started` - Export started
 * `export.ended` - Export ended
 * `exporter_service.started` - Export service started
 * `exporter_service.ended` - Export service ended
 * `import.started` - Import started
 * `import.ended` - Import ended
 * `viewing_schedule.created` - Viewing Schedule Created
 * `viewing_schedule.modified` - Viewing Schedule Modified
 * `attendance.created` - Attendance Created
 * `attendance.modified` - Attendance Modified
 * `note.created` - Note created
 * `note.updated` - Note updated
 * `note.deleted` - Note deleted
 */
export type EventType = typeof EventType[keyof typeof EventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  userregistered: 'user.registered',
  userrecovered: 'user.recovered',
  userloggedin: 'user.loggedin',
  userloggedout: 'user.loggedout',
  usersuspended: 'user.suspended',
  userremoved: 'user.removed',
  usermain_email_changed: 'user.main_email_changed',
  useremail_added: 'user.email_added',
  useremail_removed: 'user.email_removed',
  userinfo_updated: 'user.info_updated',
  userprofile_updated: 'user.profile_updated',
  organizationcreated: 'organization.created',
  organizationupdated: 'organization.updated',
  listingcreated: 'listing.created',
  listingprepublished: 'listing.prepublished',
  listingpublished: 'listing.published',
  listingdisabled: 'listing.disabled',
  listingremoved: 'listing.removed',
  listingarchived: 'listing.archived',
  listingreserved: 'listing.reserved',
  listingunreserved: 'listing.unreserved',
  listingapply_flyer_downloaded: 'listing.apply_flyer_downloaded',
  listingmarketing_flyer_downloaded: 'listing.marketing_flyer_downloaded',
  listingexpiry_extended: 'listing.expiry_extended',
  listingcontract_confirmed: 'listing.contract_confirmed',
  listingdirect_submit_viewed: 'listing.direct_submit_viewed',
  listing_likeliked: 'listing_like.liked',
  listing_likedisliked: 'listing_like.disliked',
  listing_likereset: 'listing_like.reset',
  listing_taskcreated: 'listing_task.created',
  listing_taskupdated: 'listing_task.updated',
  listing_taskdeleted: 'listing_task.deleted',
  applicationcreated: 'application.created',
  applicationreactivated: 'application.reactivated',
  applicationform_submitted: 'application.form_submitted',
  applicationremoved: 'application.removed',
  applicationrejected: 'application.rejected',
  applicationtransferred: 'application.transferred',
  applicationpushed: 'application.pushed',
  applicationchosen: 'application.chosen',
  dossiercreated: 'dossier.created',
  dossierreset: 'dossier.reset',
  checklistcreated: 'checklist.created',
  checklistfinished: 'checklist.finished',
  checklistreopened: 'checklist.reopened',
  checklistremoved: 'checklist.removed',
  promotioncreated: 'promotion.created',
  messagecreated: 'message.created',
  threadread: 'thread.read',
  unreadschanged: 'unreads.changed',
  subscriptioncreated: 'subscription.created',
  subscriptionupdated: 'subscription.updated',
  subscriptionunsubscribed: 'subscription.unsubscribed',
  purchasecharged: 'purchase.charged',
  pricingchanged: 'pricing.changed',
  insurance_ordercreated: 'insurance_order.created',
  security_deposit_requeststarted: 'security_deposit_request.started',
  security_deposit_requestcompleted: 'security_deposit_request.completed',
  signature_processstarted: 'signature_process.started',
  signature_processcompleted: 'signature_process.completed',
  lease_terminationcreated: 'lease_termination.created',
  ticketcreated: 'ticket.created',
  ticketupdated: 'ticket.updated',
  ticketresolved: 'ticket.resolved',
  work_ordercreated: 'work_order.created',
  work_orderpushed: 'work_order.pushed',
  exportstarted: 'export.started',
  exportended: 'export.ended',
  exporter_servicestarted: 'exporter_service.started',
  exporter_serviceended: 'exporter_service.ended',
  importstarted: 'import.started',
  importended: 'import.ended',
  viewing_schedulecreated: 'viewing_schedule.created',
  viewing_schedulemodified: 'viewing_schedule.modified',
  attendancecreated: 'attendance.created',
  attendancemodified: 'attendance.modified',
  notecreated: 'note.created',
  noteupdated: 'note.updated',
  notedeleted: 'note.deleted',
} as const;

export interface EventSubscription {
  auth_password?: string | null;
  auth_username?: string | null;
  description: string;
  enabled_events: EventType[];
  readonly owner: number | null;
  readonly pk: number;
  url: string;
}

export type EventData = { [key: string]: unknown } | null;

export interface Event {
  readonly data: EventData;
  readonly pk: number;
  readonly timestamp: string;
  readonly type: EventType;
}

/**
 * The delivery status of the last reference email, if available.
 */
export type EmployerReferenceExternalReferenceDeliveryStatus =
  ExternalReferenceDeliveryStatusEnum | null;

export type EmployerReferenceExternalReference = { [key: string]: unknown } | null;

export interface EmployerReference {
  readonly checklist: number;
  comment?: string | null;
  readonly contact_email: string | null;
  readonly contact_name: string | null;
  readonly contact_phone_number: string | null;
  readonly created: string;
  readonly employer: string | null;
  readonly external_reference: EmployerReferenceExternalReference;
  /** The delivery status of the last reference email, if available. */
  readonly external_reference_delivery_status: EmployerReferenceExternalReferenceDeliveryStatus;
  readonly external_reference_email: string | null;
  readonly external_reference_message: string | null;
  readonly external_reference_received: string | null;
  readonly external_reference_referer: string | null;
  readonly external_reference_sent: string | null;
  is_permanent?: boolean | null;
  job_gross_salary_end?: number | null;
  job_gross_salary_start?: number | null;
  job_status?: string | null;
  job_title?: string | null;
  no_planned_change?: boolean | null;
  readonly person_city: string | null;
  readonly person_first_name: string;
  readonly person_id: number | null;
  readonly person_last_name: string;
  readonly person_street: string | null;
  readonly person_zipcode: string | null;
  readonly pk: number;
  readonly reviewer: NestedUser;
  salary_correct?: boolean | null;
  since?: string | null;
  readonly updated: string | null;
  verdict?: boolean | null;
}

export type EmployeePhoto = number | NestedProfilePhoto;

/**
 * Adds ephemeral and email fields to the user.
 */
export interface Employee {
  readonly avatar_url: string;
  email: string;
  ephemeral: boolean;
  first_name: string;
  full_name: string;
  last_name: string;
  phone_number: string;
  readonly photo: EmployeePhoto;
  pk: number;
}

export interface EmailCheck {
  can_login_password: boolean;
  can_login_passwordless: boolean;
  can_login_social: boolean;
  device_verified: boolean;
  exists: boolean;
  has_usable_password: boolean;
}

export type DownloadMergeFields = { [key: string]: string };

export interface Download {
  merge_fields: DownloadMergeFields;
  readonly uid: string;
  readonly url: string;
}

export interface DossierImageUpload {
  payload: string;
  readonly pk: number;
  readonly uid: string;
  readonly url: string;
  readonly url_thumb_m: string;
}

export interface DossierImage {
  readonly created: string;
  readonly height: number | null;
  readonly pk: number;
  uid: string;
  readonly url: string;
  readonly url_thumb_m: string;
  readonly width: number | null;
}

export interface DossierDocumentUpload {
  caption?: string | null;
  dossier?: number | null;
  is_pending?: boolean;
  readonly name: string | null;
  payload?: string | null;
  pending_reference?: string | null;
  readonly source_verified: boolean;
  type?: ApplicationDocumentType;
  readonly uid: string;
}

export interface DossierDocument {
  caption?: string | null;
  readonly created: string;
  readonly name: string | null;
  readonly pk: number;
  readonly source_verified: boolean;
  uid: string;
  readonly url: string | null;
}

export type DossierDepositType = DepositType | null;

export type DossierCompanyLegalForm = BusinessLegalForm | null;

/**
 * This serializer is used to persist a dossier and store it as json on
an application. Never use this serializer for an enpoint.
 */
export interface Dossier {
  bell_plate_label?: string | null;
  carpooling_interest?: boolean | null;
  children_ages?: string | null;
  co_working_space_interest?: boolean | null;
  company_annual_turnover?: string | null;
  company_correspondence_address?: string | null;
  company_debt_extract?: DossierDocument[];
  company_founding_year?: string | null;
  company_has_private_liability_insurance?: boolean | null;
  company_has_vat?: boolean | null;
  company_headquarters?: string | null;
  company_industry?: string | null;
  company_is_registered?: boolean | null;
  company_legal_form?: DossierCompanyLegalForm;
  company_name?: string | null;
  company_private_liability_insurance_amount?: string | null;
  company_purpose?: string | null;
  company_register_extract?: DossierDocument[];
  company_uid_number?: string | null;
  company_website?: string | null;
  cooperative_shares?: string | null;
  deposit_type?: DossierDepositType;
  documents?: DossierDocument[];
  ev_charging_station_interest?: boolean | null;
  has_musical_instruments?: boolean | null;
  has_parking_lot_interest?: boolean | null;
  has_pets?: boolean | null;
  has_secondary_interest?: boolean | null;
  has_subtenants?: boolean | null;
  household_insurance?: DossierDocument[];
  iban_account?: string | null;
  iban_account_holder?: string | null;
  liability_insurance?: DossierDocument[];
  musical_instruments?: string | null;
  number_adults?: number | null;
  number_children?: number | null;
  persons: Person[];
  pets?: string | null;
  readonly pk: number;
  reason_remarks?: string | null;
  remarks?: string | null;
  requested_moving_date?: string | null;
  seal_tv_connection?: boolean | null;
  secondary_details?: string | null;
  smoker?: boolean | null;
  terminated_by_landlord_reason?: string | null;
  use_as_family?: boolean | null;
  vehicle_plates?: string | null;
  vehicles_and_plates?: string | null;
  was_terminated_by_landlord?: boolean | null;
}

/**
 * This serializer is only for validation purposes of extra fields that UploadField
doesn't contain
 */
export interface DocumentUpload {
  category?: SignatureDocumentType;
  readonly image_height: number;
  readonly image_width: number;
  readonly is_image: boolean;
  readonly mime_type: string;
  readonly name: string;
  signing?: boolean;
  uid: string;
  readonly url: string;
  readonly url_thumb_m: string | null;
}

/**
 * Serializer that mimics dj-rest-auth's social login serializer's fields,
minus any custom logic/validation.
 */
export interface DjRestAuthSocialLogin {
  access_token?: string;
  code?: string;
  id_token?: string;
}

/**
 * Used for the list action, as it excludes some heavy performance operations.
See PublicListingDetailSerializer for the retrieve action.
 */
export interface DirectSubmitListing {
  readonly city: string;
  readonly floor: number | null;
  readonly pk: number;
  readonly public_address: string;
  readonly ref_house: string;
  readonly ref_object: string;
  readonly ref_property: string;
  readonly reference: string;
  readonly short_title: string;
  readonly space_display: number | null;
  readonly zipcode: number;
}

/**
 * * `DEPOSIT_ACCOUNT` - Deposit bank account
 * `DEPOSIT_INSURANCE` - Deposit insurance
 */
export type DepositType = typeof DepositType[keyof typeof DepositType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepositType = {
  DEPOSIT_ACCOUNT: 'DEPOSIT_ACCOUNT',
  DEPOSIT_INSURANCE: 'DEPOSIT_INSURANCE',
} as const;

/**
 * * `fast` - Fast
 * `cheap` - Cheap
 * `registered` - Registered
 */
export type DeliveryProductEnum =
  typeof DeliveryProductEnum[keyof typeof DeliveryProductEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryProductEnum = {
  fast: 'fast',
  cheap: 'cheap',
  registered: 'registered',
} as const;

/**
 * * `90` - 90
 * `180` - 180
 * `270` - 270
 */
export type DegreesEnum = typeof DegreesEnum[keyof typeof DegreesEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DegreesEnum = {
  NUMBER_90: 90,
  NUMBER_180: 180,
  NUMBER_270: 270,
} as const;

/**
 * * `pen` - pending
 * `acc` - accepted
 * `rej` - rejected
 * `rem` - removed
 * `com` - completed
 * `mis` - missed
 */
export type DateProposalStatus =
  typeof DateProposalStatus[keyof typeof DateProposalStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateProposalStatus = {
  pen: 'pen',
  acc: 'acc',
  rej: 'rej',
  rem: 'rem',
  com: 'com',
  mis: 'mis',
} as const;

export type DateProposalSenderRole = Role | null;

export type DateProposalInviteeRole = Role | null;

/**
 * Model for a date proposal of a separate appointment
 */
export interface DateProposal {
  description?: string | null;
  readonly invitee_role: DateProposalInviteeRole;
  location?: string | null;
  readonly pk: number;
  readonly sender_role: DateProposalSenderRole;
  status: DateProposalStatus;
  thread: number;
  title?: string | null;
  when: string;
}

export interface DashboardWorkflowData {
  count: number;
  label: string;
  status: string;
  url: string;
}

export interface DashboardOutstandingData {
  act: number;
  date: string;
  dis: number;
  pre: number;
}

export interface DashboardChosenData {
  arc: number;
  date: string;
}

/**
 * Subclass of DjRestAuthSignUpSerializer but with additional
fields, to be handled by a custom allauth adapter.
 */
export interface CustomDjRestAuthSignUp {
  email: string;
  first_name: string;
  last_name: string;
  password1: string;
  password2: string;
  username?: string;
}

export interface CrmContact {
  city?: string | null;
  date_of_birth?: string | null;
  readonly emails: string[];
  first_name?: string | null;
  last_name?: string | null;
  readonly matching: boolean;
  phone_number?: string | null;
  readonly pk: number;
  readonly search_profiles: SearchProfile[];
  street?: string | null;
  zipcode?: string | null;
}

export interface CrmMatch {
  readonly contact: CrmContact;
  readonly listing: number;
  readonly notified: string | null;
  pk: number;
}

export interface CreditRisk {
  readonly checklist: number;
  comment?: string | null;
  readonly created: string;
  readonly person_first_name: string;
  readonly person_last_name: string;
  readonly pk: number;
  readonly rating: string | null;
  readonly reviewer: NestedUser;
  readonly score: number | null;
  readonly updated: string | null;
  verdict?: boolean | null;
}

export interface CreditCheck {
  readonly created: string;
  readonly pk: number;
  url: string;
  readonly user: number;
}

export interface CreditCheckOrder {
  city: string;
  readonly created: string;
  readonly credit_check: CreditCheck;
  date_of_birth: string;
  email?: string | null;
  first_name: string;
  last_name: string;
  passport_back_pk?: number;
  passport_front_pk?: number;
  phone_number: string;
  readonly pk: number;
  signature?: string | null;
  readonly status: OrderStatus;
  street: string;
  stripe_payment_intent_id: string;
  readonly uid: string;
  readonly user: number;
  zipcode: string;
}

export interface Creative {
  /** Header logo (250x50) */
  readonly advertiser_logo: string | null;
  readonly call_to_action: string | null;
  readonly content_text: string | null;
  readonly disclaimer_text: string | null;
  /** Header image (400x150) */
  readonly header_background: string | null;
  /** Header image (400x200) */
  readonly header_background_l: string | null;
  readonly header_text: string | null;
  readonly lang: Languages;
  /** Messenger teaser message content */
  readonly messenger_message: string | null;
  readonly success_message: string | null;
}

export interface CreatePassportPhoto {
  readonly name: string;
  payload: string;
  readonly pk: number;
}

export interface CreateContactRequest {
  email: string;
  first_name: string;
  last_name: string;
  listing_pk: number;
  phone_number: string;
  readonly redirect_to_thread_url: string;
}

export interface Country {
  readonly code: string;
  readonly name: string;
}

export type CostingStatsPortalType = PortalType | null;

export interface CostingStats {
  advertiser: number;
  amount: number;
  amount_incl_vat: number;
  branch: number;
  readonly currency: string;
  readonly date: string;
  readonly date_through: string | null;
  days: number;
  listing: number;
  object_category: string;
  readonly portal_type: CostingStatsPortalType;
  project: number;
  ref_house: string;
  ref_object: string;
  ref_property: string;
  reference: string;
  readonly service_type: ServiceType;
}

export type CostCenterTitle = {
  readonly '*': string;
  readonly de?: string;
  readonly en?: string;
  readonly fr?: string;
  readonly it?: string;
};

export interface CostCenter {
  readonly number: string;
  readonly pk: number;
  readonly title: CostCenterTitle;
}

/**
 * * `365` - One year
 * `730` - Two years
 * `1095` - Three years
 * `1460` - Four years
 * `1825` - Five years
 */
export type ContractDurationInDaysEnum =
  typeof ContractDurationInDaysEnum[keyof typeof ContractDurationInDaysEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractDurationInDaysEnum = {
  NUMBER_365: 365,
  NUMBER_730: 730,
  NUMBER_1095: 1095,
  NUMBER_1460: 1460,
  NUMBER_1825: 1825,
} as const;

export interface ContactRequestStats {
  city: string;
  created: string;
  email: string;
  first_name: string;
  is_inquiry: boolean;
  last_name: string;
  listing_pk: number;
  phone_number: string;
  ref_house: string;
  ref_object: string;
  ref_property: string;
  source: string;
  street: string;
  text: string;
  zipcode: string;
}

/**
 * The contact information of a legal or natural entity. Might or might not
be linked to an actual user account. Depending on use case information
might be partial and not usable for contacting on arbitrary channels.

For now we treat this as a snapshot that is immutable, but eventually we
might propagate updates from Agent and Guest side to all active processes.
 */
export interface ContactInformation {
  readonly avatar_url: string;
  city?: string;
  company?: string;
  email?: string;
  first_name?: string;
  language?: string;
  last_name?: string;
  phone_number?: string;
  street?: string;
  readonly user_id: number;
  zipcode?: string;
}

/**
 * * `unspecified` - Unspecified
 * `owner` - Owner
 * `supplier` - Supplier
 * `concierge` - Concierge
 * `tenant` - Tenant
 */
export type ContactCategory = typeof ContactCategory[keyof typeof ContactCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactCategory = {
  unspecified: 'unspecified',
  owner: 'owner',
  supplier: 'supplier',
  concierge: 'concierge',
  tenant: 'tenant',
} as const;

export interface Contact {
  categories: ContactCategory[];
  city?: string | null;
  readonly country: string | null;
  email?: string | null;
  full_name?: string | null;
  language?: Languages;
  phone_number?: string | null;
  readonly pk: number;
  readonly properties: number[];
  reference: string;
  street?: string | null;
  zipcode?: string | null;
}

export type ConfigUserProfilePhoto = number | NestedProfilePhoto;

/**
 * This serializer will be used for the auth/user/ endpoint and we'll
embedd it into window.flatfoxConfig.user for all page views.

NB: We need the session in context for resolving current_org.
 */
export interface ConfigUserProfile {
  readonly account_profile_url: string;
  readonly capabilities: string[];
  readonly email: string;
  readonly features: string[];
  first_name?: string;
  full_name?: string;
  gtc_version?: string | null;
  lang?: string | null;
  last_name?: string;
  readonly organization_pk: number;
  readonly organization_role: string | null;
  readonly owner_pk: number;
  phone_number?: string | null;
  readonly photo: ConfigUserProfilePhoto;
  pk?: number;
  readonly pricing: string;
  readonly sha256_email: string;
  readonly sha256_phone_number: string | null;
  signature_mobile_phone_number?: string | null;
}

export type ConfigValue = { [key: string]: unknown };

export interface Config {
  readonly key: string;
  value: ConfigValue;
}

/**
 * * `FCM` - Firebase Cloud Message
 * `GCM` - Google Cloud Message
 */
export type CloudMessageTypeEnum =
  typeof CloudMessageTypeEnum[keyof typeof CloudMessageTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudMessageTypeEnum = {
  FCM: 'FCM',
  GCM: 'GCM',
} as const;

export type ClicksClicksPerDayItemItem = string | number;

export interface Clicks {
  readonly clicks_per_day: ClicksClicksPerDayItemItem[][];
  readonly total_clicks: number;
}

/**
 * * `CH` - Switzerland
 * `LI` - Liechtenstein
 * `DE` - Germany
 * `AT` - Austria
 * `` - --
 * `AF` - Afghanistan
 * `AX` - Åland Islands
 * `AL` - Albania
 * `DZ` - Algeria
 * `AS` - American Samoa
 * `AD` - Andorra
 * `AO` - Angola
 * `AI` - Anguilla
 * `AQ` - Antarctica
 * `AG` - Antigua and Barbuda
 * `AR` - Argentina
 * `AM` - Armenia
 * `AW` - Aruba
 * `AU` - Australia
 * `AZ` - Azerbaijan
 * `BS` - Bahamas
 * `BH` - Bahrain
 * `BD` - Bangladesh
 * `BB` - Barbados
 * `BY` - Belarus
 * `BE` - Belgium
 * `BZ` - Belize
 * `BJ` - Benin
 * `BM` - Bermuda
 * `BT` - Bhutan
 * `BO` - Bolivia
 * `BQ` - Bonaire, Sint Eustatius and Saba
 * `BA` - Bosnia and Herzegovina
 * `BW` - Botswana
 * `BV` - Bouvet Island
 * `BR` - Brazil
 * `IO` - British Indian Ocean Territory
 * `BN` - Brunei
 * `BG` - Bulgaria
 * `BF` - Burkina Faso
 * `BI` - Burundi
 * `CV` - Cabo Verde
 * `KH` - Cambodia
 * `CM` - Cameroon
 * `CA` - Canada
 * `KY` - Cayman Islands
 * `CF` - Central African Republic
 * `TD` - Chad
 * `CL` - Chile
 * `CN` - China
 * `CX` - Christmas Island
 * `CC` - Cocos (Keeling) Islands
 * `CO` - Colombia
 * `KM` - Comoros
 * `CG` - Congo
 * `CD` - Congo (the Democratic Republic of the)
 * `CK` - Cook Islands
 * `CR` - Costa Rica
 * `CI` - Côte d'Ivoire
 * `HR` - Croatia
 * `CU` - Cuba
 * `CW` - Curaçao
 * `CY` - Cyprus
 * `CZ` - Czechia
 * `DK` - Denmark
 * `DJ` - Djibouti
 * `DM` - Dominica
 * `DO` - Dominican Republic
 * `EC` - Ecuador
 * `EG` - Egypt
 * `SV` - El Salvador
 * `GQ` - Equatorial Guinea
 * `ER` - Eritrea
 * `EE` - Estonia
 * `SZ` - Eswatini
 * `ET` - Ethiopia
 * `FK` - Falkland Islands (Malvinas)
 * `FO` - Faroe Islands
 * `FJ` - Fiji
 * `FI` - Finland
 * `FR` - France
 * `GF` - French Guiana
 * `PF` - French Polynesia
 * `TF` - French Southern Territories
 * `GA` - Gabon
 * `GM` - Gambia
 * `GE` - Georgia
 * `GH` - Ghana
 * `GI` - Gibraltar
 * `GR` - Greece
 * `GL` - Greenland
 * `GD` - Grenada
 * `GP` - Guadeloupe
 * `GU` - Guam
 * `GT` - Guatemala
 * `GG` - Guernsey
 * `GN` - Guinea
 * `GW` - Guinea-Bissau
 * `GY` - Guyana
 * `HT` - Haiti
 * `HM` - Heard Island and McDonald Islands
 * `VA` - Holy See
 * `HN` - Honduras
 * `HK` - Hong Kong
 * `HU` - Hungary
 * `IS` - Iceland
 * `IN` - India
 * `ID` - Indonesia
 * `IR` - Iran
 * `IQ` - Iraq
 * `IE` - Ireland
 * `IM` - Isle of Man
 * `IL` - Israel
 * `IT` - Italy
 * `JM` - Jamaica
 * `JP` - Japan
 * `JE` - Jersey
 * `JO` - Jordan
 * `KZ` - Kazakhstan
 * `KE` - Kenya
 * `KI` - Kiribati
 * `XK` - Kosovo
 * `KW` - Kuwait
 * `KG` - Kyrgyzstan
 * `LA` - Laos
 * `LV` - Latvia
 * `LB` - Lebanon
 * `LS` - Lesotho
 * `LR` - Liberia
 * `LY` - Libya
 * `LT` - Lithuania
 * `LU` - Luxembourg
 * `MO` - Macao
 * `MG` - Madagascar
 * `MW` - Malawi
 * `MY` - Malaysia
 * `MV` - Maldives
 * `ML` - Mali
 * `MT` - Malta
 * `MH` - Marshall Islands
 * `MQ` - Martinique
 * `MR` - Mauritania
 * `MU` - Mauritius
 * `YT` - Mayotte
 * `MX` - Mexico
 * `FM` - Micronesia (Federated States of)
 * `MD` - Moldova
 * `MC` - Monaco
 * `MN` - Mongolia
 * `ME` - Montenegro
 * `MS` - Montserrat
 * `MA` - Morocco
 * `MZ` - Mozambique
 * `MM` - Myanmar
 * `NA` - Namibia
 * `NR` - Nauru
 * `NP` - Nepal
 * `NL` - Netherlands
 * `NC` - New Caledonia
 * `NZ` - New Zealand
 * `NI` - Nicaragua
 * `NE` - Niger
 * `NG` - Nigeria
 * `NU` - Niue
 * `NF` - Norfolk Island
 * `KP` - North Korea
 * `MK` - North Macedonia
 * `MP` - Northern Mariana Islands
 * `NO` - Norway
 * `OM` - Oman
 * `PK` - Pakistan
 * `PW` - Palau
 * `PS` - Palestine, State of
 * `PA` - Panama
 * `PG` - Papua New Guinea
 * `PY` - Paraguay
 * `PE` - Peru
 * `PH` - Philippines
 * `PN` - Pitcairn
 * `PL` - Poland
 * `PT` - Portugal
 * `PR` - Puerto Rico
 * `QA` - Qatar
 * `RE` - Réunion
 * `RO` - Romania
 * `RU` - Russia
 * `RW` - Rwanda
 * `BL` - Saint Barthélemy
 * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * `KN` - Saint Kitts and Nevis
 * `LC` - Saint Lucia
 * `MF` - Saint Martin (French part)
 * `PM` - Saint Pierre and Miquelon
 * `VC` - Saint Vincent and the Grenadines
 * `WS` - Samoa
 * `SM` - San Marino
 * `ST` - Sao Tome and Principe
 * `SA` - Saudi Arabia
 * `SN` - Senegal
 * `RS` - Serbia
 * `SC` - Seychelles
 * `SL` - Sierra Leone
 * `SG` - Singapore
 * `SX` - Sint Maarten (Dutch part)
 * `SK` - Slovakia
 * `SI` - Slovenia
 * `SB` - Solomon Islands
 * `SO` - Somalia
 * `ZA` - South Africa
 * `GS` - South Georgia and the South Sandwich Islands
 * `KR` - South Korea
 * `SS` - South Sudan
 * `ES` - Spain
 * `LK` - Sri Lanka
 * `SD` - Sudan
 * `SR` - Suriname
 * `SJ` - Svalbard and Jan Mayen
 * `SE` - Sweden
 * `SY` - Syria
 * `TW` - Taiwan
 * `TJ` - Tajikistan
 * `TZ` - Tanzania
 * `TH` - Thailand
 * `TL` - Timor-Leste
 * `TG` - Togo
 * `TK` - Tokelau
 * `TO` - Tonga
 * `TT` - Trinidad and Tobago
 * `TN` - Tunisia
 * `TR` - Türkiye
 * `TM` - Turkmenistan
 * `TC` - Turks and Caicos Islands
 * `TV` - Tuvalu
 * `UG` - Uganda
 * `UA` - Ukraine
 * `AE` - United Arab Emirates
 * `GB` - United Kingdom
 * `UM` - United States Minor Outlying Islands
 * `US` - United States of America
 * `UY` - Uruguay
 * `UZ` - Uzbekistan
 * `VU` - Vanuatu
 * `VE` - Venezuela
 * `VN` - Vietnam
 * `VG` - Virgin Islands (British)
 * `VI` - Virgin Islands (U.S.)
 * `WF` - Wallis and Futuna
 * `EH` - Western Sahara
 * `YE` - Yemen
 * `ZM` - Zambia
 * `ZW` - Zimbabwe
 */
export type CitizenshipEnum = typeof CitizenshipEnum[keyof typeof CitizenshipEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CitizenshipEnum = {
  CH: 'CH',
  LI: 'LI',
  DE: 'DE',
  AT: 'AT',
  AF: 'AF',
  AX: 'AX',
  AL: 'AL',
  DZ: 'DZ',
  AS: 'AS',
  AD: 'AD',
  AO: 'AO',
  AI: 'AI',
  AQ: 'AQ',
  AG: 'AG',
  AR: 'AR',
  AM: 'AM',
  AW: 'AW',
  AU: 'AU',
  AZ: 'AZ',
  BS: 'BS',
  BH: 'BH',
  BD: 'BD',
  BB: 'BB',
  BY: 'BY',
  BE: 'BE',
  BZ: 'BZ',
  BJ: 'BJ',
  BM: 'BM',
  BT: 'BT',
  BO: 'BO',
  BQ: 'BQ',
  BA: 'BA',
  BW: 'BW',
  BV: 'BV',
  BR: 'BR',
  IO: 'IO',
  BN: 'BN',
  BG: 'BG',
  BF: 'BF',
  BI: 'BI',
  CV: 'CV',
  KH: 'KH',
  CM: 'CM',
  CA: 'CA',
  KY: 'KY',
  CF: 'CF',
  TD: 'TD',
  CL: 'CL',
  CN: 'CN',
  CX: 'CX',
  CC: 'CC',
  CO: 'CO',
  KM: 'KM',
  CG: 'CG',
  CD: 'CD',
  CK: 'CK',
  CR: 'CR',
  CI: 'CI',
  HR: 'HR',
  CU: 'CU',
  CW: 'CW',
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  DJ: 'DJ',
  DM: 'DM',
  DO: 'DO',
  EC: 'EC',
  EG: 'EG',
  SV: 'SV',
  GQ: 'GQ',
  ER: 'ER',
  EE: 'EE',
  SZ: 'SZ',
  ET: 'ET',
  FK: 'FK',
  FO: 'FO',
  FJ: 'FJ',
  FI: 'FI',
  FR: 'FR',
  GF: 'GF',
  PF: 'PF',
  TF: 'TF',
  GA: 'GA',
  GM: 'GM',
  GE: 'GE',
  GH: 'GH',
  GI: 'GI',
  GR: 'GR',
  GL: 'GL',
  GD: 'GD',
  GP: 'GP',
  GU: 'GU',
  GT: 'GT',
  GG: 'GG',
  GN: 'GN',
  GW: 'GW',
  GY: 'GY',
  HT: 'HT',
  HM: 'HM',
  VA: 'VA',
  HN: 'HN',
  HK: 'HK',
  HU: 'HU',
  IS: 'IS',
  IN: 'IN',
  ID: 'ID',
  IR: 'IR',
  IQ: 'IQ',
  IE: 'IE',
  IM: 'IM',
  IL: 'IL',
  IT: 'IT',
  JM: 'JM',
  JP: 'JP',
  JE: 'JE',
  JO: 'JO',
  KZ: 'KZ',
  KE: 'KE',
  KI: 'KI',
  XK: 'XK',
  KW: 'KW',
  KG: 'KG',
  LA: 'LA',
  LV: 'LV',
  LB: 'LB',
  LS: 'LS',
  LR: 'LR',
  LY: 'LY',
  LT: 'LT',
  LU: 'LU',
  MO: 'MO',
  MG: 'MG',
  MW: 'MW',
  MY: 'MY',
  MV: 'MV',
  ML: 'ML',
  MT: 'MT',
  MH: 'MH',
  MQ: 'MQ',
  MR: 'MR',
  MU: 'MU',
  YT: 'YT',
  MX: 'MX',
  FM: 'FM',
  MD: 'MD',
  MC: 'MC',
  MN: 'MN',
  ME: 'ME',
  MS: 'MS',
  MA: 'MA',
  MZ: 'MZ',
  MM: 'MM',
  NA: 'NA',
  NR: 'NR',
  NP: 'NP',
  NL: 'NL',
  NC: 'NC',
  NZ: 'NZ',
  NI: 'NI',
  NE: 'NE',
  NG: 'NG',
  NU: 'NU',
  NF: 'NF',
  KP: 'KP',
  MK: 'MK',
  MP: 'MP',
  NO: 'NO',
  OM: 'OM',
  PK: 'PK',
  PW: 'PW',
  PS: 'PS',
  PA: 'PA',
  PG: 'PG',
  PY: 'PY',
  PE: 'PE',
  PH: 'PH',
  PN: 'PN',
  PL: 'PL',
  PT: 'PT',
  PR: 'PR',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RU: 'RU',
  RW: 'RW',
  BL: 'BL',
  SH: 'SH',
  KN: 'KN',
  LC: 'LC',
  MF: 'MF',
  PM: 'PM',
  VC: 'VC',
  WS: 'WS',
  SM: 'SM',
  ST: 'ST',
  SA: 'SA',
  SN: 'SN',
  RS: 'RS',
  SC: 'SC',
  SL: 'SL',
  SG: 'SG',
  SX: 'SX',
  SK: 'SK',
  SI: 'SI',
  SB: 'SB',
  SO: 'SO',
  ZA: 'ZA',
  GS: 'GS',
  KR: 'KR',
  SS: 'SS',
  ES: 'ES',
  LK: 'LK',
  SD: 'SD',
  SR: 'SR',
  SJ: 'SJ',
  SE: 'SE',
  SY: 'SY',
  TW: 'TW',
  TJ: 'TJ',
  TZ: 'TZ',
  TH: 'TH',
  TL: 'TL',
  TG: 'TG',
  TK: 'TK',
  TO: 'TO',
  TT: 'TT',
  TN: 'TN',
  TR: 'TR',
  TM: 'TM',
  TC: 'TC',
  TV: 'TV',
  UG: 'UG',
  UA: 'UA',
  AE: 'AE',
  GB: 'GB',
  UM: 'UM',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VU: 'VU',
  VE: 'VE',
  VN: 'VN',
  VG: 'VG',
  VI: 'VI',
  WF: 'WF',
  EH: 'EH',
  YE: 'YE',
  ZM: 'ZM',
  ZW: 'ZW',
} as const;

/**
 * * `pending` - PENDING
 * `closed-positive` - CLOSED_POSITIVE
 * `closed-negative` - CLOSED_NEGATIVE
 */
export type ChecklistStatus = typeof ChecklistStatus[keyof typeof ChecklistStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChecklistStatus = {
  pending: 'pending',
  'closed-positive': 'closed-positive',
  'closed-negative': 'closed-negative',
} as const;

export interface Checklist {
  readonly application_context: number | null;
  application_form_submitted?: string | null;
  comment?: string | null;
  readonly created: string;
  readonly finished: boolean | null;
  readonly owner: number;
  readonly pk: number;
  readonly reviewer: NestedUser;
  readonly updated: string | null;
}

export interface CheckForceUpdateAlert {
  button: string;
  description: string;
  title: string;
  url: string;
}

export interface CheckForceUpdate {
  alert: CheckForceUpdateAlert;
}

export interface CharKeyLabel {
  key: string;
  label: string;
}

export type CancellationNotificationDepositType = SecurityDepositType | null;

/**
 * * `with-claim` - With claim
 * `without-claim` - Without claim
 */
export type CancellationClaimType =
  typeof CancellationClaimType[keyof typeof CancellationClaimType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancellationClaimType = {
  'with-claim': 'with-claim',
  'without-claim': 'without-claim',
} as const;

export interface CancellationNotification {
  agency_email: string;
  agency_phone: string;
  city: string;
  claim_type: CancellationClaimType;
  readonly created: string;
  deposit_type?: CancellationNotificationDepositType;
  effective_date: string;
  first_name: string;
  last_name: string;
  readonly pk: number;
  ref_house: string;
  ref_object: string;
  ref_property: string;
  street: string;
  readonly uid: string;
  zipcode: string;
}

/**
 * * `SOLE_PROPRIETORSHIP` - Sole proprietorship
 * `LLC` - LLC
 * `PLC` - PLC
 * `GENERAL_PARTNERSHIP` - General partnership
 * `ASSOCIATION` - Association
 * `BRANCH` - Branch
 * `FOUNDATION` - Foundation
 * `COOPERATIVE` - Cooperative
 */
export type BusinessLegalForm = typeof BusinessLegalForm[keyof typeof BusinessLegalForm];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessLegalForm = {
  SOLE_PROPRIETORSHIP: 'SOLE_PROPRIETORSHIP',
  LLC: 'LLC',
  PLC: 'PLC',
  GENERAL_PARTNERSHIP: 'GENERAL_PARTNERSHIP',
  ASSOCIATION: 'ASSOCIATION',
  BRANCH: 'BRANCH',
  FOUNDATION: 'FOUNDATION',
  COOPERATIVE: 'COOPERATIVE',
} as const;

/**
 * The contact information of a legal or natural entity. Might or might not
be linked to an actual user account. Depending on use case information
might be partial and not usable for contacting on arbitrary channels.

For now we treat this as a snapshot that is immutable, but eventually we
might propagate updates from Agent and Guest side to all active processes.
 */
export interface BroadcastGuest {
  readonly avatar_url: string;
  city?: string;
  company?: string;
  email?: string;
  first_name?: string;
  language?: string;
  last_name?: string;
  phone_number?: string;
  street?: string;
  readonly user_id: number;
  zipcode?: string;
}

export interface BroadcastRecipient {
  guests: BroadcastGuest[];
  object_ids: number[];
  send_email: boolean;
  send_letter: boolean;
}

/**
 * Model for a broadcast ticket
 */
export interface Broadcast {
  allow_reply: boolean;
  attachments?: UploadField[];
  delivery_product?: DeliveryProductEnum;
  description: string;
  language: Languages;
  recipients: BroadcastRecipient[];
  sender_contact?: ContactInformation;
  title: string;
}

export interface Branch {
  readonly city: string;
  readonly country: string;
  readonly email: string | null;
  readonly name: string;
  readonly name_2: string;
  readonly phone: string;
  readonly pk: number;
  readonly street: string;
  readonly url_org_logo_m: string | null;
  readonly zipcode: string;
}

/**
 * * `baloise-pdf` - BALOISE_PDF
 */
export type BaloisePdfDepositTypeEnum =
  typeof BaloisePdfDepositTypeEnum[keyof typeof BaloisePdfDepositTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaloisePdfDepositTypeEnum = {
  'baloise-pdf': 'baloise-pdf',
} as const;

export interface BaloisePdf {
  readonly deposit_type: BaloisePdfDepositTypeEnum;
  readonly pk: number;
  policy_holder_id: number;
  second_person_id: number | null;
}

export interface AutocompleteMember {
  readonly email: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly phone_number: string;
  readonly pk: number;
}

export interface AutocompleteContact {
  readonly categories: ContactCategory[];
  readonly city: string | null;
  readonly email: string | null;
  readonly full_name: string | null;
  readonly phone_number: string | null;
  readonly pk: number;
  readonly properties: number[];
  reference: string;
  readonly street: string | null;
  readonly zipcode: string | null;
}

/**
 * * `active-applications-count-gte-1` - ACTIVE_APPLICATIONS_COUNT_GTE_1
 * `active-applications-count-gte-5` - ACTIVE_APPLICATIONS_COUNT_GTE_5
 * `pushed-application-exists` - PUSHED_APPLICATION_EXISTS
 */
export type AutoReservationCondition =
  typeof AutoReservationCondition[keyof typeof AutoReservationCondition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AutoReservationCondition = {
  'active-applications-count-gte-1': 'active-applications-count-gte-1',
  'active-applications-count-gte-5': 'active-applications-count-gte-5',
  'pushed-application-exists': 'pushed-application-exists',
} as const;

/**
 * * `accepted` - ACCEPTED
 * `declined` - DECLINED
 * `cancelled` - CANCELLED
 */
export type AttendanceStatus = typeof AttendanceStatus[keyof typeof AttendanceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttendanceStatus = {
  accepted: 'accepted',
  declined: 'declined',
  cancelled: 'cancelled',
} as const;

export type AttendanceUser = number | NestedUser;

/**
 * Model for a viewing attendance
 */
export interface Attendance {
  email: string;
  event?: number | null;
  first_name: string;
  last_name: string;
  phone_number: string;
  readonly pk: number;
  schedule: number;
  readonly status: AttendanceStatus;
  readonly user: AttendanceUser;
}

export interface Attachment {
  readonly created: string;
  readonly id: number;
  readonly message: number | null;
  readonly name: string;
  payload?: string | null;
}

export interface ApplicationSummaryZip {
  readonly zip_content: string;
}

export interface ApplicationSummaryPDF {
  /** base64 encoded pdf content */
  readonly pdf: string;
}

export interface ApplicationSubmitForm {
  direct_submit_code?: string | null;
  dossier_pk: number;
  edit_token?: string;
  flat_pk: number;
  submit_mode: SubmitModeEnum;
}

export interface ApplicationStats {
  advertiser: number;
  application: number;
  branch: number;
  city: string;
  closed: string;
  created: string;
  form_submitted: string;
  listing: number;
  num_children: number;
  num_persons: number;
  num_rooms: string;
  object_category: string;
  readonly portal_type: PortalType;
  project: number;
  pushed: string;
  ref_house: string;
  ref_object: string;
  ref_property: string;
  reference: string;
  readonly salary_b: number | null;
  readonly status: string;
  street: string;
  submitted: boolean;
  surface_living: number;
  surface_living_b: number;
  zipcode: string;
}

export interface ApplicationRejectMany {
  pk: number;
  send_reject_message?: boolean;
}

export interface ApplicationPush {
  contract_data: Dossier;
  rental_contract_start_date: string | null;
}

/**
 * Model for a preview of an application flyer
 */
export interface ApplicationFlyerPreview {
  readonly flyer_preview_pdf_url: string;
  lang: string;
  text?: string;
}

export interface ApplicationFindByEmail {
  email: string;
  listing_id: number;
}

/**
 * * `GENERIC` - Generic
 * `ID_CARD` - ID card
 * `SALARY_STATEMENT` - Pay slip
 * `UNEMPLOYED_STATEMENT` - Unemployed statement
 * `DISABILITY_PENSION_STATEMENT` - Disability pension statement
 * `TAX_ASSESSMENT` - Latest tax assessment
 * `CONFIRMATION_OF_RESIDENCE` - Confirmation of residence
 * `DEBT_EXTRACT` - Debt extract
 * `CREDIT_TRUST_CERTIFICATE` - CreditTrust certificate
 * `HOUSEHOLD_INSURANCE` - Household insurance
 * `LIABILITY_INSURANCE` - Liability insurance
 * `COMPANY_DEBT_EXTRACT` - Debt extract
 * `COMPANY_REGISTER_EXTRACT` - Extract from the commercial register
 * `CURRENT_FINANCING_CONFIRMATION` - Current financing confirmation
 * `APPLICATION_FORM` - Application form
 * `MSG_ATTACHMENT` - Message attachment
 * `ADVERTISER_UPLOAD` - Upload
 * `RENT_DEPOSIT` - Rent deposit
 * `INITIAL_RENT_FORM` - Initial rent form
 * `RENTAL_CONTRACT` - Rental contract
 * `SECONDARY_RENTAL_CONTRACT` - Secondary rental contract
 * `RENTAL_CONTRACT_SUPPLEMENT` - Rental contract supplement
 * `RENTAL_CONTRACT_ATTACHMENT` - Rental contract attachment
 * `RENTAL_CONTRACT_PAYMENT_DETAILS` - Rental contract payment details
 * `RENTAL_CONTRACT_DOORBELL_INSCRIPTION` - Rental contract name-plate lettering
 */
export type ApplicationDocumentType =
  typeof ApplicationDocumentType[keyof typeof ApplicationDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplicationDocumentType = {
  GENERIC: 'GENERIC',
  ID_CARD: 'ID_CARD',
  SALARY_STATEMENT: 'SALARY_STATEMENT',
  UNEMPLOYED_STATEMENT: 'UNEMPLOYED_STATEMENT',
  DISABILITY_PENSION_STATEMENT: 'DISABILITY_PENSION_STATEMENT',
  TAX_ASSESSMENT: 'TAX_ASSESSMENT',
  CONFIRMATION_OF_RESIDENCE: 'CONFIRMATION_OF_RESIDENCE',
  DEBT_EXTRACT: 'DEBT_EXTRACT',
  CREDIT_TRUST_CERTIFICATE: 'CREDIT_TRUST_CERTIFICATE',
  HOUSEHOLD_INSURANCE: 'HOUSEHOLD_INSURANCE',
  LIABILITY_INSURANCE: 'LIABILITY_INSURANCE',
  COMPANY_DEBT_EXTRACT: 'COMPANY_DEBT_EXTRACT',
  COMPANY_REGISTER_EXTRACT: 'COMPANY_REGISTER_EXTRACT',
  CURRENT_FINANCING_CONFIRMATION: 'CURRENT_FINANCING_CONFIRMATION',
  APPLICATION_FORM: 'APPLICATION_FORM',
  MSG_ATTACHMENT: 'MSG_ATTACHMENT',
  ADVERTISER_UPLOAD: 'ADVERTISER_UPLOAD',
  RENT_DEPOSIT: 'RENT_DEPOSIT',
  INITIAL_RENT_FORM: 'INITIAL_RENT_FORM',
  RENTAL_CONTRACT: 'RENTAL_CONTRACT',
  SECONDARY_RENTAL_CONTRACT: 'SECONDARY_RENTAL_CONTRACT',
  RENTAL_CONTRACT_SUPPLEMENT: 'RENTAL_CONTRACT_SUPPLEMENT',
  RENTAL_CONTRACT_ATTACHMENT: 'RENTAL_CONTRACT_ATTACHMENT',
  RENTAL_CONTRACT_PAYMENT_DETAILS: 'RENTAL_CONTRACT_PAYMENT_DETAILS',
  RENTAL_CONTRACT_DOORBELL_INSCRIPTION: 'RENTAL_CONTRACT_DOORBELL_INSCRIPTION',
} as const;

/**
 * Model for an application document
 */
export interface ApplicationDocument {
  readonly application: number;
  readonly caption: string | null;
  readonly created: string;
  readonly created_by: number;
  readonly is_pending: boolean;
  readonly name: string | null;
  readonly pk: number;
  ref_house?: string;
  ref_object?: string;
  ref_property?: string;
  type: ApplicationDocumentType;
  readonly uid: string;
  upload: UploadField;
  readonly url: string | null;
}

export type ApplicationContextUser = number | NestedUser;

export type ApplicationContextChecklistStatus = ChecklistStatus | null;

/**
 * Serializer for the nested application instance. Limits set of fields, and renders
them read-only
 */
export interface ApplicationContext {
  readonly checklist_status: ApplicationContextChecklistStatus;
  readonly created: string;
  /** @deprecated */
  readonly email: string | null;
  /** @deprecated */
  readonly first_name: string | null;
  readonly form_submitted: string | null;
  readonly has_form_data: boolean;
  readonly is_favorite: boolean;
  /** @deprecated */
  readonly last_name: string | null;
  /** @deprecated */
  readonly phone_number: string | null;
  readonly pk: number;
  readonly purged: string | null;
  status?: string;
  readonly user: ApplicationContextUser;
}

export type ApplicationUser = number | NestedUser;

export type ApplicationMetadata = { [key: string]: unknown };

export type ApplicationFormData = { [key: string]: unknown } | null;

export type ApplicationFlat = number | NestedListing;

export type ApplicationContractData = { [key: string]: unknown } | null;

export type ApplicationChecklistStatus = ChecklistStatus | null;

/**
 * Application serializer. Adds the user's role (advertiser or applicant).
 */
export interface Application {
  readonly checklist_status: ApplicationChecklistStatus;
  readonly contract_data: ApplicationContractData;
  create_subscription?: boolean;
  readonly created: string;
  email: string;
  first_name: string;
  flat: ApplicationFlat;
  readonly form_data: ApplicationFormData;
  readonly form_pdf_url: string;
  readonly form_submitted: string | null;
  readonly has_form_data: boolean;
  is_favorite?: boolean;
  last_name: string;
  metadata?: ApplicationMetadata;
  phone_number: string;
  readonly pk: number;
  readonly purged: string | null;
  status?: string;
  readonly summary_pdf_url: string;
  readonly summary_zip_url: string;
  text: string;
  readonly user: ApplicationUser;
}

export interface AgencyThread {
  logo: Logo;
  name: string;
}

/**
 * * `manual` - MANUAL
 * `auto` - AUTO
 */
export type AgencyContactMode = typeof AgencyContactMode[keyof typeof AgencyContactMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyContactMode = {
  manual: 'manual',
  auto: 'auto',
} as const;

/**
 * * `TENANT` - TENANT
 * `AGENCY` - AGENCY
 * `OWNER` - OWNER
 */
export type AdvertiserType = typeof AdvertiserType[keyof typeof AdvertiserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdvertiserType = {
  TENANT: 'TENANT',
  AGENCY: 'AGENCY',
  OWNER: 'OWNER',
} as const;

export type AdvertiserUser = number | NestedUser;

export interface Advertiser {
  is_auto?: boolean;
  is_representative?: boolean;
  user: AdvertiserUser;
}

/**
 * * `3akaution` - 3akaution
 * `placeB` - placeB
 * `movu` - movu
 * `helvetia_flink` - helvetia_flink
 * `swisscom_inone` - swisscom_inone
 * `helvetia_mietkaution` - helvetia_mietkaution
 * `quickline` - quickline
 */
export type AdHandler = typeof AdHandler[keyof typeof AdHandler];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdHandler = {
  '3akaution': '3akaution',
  placeB: 'placeB',
  movu: 'movu',
  helvetia_flink: 'helvetia_flink',
  swisscom_inone: 'swisscom_inone',
  helvetia_mietkaution: 'helvetia_mietkaution',
  quickline: 'quickline',
} as const;

export type AdCustomHandler = AdHandler | null;

export interface Ad {
  creative: Creative;
  readonly custom_handler: AdCustomHandler;
  readonly goal_type: GoalType;
  readonly lang: Languages;
  readonly pk: number;
  readonly redirect_url: string;
  readonly target_type: Target;
}

export type AccountTitle = {
  readonly '*': string;
  readonly de?: string;
  readonly en?: string;
  readonly fr?: string;
  readonly it?: string;
};

export interface Account {
  readonly allowed_tax_codes: string[];
  readonly booking_text_mandatory: boolean;
  readonly number: string;
  readonly object_mandatory: boolean;
  readonly pk: number;
  readonly title: AccountTitle;
}
